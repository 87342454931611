import React from "react";
import { Link } from "react-router-dom";

const Info2Tabs = ({ current }) => {
  return (
    <div className={"sub_nav depth3"}>
      <ul>
        <li className={current === "info" ? "active" : null}>
          <Link to="/watchInfo">관람안내</Link>
        </li>
        <li className={current === "regist" ? "active" : null}>
          <Link to="/regist">사전등록</Link>
        </li>
        <li className={current === "booth" ? "active" : null}>
          <Link to="/booth">부스배치도</Link>
        </li>
      </ul>
    </div>
  );
};

export default Info2Tabs;
