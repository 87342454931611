import axios from "axios";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MoonLoader, PulseLoader } from "react-spinners";
import BoardSubVisual from "../../components/BoardSubVisual";
import LoginTabs from "../../components/LoginTabs";

const override = {
  display: "block",
  margin: "0 auto",
  //   borderColor: "red",
};

const FindPWd = () => {
  const navi = useNavigate();

  const [loading, setLoading] = useState(false);
  const [checkOpen, setCheckOpen] = useState(false);

  const [cNum, setCNum] = useState("");
  const toggelCheck = () => {
    setCheckOpen(true);
    setLoading(true);
    handleValid();
  };

  const idRef = useRef(null);
  const confirmRef = useRef(null);

  const handleValid = async () => {
    const value = idRef.current.value;
    const result = await axios.get(
      `https://admin.swfestival2022.kr/api/account/pwd?userId=${value}`
    );
    console.log(result);
    if (result.status === 200) {
      alert(
        "인증번호가 발송되었습니다. 하단에 인증번호를 입력해주시기 바랍니다."
      );
      setCNum(result.data.toString());
    } else if (result.status === 204) {
      alert("일치하는 회원정보가 존재하지 않습니다.");
      return false;
    } else {
      alert(
        "가입여부를 확인하는 중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
      );
      return false;
    }
  };

  const checkvalid = () => {
    const inputValue = confirmRef.current.value;
    console.log(cNum);
    console.log(inputValue);
    if (cNum === inputValue) {
      handleFindPWd();
    } else {
      alert("인증번호가 일치하지 않습니다. 인증번호를 확인해주시기 바랍니다.");
      return false;
    }
  };

  const handleFindPWd = async () => {
    const value = idRef.current.value;

    const result = await axios.post(
      `https://admin.swfestival2022.kr/api/account/pwd`,
      {
        userId: value,
      }
    );
    if (result.status === 200) {
      alert(
        "인증되었습니다. 입력하신 휴대전화번호로 비밀번호를 발송해드렸습니다."
      );
      navi("/login");
    } else if (result.status === 204) {
      console.log("error");
      return false;
    }
  };

  return (
    <div className={"wrap main"}>
      <div className={"container sub"}>
        <BoardSubVisual />
        <LoginTabs current={"login"} />
        <div className={"sub_wrap login"}>
          <br />
          <br />
          <span>비밀번호 찾기</span>
          <form className={"login_form"}>
            <div
              className={"input_area"}
              style={{ justifyContent: "space-between" }}
            >
              <input
                type="text"
                name="userId"
                className={"login_inp"}
                placeholder={"아이디를 입력하세요 ('-' 을 제외한 휴대전화번호)"}
                style={{ width: "70%" }}
                ref={idRef}
              />
              {!loading ? (
                <button
                  type="button"
                  style={{
                    width: "20%",
                    marginTop: 0,
                    padding: "0.5em",
                    cursor: "pointer",
                  }}
                  onClick={toggelCheck}
                >
                  <span>확인</span>
                </button>
              ) : (
                <button
                  type="button"
                  style={{
                    width: "20%",
                    marginTop: 0,
                    padding: "0.5em",
                    cursor: "pointer",
                  }}
                >
                  <PulseLoader color={"white"} />
                </button>
              )}
            </div>
            {checkOpen && (
              <div
                className={"input_area"}
                style={{ justifyContent: "space-between" }}
              >
                <input
                  type="text"
                  name="pwdCheck"
                  className={"login_inp"}
                  placeholder="인증번호를 입력해주세요"
                  style={{ width: "80%" }}
                  ref={confirmRef}
                />
                <button
                  type="button"
                  style={{
                    width: "11em",
                    marginTop: 0,
                    padding: "0.5em",
                    cursor: "pointer",
                  }}
                  onClick={checkvalid}
                >
                  인증
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default FindPWd;
