import React, { useEffect, useRef } from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import BoardTabs from "../../components/BoardTabs";
import EventSample from "../../asset/img/sub/img_event01.png";
import EventSample2 from "../../asset/img/sub/img_event02.png";
import ImageMap from "image-map";
import Thumb01 from "../../asset/img/sub/thumb_event01.png";
import { useParams } from "react-router-dom";

const Event = () => {
  const { type } = useParams();
  console.log(type);
  const imgMapRef = useRef(null);
  const btn1Ref = useRef(null);
  const btn2Ref = useRef(null);

  useEffect(() => {
    ImageMap("img[usemap]");
  }, []);

  return (
    <div className="wrap main">
      <div className="wrap main">
        <div className="container sub">
          <BoardSubVisual />
          <BoardTabs current={"event"} />
          <div className="sub_wrap event">
            <span className="page_title">이벤트</span>
            <div className="event_area">
              <img
                className="eventImg"
                src={type === "1" ? EventSample : EventSample2}
                alt=""
                useMap={"#eventLink"}
                width="700"
                height="2051"
              />
              {/* <map name="eventLink" ref={imgMapRef}>
                <area
                  shape="rect"
                  coords="92,1819,334,1899"
                  href="join.html"
                  target="_blank"
                  title="사전 등록 이벤트 참여하기"
                  alt="사전 등록 이벤트 참여하기"
                />
                <area
                  shape="rect"
                  coords="366,1819,608,1899"
                  href="http://naver.me/xVp1sJla"
                  target="_blank"
                  title="사전 등록 이벤트 참여하기"
                  alt="사전 등록 이벤트 참여하기"
                />
              </map> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
