export const boothList = [
  { number: "A01", name: "국민대학교" },
  { number: "A02", name: "숙명여자대학교" },
  { number: "A03", name: "아주대학교" },
  { number: "A04", name: "가천대학교" },
  { number: "A05", name: "경기대학교" },
  { number: "A06", name: "경북대학교" },
  { number: "A07", name: "대구가톨릭대학교" },
  { number: "A08", name: "동서대학교" },
  { number: "A09", name: "인하대학교" },
  { number: "A10", name: "전북대학교" },
  { number: "A11", name: "한국과학기술원" },
  { number: "A12", name: "한밭대학교" },
  { number: "A13", name: "경운대학교" },
  { number: "A14", name: "인제대학교" },
  { number: "A15", name: "성균관대학교" },
  { number: "A16", name: "순천향대학교" },
  { number: "A17", name: "전남대학교" },
  { number: "A18", name: "충남대학교" },
  { number: "A19", name: "삼육대학교" },
  { number: "A20", name: "한국항공대학교" },
  { number: "A21", name: "배재대학교" },
  { number: "A22", name: "상명대학교" },
  { number: "A23", name: "안동대학교" },
  { number: "A24", name: "연세대학교 미래" },
  { number: "A25", name: "이화여자대학교" },
  { number: "A26", name: "충북대학교" },
  { number: "A27", name: "경희대학교" },
  { number: "A28", name: "광운대학교" },
  { number: "A29", name: "단국대학교" },
  { number: "A30", name: "조선대학교" },
  { number: "A31", name: "중앙대학교" },
  { number: "A32", name: "한동대학교" },
  { number: "A33", name: "강원대학교" },
  { number: "A34", name: "건국대학교" },
  { number: "A35", name: "동명대학교" },
  { number: "A36", name: "선문대학교" },
  { number: "A37", name: "숭실대학교" },
  { number: "A38", name: "우송대학교" },
  { number: "A39", name: "한국외국어대학교" },
  { number: "A40", name: "원광대학교" },
  { number: "A41", name: "제주대학교" },
  { number: "A42", name: "호서대학교" },
  { number: "A43", name: "한림대학교" },
  { number: "A44", name: "한양대학교 ERICA" },
  { number: "B01", name: "경희대학교" },
  { number: "B02", name: "조선대학교" },
  { number: "B03", name: "한동대학교" },
  { number: "B04", name: "강원대학교" },
  { number: "B05", name: "건국대학교" },
  { number: "B06", name: "동명대학교" },
  { number: "B07", name: "선문대학교" },
  { number: "B08", name: "아주대학교" },
  { number: "B09", name: "제주대학교" },
  { number: "B10", name: "이화여자대학교" },
  { number: "B11", name: "숙명여자대학교" },
  { number: "B12", name: "우송대학교" },
  { number: "B13", name: "원광대학교" },
  { number: "B14", name: "한림대학교" },
  { number: "B15", name: "한양대학교 ERICA" },
  { number: "B16", name: "중앙대학교" },
  { number: "B17", name: "순천향대학교" },
  { number: "B18", name: "한국과학기술원" },
  { number: "B19", name: "연세대학교 미래" },
  { number: "B20", name: "단국대학교" },
  { number: "B21", name: "성균관대학교" },
  { number: "B22", name: "숭실대학교" },
  { number: "B23", name: "광운대학교" },
  { number: "B24", name: "전남대학교" },
  { number: "B25", name: "충남대학교" },
  { number: "B26", name: "상명대학교" },
  { number: "B27", name: "인하대학교" },
  { number: "B28", name: "대구가톨릭대학교" },
  { number: "B29", name: "동서대학교" },
  { number: "B30", name: "배재대학교" },
  { number: "B31", name: "안동대학교" },
  { number: "B32", name: "충북대학교" },
  { number: "B33", name: "한국외국어대학교" },
  { number: "B34", name: "호서대학교" },
  { number: "B35", name: "가천대학교" },
  { number: "B36", name: "경기대학교" },
  { number: "B37", name: "경북대학교" },
  { number: "B38", name: "삼육대학교" },
  { number: "B39", name: "한국항공대학교" },
  { number: "B40", name: "경운대학교" },
  { number: "B41", name: "국민대학교" },
  { number: "B42", name: "인제대학교" },
  { number: "B43", name: "전북대학교" },
  { number: "B44", name: "한밭대학교" },
  { number: "C01", name: "(주)모아데이타" },
  { number: "C02", name: "STK" },

  { number: "C04", name: "잉카인터넷" },
  { number: "C05", name: "브랜치앤바운드" },
  { number: "C06", name: "소프트스퀘어드" },
  { number: "C07", name: "아마존 웹서비시즈코리아유한책임회사" },
  { number: "C08", name: "엘리스그룹" },
  { number: "C09", name: "딥노이드" },
  { number: "C10", name: "TOPCIT" },

  { number: "D1", name: "주식회사 컨티넘" },
  { number: "D2", name: "㈜이노그리드" },
  { number: "D3", name: "위즈코어 주식회사" },
  { number: "D4", name: "㈜모아데이타" },
  { number: "D5", name: "주식회사 자이냅스" },
  { number: "D6", name: "㈜모아소프트" },
  { number: "D7", name: "㈜더존비즈온" },
  { number: "D8", name: "주식회사 알엠소프트" },
  { number: "D9", name: "㈜데이원컴퍼니" },
  { number: "D10", name: "㈜클루커스" },
  { number: "D11", name: "스마트사운드(주)" },
  { number: "D12", name: "㈜네비웍스" },
  { number: "D13", name: "아이티센그룹" },
  { number: "D14", name: "㈜네트러닝홀딩스 한국지사" },
];
