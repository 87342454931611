import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { NoticePaging } from "../../components/Paging";
import SubV from "../../asset/img/sub/img_subV.png";
import SubTitle from "../../asset/img/main/title_main.png";
import { Link } from "react-router-dom";
import BoardSubVisual from "../../components/BoardSubVisual";
import BoardTabs from "../../components/BoardTabs";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import axios from "axios";
import moment from "moment";
import { parseDateFormat } from "../../utils/DateFormat";
const PageArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2em;
  width: 100%;
`;
const Notice = () => {
  const [state] = useRecoilState(accountSelect);
  const dataScale = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({
    keyword: "",
  });

  useEffect(() => {
    const getNoticeList = async () => {
      const result = await axios.get(
        `https://admin.swfestival2022.kr/board/notice?page=${page}`
        // `http://localhost:8080/board/notice?page=${page}`
      );
      console.log(result);
      if (result.status === 200) {
        setList([...result.data.noticeList]);
        setTotalPages(Math.ceil(result.data.totalPage / 10));
        setPage(result.data.curPage + 1);
        setTotalData(result.data.totalPage);
      } else {
        alert("공지사항을 불러오는 중 오류가 발생했습니다.");
      }
    };
    getNoticeList();
  }, []);

  const init = (totalPages, curPage) => {
    setTotalPages(totalPages);
    setPage(curPage);
  };

  const filterRef = useRef(null);
  const keywordRef = useRef(null);

  const searchData = async (filter, keyword) => {
    const res = await axios.get(
      `https://admin.swfestival2022.kr/board/notice?page=1&type=${filter}&keyword=${keyword}`
      // `http://localhost:8080/board/notice?page=1&type=${filter}&keyword=${keyword}`
    );
    if (res.status === 200) {
      return res;
    } else {
      alert(
        "공지사항 검색중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
      );
      return false;
    }
  };

  const handleSearch = async () => {
    const filter = filterRef.current.value;
    const keyword = keywordRef.current.value;
    console.log(filter, keyword);
    console.log("sdfsdf");

    const resultData = await searchData(filter, keyword);
    console.log(resultData);
    if (resultData) {
      setList([...resultData.data.noticeList]);
      setTotalPages(Math.ceil(resultData.data.totalPage / 10));
      setPage(resultData.data.curPage + 1);
      setTotalData(resultData.data.totalPage);
    }
  };

  return (
    <>
      <div className="wrap main">
        <div className="wrap main">
          <div className="container sub">
            {/* subVisual */}
            <BoardSubVisual />
            {/* subVisual end*/}
            {/* boardMenu */}
            <BoardTabs current={"notice"} />
            {/* boardMenu end*/}
            {/* boardContent */}
            <div className="sub_wrap">
              <span className="page_title">공지사항</span>
              <div className="board_wrap">
                <div className="search_area">
                  <div className="input_area">
                    <select className="inp_search" ref={filterRef}>
                      <option value="title">제목</option>
                      <option value="content">내용</option>
                    </select>
                    <input
                      type="text"
                      name="inp_search"
                      className="inp_search"
                      placeholder="검색어 입력"
                      ref={keywordRef}
                    />
                    <button
                      type="button"
                      name="btn_search"
                      className="btn_search"
                      onClick={handleSearch}
                    >
                      검색
                    </button>
                  </div>
                </div>
                <div className="board_area">
                  <ul>
                    <li className="tr thead">
                      <div className="th number">
                        <span>No.</span>
                      </div>
                      <div className="th title">
                        <span>제목</span>
                      </div>
                      <div className="th date">
                        <span>작성일</span>
                      </div>
                      <div className="th view">
                        <span>조회수</span>
                      </div>
                    </li>
                    {/* {list.length > 0 && } */}
                    {list.length > 0 ? (
                      list.map((item, index) => (
                        <li className="tr" key={`notice_data_${index}`}>
                          <Link
                            to={{
                              pathname: `/notice/view/${item.id}`,
                            }}
                          >
                            <div className="td number">
                              <p>{totalData - ((page - 1) * 10 + index)}</p>
                            </div>
                            <div className="td title">
                              <span>{item.title}</span>
                            </div>
                            <div className="td date">
                              <p>{parseDateFormat(item.createdAt)}</p>
                            </div>
                            <div className="td view">
                              <span>{item.viewCnt}</span>
                            </div>
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li className="tr">
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItem: "center",
                          }}
                        >
                          공지사항이 존재하지 않습니다.
                        </p>
                      </li>
                    )}
                  </ul>
                  <PageArea>
                    <NoticePaging
                      totalPages={totalPages}
                      curPage={page}
                      setPage={setPage}
                      dataScale={dataScale}
                    />
                  </PageArea>
                </div>
              </div>
            </div>
            {/* boardContent end*/}
          </div>
        </div>
      </div>

      {/* <div>
        notice Page
        <div>
          <p> total Pages : {totalPages}</p>
          <p> curPage : {page}</p>
          <p> data List Length : {list.length}</p>
          <button onClick={() => init(5, 1)}>init</button>
          <button onClick={() => init(15, 1)}>extension</button>
          <button onClick={() => init(15, 11)}>extension2</button>
        </div>
        <div>data area</div>
      </div> */}
    </>
  );
};

export default Notice;
