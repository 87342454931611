import styled, { css } from "styled-components";
import {
  NextFlipAfterDeskTop,
  NextFlipDeskTop,
  NextFlipOther,
} from "./FlipKeyframe";

const nextFlip_d_rule = css`
  ${NextFlipDeskTop} 0.8s ease-in-out;
`;
const nextFlipAfter_d_rule = css`
  ${NextFlipAfterDeskTop} 0.8s - 3.2s initial step(1);
`;
const nextFlipOther_d_rule = css`
  ${NextFlipOther} 0.8s ease-in-out;
`;

export const FlipNextPageWrap = styled.div`
  width: 800px;
  height: 300px;
  position: absolute;
  z-index: 999;
  visibility: ${({ backActive }) => (backActive ? `visible` : `hidden`)};
`;

export const FlipNextBody = styled.div`
  transform-style: preserve-3d;
`;

export const FlipPrev = styled.div`
  position: absolute;
  visibility: visible;
`;

export const Flip2Sec = styled.div`
  position: absolute;
  width: 52px;
  height: 300px;
  left: 100%;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  background-color: white;
  transform-origin: left center;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: right center;
  }

  ${(props) =>
    props.seq === "sec1"
      ? css`
          left: 50%;
          ${props.backActive &&
          css`
            &::after {
              /* animation */
              animation: ${nextFlipAfter_d_rule};
            }
            animation: ${nextFlip_d_rule};
            /* animation */
          `}
        `
      : css`
          left: calc(100% - 2px);
          /* top: -2px; */
          transform-origin: left;

          ${props.backActive &&
          css`
            /* animation */
            animation: ${nextFlipOther_d_rule};
          `}
        `}
`;
