import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import { createHeightAndBlob } from "../utils/ImgBlob";
import FlipStyle from "../styles/flip/FlipStyle.module.scss";
import { FlipLoadingText, FlipLoadingTextWrap } from "./FlipLoading";
import ArrowPng from "../btn_prev_w.png";
import L1 from "../asset/img/sub/ci_univ01.png";
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const CoverImg = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background: white;
`;

const PdfViewDeskTop = ({
  url,

  size,
  getSize,
  toggle,
  setRefList,
  next,
  prev,
  nextActive,
  prevActive,
}) => {
  // const cRef = useRef(null);
  // const [trigger, setTrigger] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [loading, setLoading] = useState(true);
  const [imgSize, setImgSize] = useState({
    width: 800,
    height: 300,
  });
  const [delay, setDelay] = useState(false);
  const ref1Page = useRef(null);
  const ref2Page = useRef(null);
  const ref3Page = useRef(null);
  const [blobList, setBlobList] = useState([]);
  const [iList, setIList] = useState([]);
  const indexList = new Array();

  const documentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderSuccess = (index) => {
    switch (index) {
      // // case 1:
      // //   const p1BlobData = createHeightAndBlob(index, ref1Page);
      // //   indexList.push(p1BlobData);

      // //   break;
      // case 1:
      //   const p2BlobData = createHeightAndBlob(2, ref2Page);
      //   indexList.push(p2BlobData);
      //   setIList([...iList, p2BlobData]);
      //   getSize({
      //     ...size,
      //     height: indexList[0].height,
      //   });
      //   break;
      // case 2:
      //   const p3BlobData = createHeightAndBlob(3, ref3Page);
      //   indexList.push(p3BlobData);
      //   setIList([...iList, p3BlobData]);
      //   break;
      // case 3:
      //   const p1BlobData = createHeightAndBlob(index, cRef);
      //   indexList.push(p1BlobData);
      //   setIList([...iList, p1BlobData]);
      //   break;
      // default:
      //   break;
      case 1:
        console.log(1);
        const p1BlobData = createHeightAndBlob(index, ref1Page);
        indexList.push(p1BlobData);
        break;
      case 2:
        console.log(2);
        const p2BlobData = createHeightAndBlob(index, ref2Page);
        indexList.push(p2BlobData);
        break;
      case 3:
        const p3BlobData = createHeightAndBlob(index, ref3Page);
        indexList.push(p3BlobData);
        break;
      default:
        break;
    }

    // console.log(indexList.length);
    // console.log(indexList);
    // if (indexList.length === 2) {
    //   setIList(indexList);
    //   setTrigger(true);
    // }

    if (indexList.length === 3) {
      // console.log(indexList[0].height);
      getSize({
        ...size,
        height: indexList[0].height,
      });

      setRefList(indexList);
      setTimeout(() => setLoading(false), 1000);
      setTimeout(() => toggle(false), 1000);
    }
  };

  const setClassName = (index, curPage) => {
    switch (index) {
      case 1:
        return (
          FlipStyle.pdfPage +
          " " +
          FlipStyle.page +
          " " +
          (curPage === index ? FlipStyle.current : "") +
          " "
          // (loading ? " " + FlipStyle.loading : "")
        );
      case 2:
        return (
          FlipStyle.pdfPage +
          " " +
          FlipStyle.page +
          " " +
          // (next && FlipStyle.next) +
          // " " +
          // (prev && FlipStyle.prev) +
          // " " +
          // (!next && prev && curPage === 1 ? FlipStyle.showOnFront : "") +
          // (!next && !prev && curPage === 2 ? FlipStyle.current : "") +
          // (!prev && next && curPage === 3 ? FlipStyle.hideOnBack : "") +
          (curPage === index
            ? FlipStyle.current
            : prev && curPage === 1
            ? FlipStyle.current + " " + FlipStyle.back
            : "") +
          " "
          // (loading ? " " + FlipStyle.loading : "")
        );
      case 3:
        return (
          FlipStyle.pdfPage +
          " " +
          FlipStyle.page +
          " " +
          (curPage === index
            ? FlipStyle.current
            : prev && curPage === 2
            ? FlipStyle.current + " " + FlipStyle.back
            : "") +
          " "
          // (loading ? " " + FlipStyle.loading : "")
        );
    }
  };

  useEffect(() => {
    if (next) {
      if (pageNumber + 1 <= 3) {
        setPageNumber(pageNumber + 1);
      }
    }
  }, [next]);

  useEffect(() => {
    if (prev) {
      if (pageNumber - 1 > 0) {
        setPageNumber(pageNumber - 1);
      }
    }
  }, [prev]);

  return (
    <>
      <div className={FlipStyle.pdfDocumentWrap}>
        <Document
          className={FlipStyle.pdfDocumentBody}
          file={url}
          externalLinkTarget="_blank"
          externalLinkRel="_blank"
          loading={""}
          onLoadSuccess={documentLoadSuccess}
          options={{
            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
        >
          {/* {loading && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: `${imgSize.height}px`,
              }}
            >
              loading
            </div>
          )} */}
          {/* <FlipLoadingTextWrap height={size.height}>
            <FlipLoadingText>2022 SW FESTIVAL</FlipLoadingText>
          </FlipLoadingTextWrap> */}

          {!loading && (
            <>
              <div
                className={
                  FlipStyle.pageChange +
                  " " +
                  FlipStyle.left +
                  " " +
                  (pageNumber === 1 && FlipStyle.hide)
                }
                style={{ height: `${size.height}px` }}
              >
                <button onClick={prevActive} className={FlipStyle.left}>
                  <img src={ArrowPng} />
                </button>
              </div>
              <div
                className={
                  FlipStyle.pageChange +
                  " " +
                  FlipStyle.right +
                  " " +
                  (pageNumber === 3 && FlipStyle.hide)
                }
                style={{ height: `${size.height}px` }}
              >
                <button onClick={nextActive} className={FlipStyle.right}>
                  <img src={ArrowPng} />
                </button>
              </div>
            </>
          )}
          <Page
            width={size.width}
            height={imgSize.height}
            scale={pageScale}
            canvasRef={ref1Page}
            className={setClassName(1, pageNumber)}
            onRenderSuccess={() => renderSuccess(1)}
            pageNumber={1}
          />

          {/* <canvas
            width={size.width}
            height={size.height}
            ref={cRef}
            className={setClassName(1, pageNumber)}
          ></canvas> */}
          {/* <CoverImg
            className={setClassName(1, pageNumber)}
            width={size.width}
            height={size.height}
          >
            <img src={L1} />
          </CoverImg> */}

          <Page
            width={size.width}
            height={imgSize.height}
            scale={pageScale}
            canvasRef={ref2Page}
            className={setClassName(2, pageNumber)}
            onRenderSuccess={() => renderSuccess(2)}
            pageNumber={2}
          />
          <Page
            width={size.width}
            height={imgSize.height}
            scale={pageScale}
            canvasRef={ref3Page}
            className={setClassName(3, pageNumber)}
            onRenderSuccess={() => renderSuccess(3)}
            pageNumber={3}
          />
        </Document>
      </div>
    </>
  );
};

export default PdfViewDeskTop;
