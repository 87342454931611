import React from "react";
import { UnivList } from "../utils/LoadUnivImg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";

const UNIV_LIST = UnivList;

const MainSection2 = () => {
  return (
    <section className="section02">
      <Swiper
        className={"univ_slider "}
        slidesPerView={"auto"}
        spaceBetween={45}
        loopedSlides={0}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {UNIV_LIST.length > 0 &&
          UNIV_LIST.map((item, index) => (
            <SwiperSlide key={`u_img_${index}`}>
              <a>
                <img src={item.img} alt={item.name} />
              </a>
            </SwiperSlide>
          ))}
      </Swiper>
    </section>
  );
};

export default MainSection2;
