import React from "react";
import ReadyImg from "../../asset/img/main/openready.png";
import ReadyPc from "../../asset/img/main/openready_pc.png";
import ReadyMobile from "../../asset/img/main/mobile_main.jpg";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const OpenImgWrap = styled.div`
  width: 100%;
  height: ${(props) => (props.isMobile ? `100vh` : `100%`)};
  display: flex;
  ${(props) =>
    props.isMobile
      ? css`
          flex-direction: column;
          justify-content: flex-start;
        `
      : css`
          justify-content: center;
        `}
  align-items: center;
  position: relative;
  background: rgb(182, 224, 241);
  background: -moz-linear-gradient(
    20deg,
    rgb(182, 224, 241) 1%,
    rgb(233 234 252) 55%
  );
  background: -webkit-linear-gradient(
    20deg,
    rgb(182, 224, 241) 1%,
    rgb(233 234 252) 55%
  );
  background: linear-gradient(
    20deg,
    rgb(182, 224, 241) 1%,
    rgb(233 234 252) 55%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b6e0f1",endColorstr="#e9eafc",GradientType=1);
`;

const OpenImg = styled.img`
  width: 100%;
`;

const Btn = styled.a`
  position: ${(props) => (props.isMobile ? "relative" : "absolute")};
  ${(props) =>
    props.isMobile
      ? css`
          margin-top: 10px;
        `
      : css`
          bottom: 2em;
        `}

  padding: 0.75em 1.5em;
  border-radius: 5em;
  background-color: rgb(5 22 80);
  border: none;
  color: white;
  cursor: pointer;
`;

const PrevIndex = () => {
  const navi = useNavigate();

  return (
    <OpenImgWrap isMobile={isMobile}>
      <OpenImg src={isMobile ? ReadyMobile : ReadyPc} alt="open ready" />
      <Btn
        href={
          isMobile
            ? "https://kpkp.sysforu.co.kr/m/regist.asp"
            : "https://kpkp.sysforu.co.kr/regist.asp"
        }
        isMobile={isMobile}
      >
        사전등록 바로가기
      </Btn>
    </OpenImgWrap>
  );
};

export default PrevIndex;
