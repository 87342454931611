import React from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import InfoTabs from "../../components/InfoTabs";
import {
  InfoSection1,
  InfoSection2,
  InfoSection2Mobile,
  InfoSection3,
} from "../../components/info/InfoSection";
import { isMobile } from "react-device-detect";

const Introduce = () => {
  return (
    <div className={"wrap main"}>
      <div className={"wrap main"}>
        <div className={"container sub"}>
          <BoardSubVisual />
          <InfoTabs current="introduce" />
          <div className="sub_wrap introduce">
            <InfoSection1 />
            <div className="intro_content">
              <div className="intro_section">
                <span>프로그램</span>

                <InfoSection2 />

                {/* <InfoSection3 /> */}
              </div>
            </div>
            {/* <div className="intro_content">
              <div className="intro_section" style={{ paddingTop: 0 }}>
                <span>행사일정 (2일차)</span>
                <InfoSection2 type="d2" />

                
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduce;
