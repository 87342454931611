import React, { useState } from "react";
import Cl01 from "../../asset/img/common/ci_client01.png";
import Cl02 from "../../asset/img/common/ci_client02.png";
import Cl03 from "../../asset/img/common/ci_client03.png";
import ModalClose from "../../asset/img/sub/btn_close.png";

const Footer = () => {
  const [modal, setModal] = useState({
    open: false,
    target: undefined,
  });

  const handleModal = (target, toggle) => {
    if (toggle) {
      setModal({
        open: true,
        target: target,
      });
    } else {
      setModal({
        open: false,
        target: undefined,
      });
    }
  };

  return (
    <footer className="footer">
      <div className="footer_wrap">
        <div className="link_area">
          <a className="termsPopup1" onClick={() => handleModal("first", true)}>
            개인정보취급 처리법
          </a>
          {/* <a
            className="termsPopup2"
            onClick={() => handleModal("second", true)}
          >
            이메일무단수집거부
          </a> */}
        </div>
        <div className="copy_area">
          <span>사단법인 소프트웨어중심대학협의회</span>
          <p>
            [04109]서울시 마포구 백범로28(신수동)
            <br />
            전시관람 및 사전안내 : 02-3453-8922&nbsp;&nbsp;I&nbsp;&nbsp;무대행사
            및 프로그램 안내 : 02-575-3017
          </p>
          <p>Copyright © 소프트웨어중심대학협의회 ALL RIGHTS RESERVED.</p>
        </div>
        <div className="client_area">
          <div className="client_info">
            <span>주최</span>
            <a href="https://www.msit.go.kr/" target="_blank">
              <img src={Cl01} alt="" />
            </a>
          </div>
          <div className="client_info">
            <span>주관</span>
            <a href="https://www.iitp.kr/" target="_blank">
              <img src={Cl02} alt="" />
            </a>
          </div>
          <div className="client_info">
            <a href="https://www.swuniv.kr/" target="_blank">
              <img src={Cl03} alt="" />
            </a>
          </div>
        </div>
      </div>

      <div
        id="pop_terms1"
        className="popup"
        style={{ display: `${modal.target === "first" ? "flex" : "none"}` }}
      >
        <div className="modal-bg"></div>
        <div className="modal-cont">
          <h2>개인정보처리방침</h2>
          <div className="tableArea popView">
            <br />
            <br />
            (개인정보처리방침)
            <br />
            <br />
            "SW인재페스티벌"이 취급하는 모든 개인정보는 개인정보 보호법 및
            "SW인재페스티벌" 개인정보처리방침 등 관련 법령상의 개인정보 보호
            규정을 준수하여 수집·보유·처리되고 있습니다.
            <br />
            <br />
            "SW인재페스티벌"은 개인정보 보호법에 따라 이용자의 개인정보 보호 및
            권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수
            있도록 다음과 같은 처리방침을 두고 있습니다. "SW인재페스티벌"은
            개인정보 처리방침을 변경하는 경우에는 시행의 시기, 변경된 내용을
            정보주체가 쉽게 확인할 수 있도록 변경 전·후를 비교하여 공개하도록 할
            예정입니다.
            <br />
            <br />
            개인정보의 처리목적, 개인정보의 처리 및 보유기간, 처리하는
            개인정보의 항목)
            <br />
            "SW인재페스티벌" 대표 홈페이지(https://www.swhrfestival.kr)는 직접
            개인정보를 수집하지 않으며, 개인정보 보호법 제32조에 따라 등록,
            공개하는 개인정보파일은 아래에서 확인하실 수 있습니다.
            <br />
            개인정보보호 종합지원포털(www.privacy.go.kr)
            <br />
            개인정보민원
            <br />
            개인정보의 열람 등 요구 <br />
            개인정보파일 목록 검색
            <br />
            기관명에 "SW인재페스티벌" 입력 후 조회
            <br />
            <br />
            개인정보의 제3자 제공)
            <br />
            "SW인재페스티벌"은 원칙적으로 정보주체의 개인정보를 수집·이용
            목적으로 명시한 범위 내에서 처리하며, 아래의 경우를 제외하고는
            정보주체의 사전 동의 없이는 본래의 목적 범위를 초과하여 처리하거나
            제3자에게 제공하지 않습니다.
            <br />
            정보주체로부터 별도의 동의를 받는 경우
            <br />
            법률에 특별한 규정이 있는 경우
            <br />
            정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나
            주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체
            또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고
            인정되는 경우
            <br />
            통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을
            알아 볼 수 없는 형태로 개인정보를 제공하는 경우
            <br />
            개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지
            아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서
            보호위원회의 심의·의결을 거친 경우 조약, 그 밖의 국제협정의 이행을
            위하여 외국정보 또는 국제기구에 제공하기 위하여 필요한 경우
            <br />
            범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
            <br />
            법원의 재판업무 수행을 위하여 필요한 경우
            <br />
            형 및 감호, 보호처분의 집행을 위하여 필요한 경우
            <br />
            <br />
            개인정보처리 위탁)
            <br />
            "SW인재페스티벌"에서 관리하는 개인정보의 처리를 다른 공공기관 또는
            다른 전문기관에 위탁하는 경우, 그 업무에 종사하거나 종사하였던 자에
            대해 직무상 알게 된 개인정보를 누설 또는 권한 없이 처리하거나 타인의
            이용에 제공하는 등 부당한 목적을 위하여 사용하지 않도록 위탁부서에서
            아래에 대한 사항에 관하여 필요한 제한이나 절차를 정하고 수탁기관으로
            하여금 준수하도록 하고 있으며, 실태점검도 실시하고 있습니다.
            <br />
            재 위탁 금지에 관한 사항
            <br />
            개인정보파일의 복사에 관한 사항
            <br />
            개인정보의 관리상황 검사 및 소속직원의 교육에 관한 사항
            <br />
            수탁기관에서 준수하여야 할 의무를 위반한 경우의 손해배상 등에 관한
            사항
            <br />
            <br />
            정보주체의 권리·의무 및 그 행사 방법)
            <br />
            정보주체는 아래와 같은 권리를 행사 할 수 있으며, 만14세 미만 아동의
            법정대리인은 그 아동의 개인정보에 대한 열람, 정정·삭제, 처리정지를
            요구할 수 있습니다.
            <br />
            <br />
            개인정보 열람 요구)
            <br />
            "SW인재페스티벌"에서 보유하고 있는 개인정보파일은 「개인정보
            보호법」 제35조(개인정보의 열람)에 따라 자신의 개인정보에 대한
            열람을 요구할 수 있습니다. 다만, 개인정보 열람 요구는 법 제35조
            5항에 의하여 아래와 같이 제한될 수 있습니다.
            <br />
            법률에 따라 열람이 금지되거나 제한되는 경우
            <br />
            다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그
            밖의 이익을 부당하게 침해할 우려가 있는 경우
            <br />
            공공기관이 아래 각 목의 어느 하나에 해당하는 업무를 수행할 때 중대한
            지장을 초래하는 경우
            <br />
            조세의 부과·징수 또는 환급에 관한 업무
            <br />
            학력·기능 및 채용에 관한 시험, 자격 심사에 관한 업무
            <br />
            보상금·급부금 산정 등에 대하여 진행 중인 평가 또는 판단에 관한 업무
            <br />
            다른 법률에 따라 진행 중인 감사 및 조사에 관한 업무
            <br />
            <br />
            개인정보 정정·삭제 요구)
            <br />
            "SW인재페스티벌"에서 보유하고 있는 개인정보파일은 「개인정보
            보호법」 제36조(개인정보의 정정·삭제)에 따라 정정·삭제를 요구할 수
            있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어
            있는 경우에는 그 삭제를 요구할 수 없습니다.
            <br />
            <br />
            개인정보 처리정지 요구)
            <br />
            "SW인재페스티벌"에서 보유하고 있는 개인정보파일은 「개인정보보호법」
            제37조(개인정보의 처리정지 등)에 따라 처리정지를 요구할 수 있습니다.
            다만, 개인정보 처리정지 요구는 법 제37조 2항에 의하여 거절될 수
            있습니다.
            <br />
            법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한
            경우
            <br />
            다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그
            밖의 이익을 부당하게 침해할 우려가 있는 경우 <br />
            공공기관이 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관
            업무를 수행할 수 없는 경우
            <br />
            개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지
            못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지
            의사를 명확하게 밝히지 아니한 경우
            <br />
            <br />
            개인정보의 파기)
            <br />
            "SW인재페스티벌"은 원칙적으로 개인정보 보존기간이 경과되거나,
            개인정보 처리목적이 달성된 경우에는 지체 없이 파기합니다. 파기의
            절차, 기한 및 방법은 아래와 같습니다.
            <br />
            <br />
            파기 절차)
            <br />
            개인정보는 목적 달성 후 즉시 또는 별도의 공간에 옮겨져 내부 방침 및
            기타 관련법령에 따라 일정기간 저장된 후 파기됩니다. 별도의 공간으로
            옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로
            이용되지 않습니다.
            <br />
            <br />
            파기 기한 및 파기 방법)
            <br />
            보유기간이 만료되었거나 개인정보의 처리목적달성, 해당 업무의 폐지 등
            그 개인정보가 불필요하게 되었을 때에는 지체 없이 파기합니다. 전자적
            파일형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
            종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
            파기합니다.
            <br />
            <br />
            개인정보의 안전성 확보 조치)
            <br />
            "SW인재페스티벌"은 개인정보 보호법 제29조에 따라 다음과 같이 안전성
            확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.
            <br />
            <br />
            개인정보 처리직원의 최소화 및 교육)
            <br />
            개인정보를 처리하는 직원은 반드시 필요한 인원에 한하여 지정 ·
            관리하고 있으며 처리직원을 대상으로 안전한 관리를 위한 교육을
            실시하고 있습니다.
            <br />
            <br />
            개인정보에 대한 접근 제한)
            <br />
            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
            부여·변경·말소를 통하여 개인정보에 대한 접근통제를 위한 필요한
            조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
            접근을 통제하고 있습니다.
            <br />
            <br />
            접속기록의 보관)
            <br />
            개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 6개월
            이상 보관·관리하고 있습니다.
            <br />
            <br />
            개인정보의 암호화)
            <br />
            개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다. 또한
            중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도
            보안기능을 사용하고 있습니다.
            <br />
            <br />
            보안프로그램 설치 및 주기적 점검·갱신)
            <br />
            해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
            위하여 보안프로그램을 설치하고 주기적으로 갱신·점검하고 있습니다.
            <br />
            <br />
            비인가자에 대한 출입 통제)
            <br />
            개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로
            두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
            <br />
            <br />
            정기적인 자체 점검 실시)
            <br />
            개인정보 처리 관련 안정성 확보를 위해 정기적으로 소속·산하기관을
            포함하여 개인정보 보호관리 점검을 실시하고 있습니다.
            <br />
            <br />
            내부관리계획의 수립 및 시행)
            <br />
            개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
            있습니다.
            <br />
            <br />
            개인정보 보호책임자 및 담당자)
            <br />
            "SW인재페스티벌"은 개인정보를 보호하고 개인정보와 관련한 불만을
            처리하기 위하여 아래와 같이 개인정보 보호책임자 및 담당자를 지정하고
            있습니다.
            <br />
            <br />
            개인정보 보호책임관: (주) 올에프 추성윤 실장
            <br />
            개인정보보호 담당자: (주) 올에프 추성윤 실장
            <br />
            연락처: 02-572-3017
            <br />
            이메일: dhddl95r@gmail.com <br />
            <br />
            권익침해 구제방법)
            <br />
            개인정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보
            분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에
            분쟁해결이나 상담 등을 신청할 수 있습니다.
            <br />
            개인정보 분쟁조정위원회: (국번없이) 118 (privacy.kisa.or.kr)
            <br />
            개인정보 침해신고센터: (국번없이) 118 (privacy.kisa.or.kr)
            <br />
            대검찰청 사이버범죄수사단: 02-3480-3571 (cybercid@spo.go.kr)
            <br />
            경찰청 사이버테러대응센터: 1566-0112 (www.netan.go.kr)
            <br />
            또한, 개인정보의 열람, 정정·삭제, 처리정지 등에 대한 정보주체자의
            요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리
            또는 이익을 침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
            청구할 수 있습니다.
            <br />
            행정심판에 대한 자세한 사항은 국민권익위원회(www.acrc.go.kr)
            홈페이지를 참고하시기 바랍니다.
            <br />
            <br />
          </div>
          <a
            className="close close1"
            onClick={() => handleModal("first", false)}
          >
            <img src={ModalClose} alt="닫기" />
          </a>
        </div>
      </div>

      <div
        id="pop_terms2"
        className="popup"
        style={{ display: `${modal.target === "second" ? "flex" : "none"}` }}
      >
        <div className="modal-bg"></div>
        <div className="modal-cont">
          <h2>이메일무단수집거부</h2>
          <div className="tableArea popView">
            <br />
            <br />
            <br />
            <br />
          </div>
          <a
            className="close close1"
            onClick={() => handleModal("second", false)}
          >
            <img src={ModalClose} alt="닫기" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
