import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import ArrowPng from "../btn_prev_w.png";
import WindowSize from "../utils/WindowSize";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewUnivInfoM = ({ url }) => {
  const size = WindowSize();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [cSize, setCSize] = useState({
    loading: true,
  });

  const documentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber + 1 <= numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    if (size.width) {
      setCSize({ ...size, loading: false });
    }
  }, [size]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          type="button"
          onClick={handlePrevPage}
          style={{
            border: "none",
            background: "transparent",
            color: "white",
            fontSize: "2em",
          }}
        >
          {"<"}
        </button>
        <span style={{ color: "white" }}>
          {pageNumber} / {numPages}
        </span>
        <button
          type="button"
          onClick={handleNextPage}
          style={{
            border: "none",
            background: "transparent",
            color: "white",
            fontSize: "2em",
          }}
        >
          {">"}
        </button>
      </div>
      <div>
        {!cSize.loading && (
          <Document
            file={url}
            onLoadSuccess={documentLoadSuccess}
            options={{
              cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}
          >
            <Page
              //   className={NStyle.page}
              width={cSize.width}
              scale={1}
              pageNumber={pageNumber}
            />
          </Document>
        )}
      </div>
      {/* {pageNumber !== numPages && (
        <div
          style={{ position: "absolute", top: "45%", right: 0, zIndex: 11111 }}
        >
          <button
            type="button"
            onClick={handleNextPage}
            style={{
              borderRadius: "50%",
              padding: "1em",
              border: "none",
              width: "60px",
              height: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ArrowPng} style={{ transform: "rotateY(180deg)" }} />
          </button>
        </div>
      )}
      {pageNumber !== 1 && (
        <div
          style={{ position: "absolute", top: "45%", left: 0, zIndex: 11111 }}
        >
          <button
            type="button"
            onClick={handlePrevPage}
            style={{
              borderRadius: "50%",
              padding: "1em",
              border: "none",
              width: "60px",
              height: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ArrowPng} />
          </button>
        </div>
      )} */}
    </div>
  );
};

export default PdfViewUnivInfoM;
