import React, { useEffect, useState } from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import BoardTabs from "../../components/BoardTabs";
import AnimateHeight from "react-animate-height";
import axios from "axios";

const Faq = () => {
  const [curData, setCurData] = useState({
    open: false,
    targetId: 0,
  });
  const [list, setList] = useState([]);

  const handleOpen = (targetId) => {
    if (targetId === curData.targetId) {
      setCurData({
        open: false,
        targetId: 0,
      });
    } else {
      setCurData({
        open: true,
        targetId,
      });
    }
  };

  useEffect(() => {
    const getFaq = async () => {
      const result = await axios.get(
        `https://admin.swfestival2022.kr/board/faq`
      );
      // const result = await axios.get(`http://localhost:8080/board/faq`);

      if (result.status === 200) {
        setList([...result.data]);
      } else {
        alert("faq를 불러오는중 오류가 발생했습니다.");
      }
    };
    getFaq();
  }, []);
  return (
    <div className="wrap main">
      <div className="wrap main">
        <div className="container sub">
          <BoardSubVisual />
          <BoardTabs current={"faq"} />
          <div className="sub_wrap">
            <span className="page_title">자주 묻는 질문</span>
            <div className="board_wrap">
              <div className="board_area" id="faq">
                <ul>
                  {list.length > 0 ? (
                    list.map((item, index) => (
                      <li
                        key={`faq_data_${index}`}
                        className={`tr subNav faq ${
                          curData.targetId === item.id ? "active" : ""
                        }`}
                      >
                        <a onClick={() => handleOpen(item.id)}>
                          <span>{item.title}</span>
                        </a>
                        <ul
                          className={`submenu answer ${
                            curData.targetId === item.id ? "active" : ""
                          }`}
                          style={{
                            display: "block",
                            marginTop: "0",
                          }}
                        >
                          <AnimateHeight
                            duration={500}
                            height={curData.targetId === item.id ? "auto" : 0}
                          >
                            <li
                              style={{ marginTop: "1em" }}
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            ></li>
                          </AnimateHeight>
                        </ul>
                      </li>
                    ))
                  ) : (
                    <li
                      className={`tr subNav faq ${
                        curData.targetId === 1 ? "active" : ""
                      }`}
                    >
                      <p>faq 가 존재하지 않습니다.</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
