import React from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import { SubContainer } from "../../components/layout/CommonLayout";
import SpecialLecture from "../../components/program/SpecialLecture";
import ProgramTabs from "../../components/ProgramTags";
import Sl1 from "../../asset/img/sub/img_sl01.png";
import Sl2 from "../../asset/img/sub/img_sl02.png";
import YouTube from "../../asset/img/main/btn_youtube.png";
import Naver from "../../asset/img/main/btn_naver.png";

const Special = () => {
  return (
    <SubContainer>
      <BoardSubVisual />
      <ProgramTabs current="special" />
      <div className="sub_wrap additional">
        <span
          className="page_title"
          style={{ marginLeft: 0, marginRight: 0, textAlign: "left" }}
        >
          명사특강 : SW 및 인문 분야의 저명한 강연자를 초빙하여 특강 진행
        </span>
        <div className="tc_cont sl">
          <div className="about_tc">
            <div className="at_tit">
              <span>
                <b>1부 :</b> SW인재의 소양
              </span>
            </div>
            <div className="at_cont">
              <img src={Sl1} alt="" />
              <div className="text_area">
                <span>
                  <b>김태원</b>{" "}
                  <small style={{ color: "unset" }}>(글로벌 IT기업 임원)</small>
                  <br />
                  <small>
                    SW인재의 소양 : 디지털 트랜스포메이션 시대와 인재{" "}
                    <h5
                      style={{
                        display: "unset",
                        color: "black",
                        fontWeight: "400",
                      }}
                    >
                      (대상 : 대학생 및 일반)
                    </h5>
                  </small>
                </span>
                <ul>
                  <li>
                    <p>일시 :</p>
                    <p>22. 12. 9(금) 10:00 ~ 10:50</p>
                  </li>
                  <li>
                    <p>장소 :</p>
                    <p>아트홀 1관</p>
                  </li>
                  <li style={{ marginTop: "2em" }}>
                    <p>학력 :</p>
                    <p>고려대 사회학과 학사</p>
                  </li>
                  <li>
                    <p>경력 :</p>
                    <div>
                      <p>고려대 미디어학부 겸임교수</p>
                      <p>글로벌 IT기업 임원</p>
                    </div>
                  </li>

                  <li>
                    <p>저서 :</p>
                    <div>
                      <p>생각을 선물하는 남자</p>
                      <p>죽은 열정에게 보내는 젊은 구글러의 편지</p>
                      <p>젊은 구글러가 세상에 던지는 열정력 등</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="btn_area">
              <a
                href="https://youtu.be/ntXuHdkKdRk"
                target="_blank"
                className="btn youtube"
              >
                <img src={YouTube} />
              </a>
              <a
                href="https://tv.naver.com/l/93242"
                target="_blank"
                className="btn naver"
              >
                <img src={Naver} />
              </a>
            </div> */}
          </div>
          <div className="about_tc">
            <div className="at_tit">
              <span>
                <b>2부 :</b> 공간의 미래
              </span>
            </div>
            <div className="at_cont">
              <img src={Sl2} alt="" />
              <div className="text_area">
                <span>
                  <b>유현준</b> <small style={{ color: "unset" }}>대표</small>
                  <br />
                  <small>
                    디지털 대전환 시대, 공간의 미래{" "}
                    <h5
                      style={{
                        display: "unset",
                        color: "black",
                        fontWeight: "400",
                      }}
                    >
                      (대상 : 일반)
                    </h5>
                  </small>
                </span>
                <ul>
                  <li>
                    <p>일시 :</p>
                    <p>22. 12. 9(금) 11:00 ~ 11:50</p>
                  </li>
                  <li>
                    <p>장소 :</p>
                    <p>아트홀 1관</p>
                  </li>
                  <li style={{ marginTop: "2em" }}>
                    <p>학력 :</p>
                    <p>하버드대학교 대학원 건축설계 석사</p>
                  </li>
                  <li>
                    <p>경력 :</p>
                    <div>
                      <p>유현준건축사무소 대표</p>
                      <p>스페이스컨설팅그룹 설립, 대표 건축가</p>
                    </div>
                  </li>
                  <li>
                    <p>저서 :</p>
                    <div>
                      <p>도시는 무엇으로 사는가</p>
                      <p>당신의 별자리는 무엇인가요</p>
                      <p>공간의 미래 - 코로나가 가속화시킨 공간 변화</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="btn_area">
              <a
                href="https://youtu.be/ntXuHdkKdRk"
                target="_blank"
                className="btn youtube"
              >
                <img src={YouTube} />
              </a>
              <a
                href="https://tv.naver.com/l/93242"
                target="_blank"
                className="btn naver"
              >
                <img src={Naver} />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </SubContainer>
  );
};

export default Special;
