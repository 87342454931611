import styled, { css, keyframes } from "styled-components";
const loadingTextKeyframe = keyframes`
0%{
    width:0px;
}
80%{
    width:50%;
}
100%{
    width:100%;
}
`;

const animationRule = css`
  ${loadingTextKeyframe} 2s ease-in forwards;
`;

export const FlipLoadingTextWrap = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

export const FlipLoadingText = styled.span`
  font-size: 4em;
  font-weight: 900;
  color: #dfdfdf;
  position: relative;

  &::before {
    content: "2022 SW FESTIVAL";
    position: absolute;
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    background: linear-gradient(
      90deg,
      rgba(3, 196, 183, 1) 41%,
      rgba(255, 0, 211, 0.41220238095238093) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${animationRule};
  }
`;

const textFrame = (index) => keyframes`
0%{
opacity: 1;
  transform:rotateZ(0deg) 
}
100%{
    opacity: 1;
  transform:rotateZ(-360deg) 
}
`;
const textRule = (index) => css`
  ${textFrame(index)} 3s ${(index - 1) * 0.2}s linear infinite;
`;
export const FlipLoadingSpinner = styled.span`
  position: absolute;
  padding-bottom: 100px;
  opacity: 0;
  animation: ${(props) =>
    css`
      ${textRule(props.index)}
    `};
`;

export const FlipL_Text = styled.div`
  position: absolute;
  top: calc(50% + 49px);
  left: calc(50% - 19px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
