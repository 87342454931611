import React from "react";
import styled from "styled-components";
import Reg from "../../asset/img/sub/img_reg.png";

const PrevWrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #e9eafc;
`;

const PrevBody = styled.div``;

const PrevTitle = styled.span`
  position: relative;
  display: table;
  margin: 3em auto 1em;
  font-family: "SCDream-Bold";
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: #001650;
`;
const PrevImg = styled.img``;

const PrevBtnArea = styled.div`
  margin: 1em auto 0;
  padding-top: 2em;
  width: 100%;
  border-top: 1px solid #dcdcdc;
`;

const PrevBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1em;
  width: 100%;
  max-width: 10em;
  height: 100%;
  text-align: center;
  color: #fff;
  background-color: #8c7eb8;
  border-radius: 0.5em;

  cursor: pointer;
`;

const PrevRegist = () => {
  return (
    <PrevWrap>
      <PrevBody>
        <PrevTitle>관람객 사전등록</PrevTitle>
        <PrevImg src={Reg} />
        <PrevBtnArea>
          <PrevBtn
            href="https://kpkp.sysforu.co.kr/regist.asp"
            target={"_blank"}
          >
            사전등록하기
          </PrevBtn>
        </PrevBtnArea>
      </PrevBody>
    </PrevWrap>
  );
};

export default PrevRegist;
