import styled, { css, keyframes } from "styled-components";

export const FlipWrapMobile = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  visibility: visible;
  /* visibility: ${(props) => (props.active ? `visible` : `hidden`)}; */
  ${(props) => props.active && `z-index:9999;`}
`;

export const FlipBodyMobile = styled.div`
  transform-style: preserve-3d;
`;
export const FlipSecMobile_ver2_reverse = styled.div`
  position: absolute;
  width: ${(props) => Math.ceil(props.width / 8)}px;
  height: ${(props) => props.height}px;
  left: 0;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  transform-origin: left center;
  background-color: transparent;
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform-origin: left center;
  }

  ${(props) =>
    props.seq === "seq1" &&
    css`
      left: 0;
      ${props.active &&
      css`
        animation: ${backFlipRule_ver2_reverse};
        &::after {
          animation: ${backFlipAfterRule_ver2_reverse};
        }
      `}
    `}

  ${(props) =>
    props.seq !== "seq1" &&
    css`
      left: calc(100% + 3px);
      /* ${props.seq === "seq4" && `right:calc(100% + 10px);`}
      ${props.seq === "seq5" && `right:calc(100% - 40px);`} */

      /* top: -2px; */
      transform-origin: right;
      ${props.active &&
      css`
        animation: ${backFlipOtherRule_ver2_reverse};
      `}
    `}
`;

export const FlipSecMobile_ver2 = styled.div`
  position: absolute;
  width: ${(props) => Math.ceil(props.width / 8)}px;
  height: ${(props) => props.height}px;
  left: 0;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  transform-origin: left center;
  background-color: transparent;
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform-origin: left center;
  }

  ${(props) =>
    props.seq === "seq1" &&
    css`
      left: 0;
      ${props.active &&
      css`
        animation: ${backFlipRule_ver2};
        &::after {
          animation: ${backFlipAfterRule_ver2};
        }
      `}
    `}

  ${(props) =>
    props.seq !== "seq1" &&
    css`
      left: calc(100% + 3px);
      /* ${props.seq === "seq4" && `right:calc(100% + 10px);`}
      ${props.seq === "seq5" && `right:calc(100% - 40px);`} */

      /* top: -2px; */
      transform-origin: right;
      ${props.active &&
      css`
        animation: ${backFlipOtherRule_ver2};
      `}
    `}
`;

export const FlipSecMobile = styled.div`
  position: absolute;
  width: ${(props) => Math.ceil(props.width / 8)}px;
  height: ${(props) => props.height}px;
  right: 100%;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  transform-origin: right center;
  background-color: transparent;

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    transform-origin: left center;
  }

  ${(props) =>
    props.seq === "seq1" &&
    css`
      right: 100%;
      ${props.active &&
      css`
        animation: ${backFlipRule};
        &::after {
          animation: ${backFlipAfterRule};
        }
      `}
    `}

  ${(props) =>
    props.seq !== "seq1" &&
    css`
      right: calc(100% + 3px);
      /* ${props.seq === "seq4" && `right:calc(100% + 10px);`}
      ${props.seq === "seq5" && `right:calc(100% - 40px);`} */

      /* top: -2px; */
      transform-origin: right;
      ${props.active &&
      css`
        animation: ${backFlipOtherRule};
      `}
    `}
`;

const backFlip = keyframes`
0%,
20%{
    transform:rotateY(0deg) translateY(0);
}
80%,
100%{
    transform:rotateY(180deg) translateY(0);
}
`;

const backFlipAfter = keyframes`
0% {
    
    transform: rotateY(0deg);
  }
  20% {
    
    transform: rotateY(0deg);
  }
  40% {
    
    transform: rotateY(0deg);
  }
  60% {
    
    transform: rotateY(0deg);
  }
  80% {
    
    transform: rotateY(0deg);
  }
  100% {
    
    transform: rotateY(0deg);
  }
`;

const backFlipOther = keyframes`
0%,
  20% {
    transform: rotateY(0deg) translateY(0px) rotateZ(0deg) translateX(0px);
  }
  50% {
    transform: rotateY(-20deg) translateY(0px) rotateZ(0deg) translateX(0px);
  }
`;

const backFlip_ver2 = keyframes`
0%,
20%{
    transform:rotateY(0deg) translateY(0);
}
100%{
  transform:rotateY(180deg) translateY(0);
}

`;

const backFlipAfter_ver2 = keyframes`
0% {
    
    transform: rotateY(0deg);
  }
  20% {
    
    transform: rotateY(0deg);
  }
  40% {
    
    transform: rotateY(0deg);
  }
  60% {
    
    transform: rotateY(0deg);
  }
  80% {
    
    transform: rotateY(0deg);
  }
  100% {
    
    transform: rotateY(0deg);
  }
`;

const backFlipOther_ver2 = keyframes`
0%,
  20% {
    transform: rotateY(0deg) translateY(0px) rotateZ(0deg) translateX(0px);
  }
  80%,
  100% {
    transform: rotateY(-20deg) translateY(0px) rotateZ(0deg) translateX(0px);
  }
`;

const time = "1s";
const backFlipRule = css`
  ${backFlip} ${time} ease-in-out
`;

const backFlipAfterRule = css`
  ${backFlipAfter} ${time} -0.8s initial step(1);
`;

const backFlipOtherRule = css`
  ${backFlipOther} ${time} ease-in-out;
`;

const backFlipRule_ver2 = css`
  ${backFlip_ver2} 0.5s  ease-in-out reverse
`;

const backFlipAfterRule_ver2 = css`
  ${backFlipAfter_ver2} 0.5s -0.8s reverse initial step(1);
`;

const backFlipOtherRule_ver2 = css`
  ${backFlipOther_ver2} 0.5s reverse ease-in-out;
`;
const backFlipRule_ver2_reverse = css`
  ${backFlip_ver2} 0.5s  ease-in-out
`;

const backFlipAfterRule_ver2_reverse = css`
  ${backFlipAfter_ver2} 0.5s -0.8s  initial step(1);
`;

const backFlipOtherRule_ver2_reverse = css`
  ${backFlipOther_ver2} 0.5s  ease-in-out;
`;
