import React from "react";
import SubV from "../asset/img/sub/img_subV.png";
import SubTitle from "../asset/img/sub/title_subV.png";

const BoardSubVisual = () => {
  return (
    <div className="subVisual">
      <div className="img_area">
        <img className="img" src={SubV} alt="" />
        <img className="title" src={SubTitle} alt="" />
      </div>
      <div className="text_area">
        <p>
          2022.12.08(목), 10:00~
          <br />
          2022.12.09(금), ~17:00
        </p>

        <p>동대문디자인플라자(DDP), 아트홀 1관</p>
      </div>
    </div>
  );
};

export default BoardSubVisual;
