import React, { useEffect, useRef, useState } from "react";
import WindowSize from "../utils/WindowSize";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
// import { createHeightAndBlob } from "../utils/ImgBlob";
import "../mobiletemp1.css";

import { createHeightAndBlob } from "../utils/ImgBlob";
import { FlipBackMobile, FlipBackMobile_reverse } from "../components/FlipMove";
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewMobile = ({ url }) => {
  const PDF_URL = url;
  // "https://s3.ap-northeast-2.amazonaws.com/mulsarang2021.archive/pdf01.pdf";
  const [cnt, setCnt] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);
  const [backActive, setBackActive] = useState(false);

  const [imgList, setImgList] = useState([]);

  const ref1Page = useRef(null);
  const ref2Page = useRef(null);
  const ref3Page = useRef(null);

  const size = WindowSize();
  const [imgSize, setImgSize] = useState({
    width: 400,
    height: 300,
  });

  const blobList = new Array();

  const handleClassName = (index) => {
    switch (index) {
      case 1:
        return `pf_image_mobile right ${pageNumber === index ? "current" : ""}`;
        break;
      case 2:
        return `pf_image_mobile right ${
          pageNumber === index
            ? "current"
            : backActive && pageNumber === 1
            ? "current back"
            : ""
        }`;
        break;
      case 3:
        return `pf_image_mobile right ${
          pageNumber === index
            ? "current"
            : backActive && pageNumber === 2
            ? "current back"
            : ""
        }`;
        break;
    }
  };

  const handleNext = () => {
    let curPageNumber = pageNumber + 1;
    if (curPageNumber < 4) {
      setPageNumber(pageNumber + 1);
      console.log(ref1Page.current);
      setCnt(cnt + 1);
      setActive(true);
      setTimeout(() => setActive(false), 500);
    }
  };
  const handlePrev = () => {
    let curPageNumber = pageNumber - 1;
    if (curPageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setBackActive(true);
      setTimeout(() => setBackActive(false), 500);
    }
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (!loading) {
      const p1Object = createHeightAndBlob(1, ref1Page);
      const p2Object = createHeightAndBlob(2, ref2Page);
      const p3Object = createHeightAndBlob(3, ref3Page);
      blobList.push(p1Object);
      blobList.push(p2Object);
      blobList.push(p3Object);

      setImgList(blobList);
      setImgSize({
        widht: ref1Page.current.width,
        height: p1Object.height,
      });
    }
  }, [loading]);

  return (
    <div>
      <div className={"pf_wrap mobile"}>
        {imgList.length > 0 && (
          <>
            <FlipBackMobile
              active={active}
              page={pageNumber}
              refList={[ref1Page, ref2Page, ref3Page]}
              imgList={imgList}
              width={size.width}
              height={imgSize.height}
            />
            <FlipBackMobile_reverse
              active={backActive}
              page={pageNumber}
              refList={[ref1Page, ref2Page, ref3Page]}
              imgList={imgList}
              width={size.width}
              height={imgSize.height}
            />
          </>
        )}
        {imgList.length > 0 && (
          <div
            style={{
              width: "30px",
              height: `${imgSize.height}px`,
              position: "absolute",
              right: 0,
              zIndex: "1000",
            }}
          >
            <ActiveButton
              style={{
                border: "none",

                opacity: "0.3",
                width: "100%",
                height: "100%",

                borderRadius: "30px 0 0 30px",
              }}
              onClick={handleNext}
            >
              +
            </ActiveButton>
          </div>
        )}
        {imgList.length > 0 && (
          <div
            style={{
              width: "30px",
              height: `${imgSize.height}px`,
              position: "absolute",
              left: "0",
              zIndex: "1000",
            }}
          >
            <ActiveButton
              style={{
                border: "none",
                opacity: "0.3",
                width: "100%",
                height: "100%",
                borderRadius: "0px 30px 30px 0px",
              }}
              onClick={handlePrev}
            >
              -
            </ActiveButton>
          </div>
        )}
        <Document
          className={"pf_body"}
          file={PDF_URL}
          onLoadSuccess={handleDocumentLoadSuccess}
          externalLinkTarget="_blank"
          externalLinkRel="_blank"
          options={{
            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
        >
          <Page
            key={`page_1`}
            width={size.width}
            // height={imgSize.height}
            canvasRef={ref1Page}
            className={handleClassName(1)}
            onRenderSuccess={() => setLoading(false)}
            scale={pageScale}
            // onGetTextSuccess={() => testest()}
            pageNumber={1}
          />
          <Page
            key={`page_2`}
            width={size.width}
            // height={imgSize.height}
            canvasRef={ref2Page}
            className={handleClassName(2)}
            scale={pageScale}
            pageNumber={2}
          />
          <Page
            key={`page_3`}
            width={size.width}
            // height={imgSize.height}
            canvasRef={ref3Page}
            className={handleClassName(3)}
            scale={pageScale}
            pageNumber={3}
          />
        </Document>
      </div>
    </div>
  );
};

const ActiveButton = styled.button`
  background-color: #444343;
  color: white;
  &:active {
    background-color: black;
  }
`;
export default PdfViewMobile;
