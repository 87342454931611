import React from "react";
import { UnivList } from "../utils/LoadUnivImg";

const UNIV_LOGO = UnivList;

const MainSection6 = () => {
  return (
    <section className="section06">
      <div className="client_area">
        <span className="wow fadeInDown">
          SW인재페스티벌 참여대학 <small>(44개교)</small>
          <br />
          <small>
            ※ 대학 로고를 클릭하시면 SW중심대학 사업단 홈페이지(또는 대학
            홈페이지)로 연동됩니다.
          </small>
        </span>
        {/* <ul className="client">
          <li>
            <span>주&nbsp;&nbsp;&nbsp;&nbsp;최</span>
            <p>과학기술정보통신부</p>
          </li>
          <li>
            <span>주&nbsp;&nbsp;&nbsp;&nbsp;관</span>
            <p>정보통신기획평가원, 소프트웨어중심대학협의회</p>
          </li>
        </ul> */}
        <ul className="part_list wow fadeInUp" data-wow-delay=".5s">
          {UNIV_LOGO.length > 0 &&
            UNIV_LOGO.map((item, index) => (
              <li key={`logo_${index}`}>
                <a href={item.link} target={"_blank"}>
                  <img src={item.logo} alt={item.name} />
                </a>
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
};

export default MainSection6;
