import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Logo from "../../asset/img/common/logo.png";
import Login from "../../asset/img/common/ico_login.png";
import HeaderAnimate from "../../components/HeaderAnimate";
import { accountSelect } from "../../recoil/AtomConfig";
import Footer from "./Footer";
import { FinalOpen, MenuOpen } from "../../utils/DateLimit";
import { HiHome } from "react-icons/hi";
import { isMobile } from "react-device-detect";
const MENU_LIST = {
  info: [
    {
      type: "info",
      name: "행사소개",
      link: "/introduce",
    },
    {
      type: "info",
      name: "지난행사 소개",
      link: "/last",
    },
    {
      type: "info",
      name: "참여대학",
      link: "/univList",
    },
  ],
  univ: [
    {
      type: "univ",
      name: "사업소개",
      link: "/univIntro",
    },
    {
      type: "univ",
      name: "선정현황",
      link: "/univCurrent",
    },
  ],
  info2: [
    {
      type: "info2",
      name: "관람안내",
      link: "/watchInfo",
    },
    {
      type: "info2",
      name: "사전등록",
      link: "/regist",
      // reqLogin: true,
    },
    {
      type: "info2",
      name: "부스배치도",
      link: "/booth",
    },
    // {
    //   type: "info2",
    //   name: "오시는길",
    //   link: "/",
    // },
  ],
  program: [
    {
      type: "program",
      name: "우수작품 · 대학소개",
      link: "/ework",
    },
    {
      type: "program",
      name: "명사특강",
      link: "/special",
    },
    {
      type: "program",
      name: "토크콘서트",
      link: "/concert",
    },
    {
      type: "program",
      name: "강연",
      link: "/lecture",
    },
    {
      type: "program",
      name: "시상식",
      link: "/award",
    },
  ],
  board: [
    {
      type: "board",
      name: "공지사항",
      link: "/notice",
    },
    {
      type: "board",
      name: "이벤트",
      link: "/eventList",
    },
    {
      type: "board",
      name: "자주묻는 질문",
      link: "/faq",
    },
  ],
};

const Header = () => {
  const isOpen = MenuOpen();
  const isFinalOpen = FinalOpen();
  // console.log(isOpen);

  const [state, setState] = useRecoilState(accountSelect);
  const navigate = useNavigate();

  const [curMenu, setCurMenu] = useState("main");
  const [reset, setReset] = useState(false);
  const [animate, setAnimate] = useState(false);

  const handleAnimate = () => {
    if (animate) {
      setReset(false);
      setAnimate(false);
    } else {
      setReset(false);
      setAnimate(true);
    }
  };

  const handleRefreshAnimation = () => {
    setAnimate(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");

    setState((prev) => {
      const prevObject = { ...prev };
      prevObject.id = undefined;
      prevObject.accessToken = undefined;
      return { ...prevObject };
    });

    navigate("/");
  };

  const untoggle = () => {
    setReset(true);
    setCurMenu("main");
  };

  return (
    <>
      <header className="header main">
        <div className="header_wrap">
          {/* <Link className="logo" to={"/"} onClick={() => setReset(true)}>
            <div
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HiHome size={24} />
              <span style={{ paddingLeft: "0.5em", fontSize: "1.1rem" }}>
                홈
              </span>
            </div>
          </Link> */}
          <div
            className="ab_right"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            {isMobile && (
              <div
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={Logo} />
              </div>
            )}

            <div className="menuBtn" onClick={handleAnimate}>
              <a
                className={`gnbOpen menu-btn ${
                  !reset && animate && "gnbClose"
                }`}
              >
                <span
                  className={`line first ${!reset && animate && "animate"}`}
                ></span>
                <span
                  className={`line second ${!reset && animate && "animate"}`}
                ></span>
                <span
                  className={`line hid ${!reset && animate && "animate"}`}
                ></span>
                <span
                  className={`line third ${!reset && animate && "animate"}`}
                ></span>
              </a>
            </div>
            <div className={`gnb ${!reset && animate && "active"}`}>
              <nav className="menu">
                <div className="nav-category" id="accordion">
                  <ul className="accordion gnbBox">
                    <li className={"subNav"}>
                      <Link to="/">
                        <img src={Logo} />
                      </Link>
                    </li>
                    <li
                      className={`subNav ${curMenu === "info" && "active"}`}
                      onMouseLeave={untoggle}
                    >
                      <HeaderAnimate
                        reset={reset}
                        setReset={setReset}
                        curMenu={curMenu}
                        toggle={setCurMenu}
                        refreshAnimation={handleRefreshAnimation}
                        mainTitle={"행사안내"}
                        type={"info"}
                        subInfo={MENU_LIST.info}
                      />
                    </li>
                    <li
                      className={`subNav ${curMenu === "univ" && "active"}`}
                      onMouseLeave={untoggle}
                    >
                      <HeaderAnimate
                        type={"univ"}
                        reset={reset}
                        setReset={setReset}
                        curMenu={curMenu}
                        toggle={setCurMenu}
                        refreshAnimation={handleRefreshAnimation}
                        mainTitle={"SW중심대학"}
                        subInfo={MENU_LIST.univ}
                      />
                    </li>
                    <li
                      className={`subNav ${curMenu === "info2" && "active"}`}
                      onMouseLeave={untoggle}
                    >
                      <HeaderAnimate
                        type={"info2"}
                        reset={reset}
                        setReset={setReset}
                        curMenu={curMenu}
                        toggle={setCurMenu}
                        isLogin={state.id && state.accessToken}
                        refreshAnimation={handleRefreshAnimation}
                        mainTitle={"관람안내"}
                        subInfo={MENU_LIST.info2}
                      />
                    </li>
                    <li
                      className={`subNav ${curMenu === "program" && "active"}`}
                      onMouseLeave={untoggle}
                    >
                      <HeaderAnimate
                        type={"program"}
                        reset={reset}
                        setReset={setReset}
                        curMenu={curMenu}
                        toggle={setCurMenu}
                        refreshAnimation={handleRefreshAnimation}
                        mainTitle={"프로그램"}
                        subInfo={MENU_LIST.program}
                      />
                    </li>
                    <li
                      className={`subNav ${curMenu === "board" && "active"}`}
                      onMouseLeave={untoggle}
                    >
                      <HeaderAnimate
                        type={"board"}
                        reset={reset}
                        setReset={setReset}
                        curMenu={curMenu}
                        toggle={setCurMenu}
                        refreshAnimation={handleRefreshAnimation}
                        mainTitle={"공지사항"}
                        subInfo={MENU_LIST.board}
                      />
                    </li>

                    <li className={"subNav"}>
                      {state.id && state.accessToken ? (
                        <a onClick={handleLogout}>
                          <img src={Login} alt="로그인" />
                          <span>로그아웃</span>
                        </a>
                      ) : (
                        <Link to="/login" onClick={untoggle}>
                          <img src={Login} alt="로그인" />
                          <span>로그인</span>
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/temp7">Temp7</Link>
        <Link to="/temp9">temp9</Link>
        <Link to="/testPage">TestPage</Link>
        <Link to="/notice">공지사항</Link>
      </nav> */}
      <Outlet />
      <Footer />
    </>
  );
};

export default Header;
