import React from "react";
import Slogan from "../../asset/img/sub/txt_slogan.png";
import Table1 from "../../asset/img/sub/table_intro01.png";
import Table2 from "../../asset/img/sub/table_intro02.png";
import styled from "styled-components";
import { HiOutlinePlay } from "react-icons/hi";
import { redirect } from "react-router-dom";

export const InfoSection1 = () => {
  return (
    <div className="intro_top">
      <span>
        <img src={Slogan} />
      </span>
      <div className="text_area">
        <span>행사개요</span>
        <ul>
          <li>
            <span>행사명</span>
            <div className="text">
              <p>디지털 대전환 시대의 주역, 2022 SW인재페스티벌</p>
            </div>
          </li>
          <li>
            <span>행사목적</span>
            <div className="text">
              <p>
                SW산업을 이끌어갈 인재양성의 중요성을 고취하고 SW중심대학 사업과
                대학교육의 성과 확산을 통한 대국민 SW교육 신뢰 확보 및 SW가치
                확산 추진
              </p>
            </div>
          </li>
          <li>
            <span>행사주제</span>
            <div className="text">
              <p>디지털 인재를 넘어 글로벌 리더로</p>
            </div>
          </li>
          <li>
            <span>주최/주관</span>
            <div className="text">
              <p>
                주최 : 과학기술정보통신부
                <br />
                주관 : 정보통신기획평가원, (사)소프트웨어중심대학협의회
              </p>
            </div>
          </li>
          <li>
            <span>행사일시</span>
            <div className="text">
              <p>2022. 12. 8(목) – 12. 9(금) 10:00~17:00</p>
            </div>
          </li>
          <li>
            <span>행사장소</span>
            <div className="text">
              <p>동대문디자인플라자(DDP), 아트홀 1관</p>
              <p className="alert asterisk">
                코로나19 방역수칙 준수, 일부 행사일정 온라인(유튜브, 네이버TV)
                동시 송출
              </p>
            </div>
          </li>
          <li>
            <span>주요내용</span>
            <div className="text">
              <p>
                SW중심대학 주요성과 및 산학협력프로젝트를 통해 도출된 우수성과물
                소개•전시
              </p>
            </div>
          </li>
          <li>
            <span>대상</span>
            <div className="text">
              <p>SW중심대학(44개교) 및 SW에 관심있는 누구나</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

const PLeft = styled.p`
  text-align: left;
  margin-top: 0.5em;
  &:last-child {
    margin-top: 0;
  }
`;

export const InfoSection2 = ({ type }) => {
  return (
    <div className="section_table">
      <div className="table_area">
        {/* <img className="table" src={Table1} alt="" /> */}
        <table className="table tbl01 introTable">
          <colgroup>
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />

            <col style={{ width: "55%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>일정</th>
              <th>시간</th>

              <th>주요내용</th>
              <th>장소</th>
            </tr>
          </thead>
          <tbody>
            <>
              <tr>
                <td rowSpan={6}>
                  <b>12.08일(목)</b>

                  <br />
                  <small>(1일차)</small>
                </td>
                <td className={"time"}>10:00 ~ 12:00</td>

                <td className="left">우수작품 발표 1부(20개팀)</td>
                <td rowSpan={5}>
                  아트홀 1<br />
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        background: "#be1b34",
                        borderRadius: "5px",
                        padding: "0.1em 0.5em",
                      }}
                    >
                      {" "}
                      <HiOutlinePlay />
                      <span>생중계</span>
                    </div>
                  </span>
                </td>
              </tr>
              {/* 개막식 */}
              <tr>
                <td className={"time"}>14:00 ~ 14:16</td>

                <td className="left">
                  개회사
                  <span className={"who"}>
                    (<b>전성배</b> IITP 원장)
                  </span>
                  <br />
                  격려사
                  <span className={"who"}>
                    (<b>박윤규</b> 과기정통부 제2차관)
                  </span>
                  <br />
                  환영사
                  <span className={"who"}>
                    (<b>나연묵</b> SW중심대학협의회 회장)
                  </span>
                  <br />
                  축&nbsp;&nbsp;&nbsp;사
                  <span className={"who"}>
                    (<b>서정연</b> 디지털 인재 얼라이언스 위원장)
                  </span>
                </td>
              </tr>
              {/* 포상식 */}
              <tr>
                <td className={"time"}>14:16 ~ 14:22</td>

                <td className="left">유공자 포상</td>
              </tr>
              {/* 시상식 */}
              <tr>
                <td className={"time"}>14:22 ~ 15:25</td>

                <td className="left">우수자 시상</td>
              </tr>
              {/* 시상식 2  */}
              <tr>
                <td className={"time"}>15:25 ~ 17:45</td>
                <td className="left">우수작품 발표 2부 (24개팀)</td>
                {/* <td>〃</td> */}
              </tr>

              {/* 강연 */}
              <tr>
                <td className={"time"}>15:00 ~ 17:00</td>

                <td>
                  <PLeft style={{ marginTop: 0 }}>SW중심대학/기업 강연</PLeft>
                  <div className={"subSection"}>
                    <span className={"subSectionTitle"}>1부</span>
                    <div className={"subSectionInfo"}>
                      <p>
                        <span className={"lTitle"}>· AI의 미래 </span>
                        <span className={"lWho"}>
                          | 숭실대 <b>장의진</b> 교수
                        </span>
                      </p>
                      <p>
                        <span className={"lTitle"}>· 생활 속 SW </span>
                        <span className={"lWho"}>
                          | 이화여대 <b>양재희</b> 교수
                        </span>
                      </p>
                      <p className={"helpText"}>대상 : 고등학생 및 일반</p>
                    </div>
                  </div>
                  <div className={"subSection"}>
                    <span className={"subSectionTitle"}>2부</span>
                    <div className={"subSectionInfo"}>
                      <p>
                        <span className={"lTitle"}>
                          · 개발자 성장과 커뮤니티 활동{" "}
                        </span>
                        <span className={"lWho"}>
                          | SKT <b>김상기</b> DevRel
                        </span>
                      </p>
                      <p>
                        <span className={"lTitle"}>
                          · IT 기업이 바라는 개발자 덕목 10가지{" "}
                        </span>
                        <span className={"lWho"}>
                          | 넷마블 <b>오인수</b> AI센터장
                        </span>
                      </p>
                      <p className={"helpText"}>대상 : 대학생 및 일반</p>
                    </div>
                  </div>
                </td>
                <td>컨퍼런스 홀</td>
              </tr>
            </>

            <>
              <tr>
                <td rowSpan={4}>
                  <b>12.09일(금)</b>
                  <br />
                  <small>(2일차)</small>
                </td>
                <td rowSpan={1} className={"time"}>
                  10:00 ~ 12:00
                </td>

                <td className="left">
                  <PLeft style={{ marginTop: 0 }}>명사특강</PLeft>
                  <div className={"subSection"}>
                    <span className={"subSectionTitle"}>1부</span>
                    <div className={"subSectionInfo"}>
                      <p>
                        <span className={"lTitle"}>
                          · 디지털 트랜스포메이션 시대와 인재{" "}
                        </span>
                        <span className={"lWho"}>
                          | <b>김태원</b>
                        </span>
                      </p>

                      <p className={"helpText"}>대상 : 대학생 및 일반</p>
                    </div>
                  </div>
                  <div className={"subSection"}>
                    <span className={"subSectionTitle"}>2부</span>
                    <div className={"subSectionInfo"}>
                      <p>
                        <span className={"lTitle"}>
                          · 디지털 대전환 시대, 공간의 미래{" "}
                        </span>
                        <span className={"lWho"}>
                          | <b>유현준</b>
                        </span>
                      </p>
                      <p className={"helpText"}>대상 : 일반</p>
                      <p className={"helpText"} style={{ color: "red" }}>
                        * 2부 명사특강은 중계없이, 현장강연으로 진행됩니다.
                      </p>
                    </div>
                  </div>
                </td>
                <td rowSpan={4}>
                  아트홀 1
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        background: "#be1b34",
                        borderRadius: "5px",
                        padding: "0.1em 0.5em",
                      }}
                    >
                      {" "}
                      <HiOutlinePlay />
                      <span>생중계</span>
                    </div>
                  </span>
                </td>
              </tr>

              <tr>
                <td rowSpan={1} className={"time"}>
                  14:00 ~ 16:00
                </td>

                <td className="left">
                  <PLeft style={{ marginTop: 0 }}>토크콘서트</PLeft>
                  <div className={"subSection"}>
                    <span className={"subSectionTitle"}>1부</span>
                    <div className={"subSectionInfo"}>
                      <p>
                        <span className={"lTitle"}>· SW방의 문제아들 </span>
                        <span style={{ display: "block", marginLeft: ".8em" }}>
                          SW에 살고 죽는 SW인재 4인방의 퀴즈&토크쇼
                        </span>
                      </p>

                      <p className={"helpText"}>대상 : 대학생 및 일반</p>

                      <div className={"panelWrap"}>
                        <label>패널</label>
                        <div className={"panelList"}>
                          <span>이채린 대표 (클라썸)</span>
                          <span>박건희 매니저 (지마켓)</span>
                          <span>김성진 팀장 (카카오)</span>
                          <span>조준영 연구원 (LG전자)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"subSection"}>
                    <span className={"subSectionTitle"}>2부</span>
                    <div className={"subSectionInfo"}>
                      <p>
                        <span className={"lTitle"}>
                          · 소중대 사랑의 콜센타{" "}
                        </span>
                        <span style={{ display: "block", marginLeft: ".8em" }}>
                          사연과 신청곡을 직접 들어보는 SW인재들의 음악&토크쇼
                        </span>
                      </p>

                      <p className={"helpText"}>대상 : 대학생 및 일반</p>
                      <div className={"panelWrap"}>
                        <label>패널</label>
                        <div className={"panelList"}>
                          <span>박정현 학생 (호서대학교)</span>
                          <span>조우연 학생 (아주대학교)</span>
                          <span>이강욱 학생 (충북대학교)</span>
                          <span>허유민 학생 (성균관대학교)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td className={"time"}>16:30 ~ 17:00</td>

                <td className={"left"}>
                  폐막식(인기상 시상, 경품, 이벤트 발표)
                </td>
              </tr>
            </>

            {/* 12월 9일 금  */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const InfoSection2Mobile = () => {
  return (
    <div className={"section_table mobile"}>
      <label className={"programTitle"}>12. 8일(목), 1일차</label>
      <span className={"pLocation"}>[아트홀 1]</span>
      <ul>
        <li>
          <span className={"pTime"}>10:00 ~ 12:00</span>
          <p>우수작품 발표 1부 (20개팀)</p>
        </li>
      </ul>
    </div>
  );
};

export const InfoSection3 = () => {
  return (
    <div className="section_table">
      <span className="table_title">2022. 12. 09 (금)</span>
      <div className="table_area">
        <img className="table" src={Table2} alt="" />
        <table className="table tbl01">
          <colgroup>
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "55%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>시간</th>
              <th>주요내용</th>
              <th>장소</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="2">명사특강</td>
              <td rowSpan="2">10:00 ~ 12:00</td>
              <td className="left">
                <b>1부: 디지털 트랜스포메이션 시대와 인재</b>
                <br />- 강연자 : 김태원
              </td>
              <td rowSpan="5">아트홀1</td>
            </tr>
            <tr>
              <td className="left">
                <b>2부: 디지털 대전환 시대, 공간의 미래</b>
                <br />- 강연자: 유현준
              </td>
            </tr>
            <tr>
              <td rowSpan="2">
                토크콘서트
                <br />
                (MC: 김승주 아나운서)
              </td>
              <td rowSpan="2">14:00 ~ 16:00</td>
              <td className="left">
                <b>1부: 'SW방의 문제아들'</b>
                <br />- SW에 살고 죽는 SW인재 4인방의 퀴즈&토크쇼
              </td>
              {/* <td rowSpan="2">〃</td> */}
            </tr>
            <tr>
              <td className="left">
                <b>2부: '소중대 사랑의 콜센타'</b>
                <br />- 사연과 신청곡을 직접 들어보는 SW인재들의 음악&토크쇼
              </td>
            </tr>
            <tr>
              <td>폐막식</td>
              <td rowSpan="2">16:30 ~ 17:00</td>
              <td className="left">인기상 시상, 경품·이벤트 발표 등</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const InfoSection4 = () => {
  return (
    <div className="section_table">
      <span
        style={{
          marginLeft: 0,
          marginRight: 0,
          textAlign: "left",
          position: "relative",
          display: "table",
          margin: "1em 0 0.5em",
          fontFamily: "SCDream-Bold",
          fontSize: "1.5em",
          fontWeight: "600",
          textAlign: "center",
          color: "#001650",
        }}
      >
        시상식 [1부]
      </span>
      <span
        style={{
          marginLeft: 0,
          marginRight: 0,
          textAlign: "left",
          position: "relative",
          display: "table",
          margin: "0.2em 0 0.2em",
          fontFamily: "SCDream-Bold",
          fontSize: "1.1em",
          fontWeight: "600",
          textAlign: "center",
          color: "#001650",
        }}
      >
        일정 : 22. 12. 8(목), 14:22 ~ 15:00
      </span>
      <span
        style={{
          marginLeft: 0,
          marginRight: 0,
          textAlign: "left",
          position: "relative",
          display: "table",
          margin: "0.2em 0 0.2em",
          fontFamily: "SCDream-Bold",
          fontSize: "1.1em",
          fontWeight: "600",
          textAlign: "center",
          color: "#001650",
        }}
      >
        장소 : 아트홀 1
      </span>
      <div className="table_area">
        <br />
        <table className="table tbl04">
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th colSpan={2}>훈격</th>
              <th>수상팀</th>
              <th>상금(만원)</th>
              <th>심사방법</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="6" className="title">
                <span>2022 SW인재페스티벌</span>
                <br />
                우수작품 시상
                <br />
                <small>(44개교 산학협력 프로젝트 결과물)</small>
              </td>

              <td>대상</td>
              <td>과학기술정보통신부장관상</td>
              <td>1</td>
              <td>300</td>
              <td rowSpan={5}>산학연 심사위원 사전 심사</td>
            </tr>
            <tr>
              <td>최우수상</td>
              <td>정보통신기획평가원장상</td>
              <td>2</td>
              <td>각 100</td>
            </tr>
            <tr>
              <td>우수상</td>
              <td>소프트웨어중심대학협의회장상</td>
              <td>3</td>
              <td>각 50</td>
            </tr>
            <tr>
              <td>특별상</td>
              <td>학회, 협회</td>
              <td>4</td>
              <td>각 30</td>
            </tr>
            <tr>
              <td>기업상</td>
              <td>-</td>
              <td>6</td>
              <td>각 30</td>
            </tr>
            <tr>
              <td>인기상</td>
              <td>-</td>
              <td>2</td>
              <td>각 20</td>
              <td>
                현장 투표
                <br />
                (폐막식시상)
              </td>
            </tr>
            <tr className="total">
              <td colSpan="3">합계</td>
              <td>18</td>
              <td>990</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
        <br />

        <table className="table tbl04">
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th colSpan="2">훈격</th>
              <th>수상팀</th>
              <th>상금(만원)</th>
              <th>심사방법</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="4" className="title">
                2022 공동 AI 경진대회 수상팀 시상
              </td>
              <td>대상</td>
              <td>과학기술정보통신부장관상</td>
              <td>1</td>
              <td>300</td>
              <td rowSpan="4">산학연 심사위원 심사</td>
            </tr>
            <tr>
              <td>최우수상</td>
              <td>정보통신기획평가원장상</td>
              <td>2</td>
              <td>각 100</td>
            </tr>
            <tr>
              <td>우수상</td>
              <td>소프트웨어중심대학협의회장상</td>
              <td>3</td>
              <td>각 50</td>
            </tr>
            <tr>
              <td>장려상</td>
              <td>소프트웨어중심대학협의회장상</td>
              <td>10</td>
              <td>각 30</td>
            </tr>
            <tr className="total">
              <td colSpan="3">합계</td>
              <td>16</td>
              <td>950</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>

        <br />
        <span
          style={{
            marginLeft: 0,
            marginRight: 0,
            textAlign: "left",
            position: "relative",
            display: "table",
            margin: "1em 0 0.5em",
            fontFamily: "SCDream-Bold",
            fontSize: "1.5em",
            fontWeight: "600",
            textAlign: "center",
            color: "#001650",
          }}
        >
          시상식 [2부]
        </span>
        <span
          style={{
            marginLeft: 0,
            marginRight: 0,
            textAlign: "left",
            position: "relative",
            display: "table",
            margin: "0.2em 0 0.2em",
            fontFamily: "SCDream-Bold",
            fontSize: "1.1em",
            fontWeight: "600",
            textAlign: "center",
            color: "#001650",
          }}
        >
          일정 : 22. 12. 9(금), 15:15 ~ 15:25
        </span>
        <span
          style={{
            marginLeft: 0,
            marginRight: 0,
            textAlign: "left",
            position: "relative",
            display: "table",
            margin: "0.2em 0 0.2em",
            fontFamily: "SCDream-Bold",
            fontSize: "1.1em",
            fontWeight: "600",
            textAlign: "center",
            color: "#001650",
          }}
        >
          장소 : 아트홀 1
        </span>
        <br />
        <table className="table tbl04">
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th colSpan="2">훈격</th>
              <th>수상팀</th>
              <th>상금(만원)</th>
              <th>심사방법</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="4" className="title">
                2022 소중인 브이로그 영상 공모전
              </td>
              <td>대상</td>
              <td rowSpan="4">소프트웨어중심대학협의회장상</td>
              <td>1</td>
              <td>100</td>
              <td rowSpan="4">대외홍보 TFT 위원 심사</td>
            </tr>
            <tr>
              <td>최우수상</td>
              <td>1</td>
              <td>50</td>
            </tr>
            <tr>
              <td>우수상</td>
              <td>2</td>
              <td>각 30</td>
            </tr>
            <tr>
              <td>장려상</td>
              <td>3</td>
              <td>각 10</td>
            </tr>
            <tr className="total">
              <td colSpan="3">합계</td>
              <td>7</td>
              <td>240</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};
