import U1 from "../asset/img/common/ci_univ01.png";
import U2 from "../asset/img/common/ci_univ02.png";
import U3 from "../asset/img/common/ci_univ03.png";
import U4 from "../asset/img/common/ci_univ04.png";
import U5 from "../asset/img/common/ci_univ05.png";
import U6 from "../asset/img/common/ci_univ06.png";
import U7 from "../asset/img/common/ci_univ07.png";
import U8 from "../asset/img/common/ci_univ08.png";
import U9 from "../asset/img/common/ci_univ09.png";
import U10 from "../asset/img/common/ci_univ10.png";
import U11 from "../asset/img/common/ci_univ11.png";
import U12 from "../asset/img/common/ci_univ12.png";
import U13 from "../asset/img/common/ci_univ13.png";
import U14 from "../asset/img/common/ci_univ14.png";
import U15 from "../asset/img/common/ci_univ15.png";
import U16 from "../asset/img/common/ci_univ16.png";
import U17 from "../asset/img/common/ci_univ17.png";
import U18 from "../asset/img/common/ci_univ18.png";
import U19 from "../asset/img/common/ci_univ19.png";
import U20 from "../asset/img/common/ci_univ20.png";
import U21 from "../asset/img/common/ci_univ21.png";
import U22 from "../asset/img/common/ci_univ22.png";
import U23 from "../asset/img/common/ci_univ23.png";
import U24 from "../asset/img/common/ci_univ24.png";
import U25 from "../asset/img/common/ci_univ25.png";
import U26 from "../asset/img/common/ci_univ26.png";
import U27 from "../asset/img/common/ci_univ27.png";
import U28 from "../asset/img/common/ci_univ28.png";
import U29 from "../asset/img/common/ci_univ29.png";
import U30 from "../asset/img/common/ci_univ30.png";
import U31 from "../asset/img/common/ci_univ31.png";
import U32 from "../asset/img/common/ci_univ32.png";
import U33 from "../asset/img/common/ci_univ33.png";
import U34 from "../asset/img/common/ci_univ34.png";
import U35 from "../asset/img/common/ci_univ35.png";
import U36 from "../asset/img/common/ci_univ36.png";
import U37 from "../asset/img/common/ci_univ37.png";
import U38 from "../asset/img/common/ci_univ38.png";
import U39 from "../asset/img/common/ci_univ39.png";
import U40 from "../asset/img/common/ci_univ40.png";
import U41 from "../asset/img/common/ci_univ41.png";
import U42 from "../asset/img/common/ci_univ42.png";
import U43 from "../asset/img/common/ci_univ43.png";
import U44 from "../asset/img/common/ci_univ44.png";

import L1 from "../asset/img/sub/logo_pu01.png";
import L2 from "../asset/img/sub/logo_pu02.png";
import L3 from "../asset/img/sub/logo_pu03.png";
import L4 from "../asset/img/sub/logo_pu04.png";
import L5 from "../asset/img/sub/logo_pu05.png";
import L6 from "../asset/img/sub/logo_pu06.png";
import L7 from "../asset/img/sub/logo_pu07.png";
import L8 from "../asset/img/sub/logo_pu08.png";
import L9 from "../asset/img/sub/logo_pu09.png";
import L10 from "../asset/img/sub/logo_pu10.png";
import L11 from "../asset/img/sub/logo_pu11.png";
import L12 from "../asset/img/sub/logo_pu12.png";
import L13 from "../asset/img/sub/logo_pu13.png";
import L14 from "../asset/img/sub/logo_pu14.png";
import L15 from "../asset/img/sub/logo_pu15.png";
import L16 from "../asset/img/sub/logo_pu16.png";
import L17 from "../asset/img/sub/logo_pu17.png";
import L18 from "../asset/img/sub/logo_pu18.png";
import L19 from "../asset/img/sub/logo_pu19.png";
import L20 from "../asset/img/sub/logo_pu20.png";
import L21 from "../asset/img/sub/logo_pu21.png";
import L22 from "../asset/img/sub/logo_pu22.png";
import L23 from "../asset/img/sub/logo_pu23.png";
import L24 from "../asset/img/sub/logo_pu24.png";
import L25 from "../asset/img/sub/logo_pu25.png";
import L26 from "../asset/img/sub/logo_pu26.png";
import L27 from "../asset/img/sub/logo_pu27.png";
import L28 from "../asset/img/sub/logo_pu28.png";
import L29 from "../asset/img/sub/logo_pu29.png";
import L30 from "../asset/img/sub/logo_pu30.png";
import L31 from "../asset/img/sub/logo_pu31.png";
import L32 from "../asset/img/sub/logo_pu32.png";
import L33 from "../asset/img/sub/logo_pu33.png";
import L34 from "../asset/img/sub/logo_pu34.png";
import L35 from "../asset/img/sub/logo_pu35.png";
import L36 from "../asset/img/sub/logo_pu36.png";
import L37 from "../asset/img/sub/logo_pu37.png";
import L38 from "../asset/img/sub/logo_pu38.png";
import L39 from "../asset/img/sub/logo_pu39.png";
import L40 from "../asset/img/sub/logo_pu40.png";
import L41 from "../asset/img/sub/logo_pu41.png";
import L42 from "../asset/img/sub/logo_pu42.png";
import L43 from "../asset/img/sub/logo_pu43.png";
import L44 from "../asset/img/sub/logo_pu44.png";

import BG1 from "../asset/img/sub/1.jpg";
import BG2 from "../asset/img/sub/1.jpg";
import BG3 from "../asset/img/sub/1.jpg";
import BG4 from "../asset/img/sub/1.jpg";
import BG5 from "../asset/img/sub/1.jpg";
import BG6 from "../asset/img/sub/1.jpg";
import BG7 from "../asset/img/sub/1.jpg";
import BG8 from "../asset/img/sub/1.jpg";
import BG9 from "../asset/img/sub/1.jpg";
import BG10 from "../asset/img/sub/1.jpg";
import BG11 from "../asset/img/sub/1.jpg";
import BG12 from "../asset/img/sub/1.jpg";
import BG13 from "../asset/img/sub/1.jpg";
import BG14 from "../asset/img/sub/1.jpg";
import BG15 from "../asset/img/sub/1.jpg";
import BG16 from "../asset/img/sub/1.jpg";
import BG17 from "../asset/img/sub/1.jpg";
import BG18 from "../asset/img/sub/1.jpg";
import BG19 from "../asset/img/sub/1.jpg";
import BG20 from "../asset/img/sub/1.jpg";
import BG21 from "../asset/img/sub/1.jpg";
import BG22 from "../asset/img/sub/1.jpg";
import BG23 from "../asset/img/sub/1.jpg";
import BG24 from "../asset/img/sub/1.jpg";
import BG25 from "../asset/img/sub/1.jpg";
import BG26 from "../asset/img/sub/1.jpg";
import BG27 from "../asset/img/sub/1.jpg";
import BG28 from "../asset/img/sub/1.jpg";
import BG29 from "../asset/img/sub/1.jpg";
import BG30 from "../asset/img/sub/1.jpg";
import BG31 from "../asset/img/sub/1.jpg";
import BG32 from "../asset/img/sub/1.jpg";
import BG33 from "../asset/img/sub/1.jpg";
import BG34 from "../asset/img/sub/1.jpg";
import BG35 from "../asset/img/sub/1.jpg";
import BG36 from "../asset/img/sub/1.jpg";
import BG37 from "../asset/img/sub/1.jpg";
import BG38 from "../asset/img/sub/1.jpg";
import BG39 from "../asset/img/sub/1.jpg";
import BG40 from "../asset/img/sub/1.jpg";
import BG41 from "../asset/img/sub/1.jpg";
import BG42 from "../asset/img/sub/1.jpg";
import BG43 from "../asset/img/sub/1.jpg";
import BG44 from "../asset/img/sub/1.jpg";

// import PDF1 from "../asset/pdf/ebook01.pdf";
// import PDF2 from "../asset/pdf/ebook02.pdf";
// import PDF3 from "../asset/pdf/ebook03.pdf";
// import PDF4 from "../asset/pdf/ebook04.pdf";
// import PDF5 from "../asset/pdf/ebook05.pdf";
// import PDF6 from "../asset/pdf/ebook06.pdf";
// import PDF7 from "../asset/pdf/ebook07.pdf";
// import PDF8 from "../asset/pdf/ebook08.pdf";
// import PDF9 from "../asset/pdf/ebook09.pdf";
// import PDF10 from "../asset/pdf/ebook10.pdf";
// import PDF11 from "../asset/pdf/ebook11.pdf";
// import PDF12 from "../asset/pdf/ebook12.pdf";
// import PDF13 from "../asset/pdf/ebook13.pdf";
// import PDF14 from "../asset/pdf/ebook14.pdf";
// import PDF15 from "../asset/pdf/ebook15.pdf";
// import PDF16 from "../asset/pdf/ebook16.pdf";
// import PDF17 from "../asset/pdf/ebook17.pdf";
// import PDF18 from "../asset/pdf/ebook18.pdf";
// import PDF19 from "../asset/pdf/ebook19.pdf";
// import PDF20 from "../asset/pdf/ebook20.pdf";
// import PDF21 from "../asset/pdf/ebook21.pdf";
// import PDF22 from "../asset/pdf/ebook22.pdf";
// import PDF23 from "../asset/pdf/ebook23.pdf";
// import PDF24 from "../asset/pdf/ebook24.pdf";
// import PDF25 from "../asset/pdf/ebook25.pdf";
// import PDF26 from "../asset/pdf/ebook26.pdf";
// import PDF27 from "../asset/pdf/ebook27.pdf";
// import PDF28 from "../asset/pdf/ebook28.pdf";
// import PDF29 from "../asset/pdf/ebook29.pdf";
// import PDF30 from "../asset/pdf/ebook30.pdf";
// import PDF31 from "../asset/pdf/ebook31.pdf";
// import PDF32 from "../asset/pdf/ebook32.pdf";
// import PDF33 from "../asset/pdf/ebook33.pdf";
// import PDF34 from "../asset/pdf/ebook34.pdf";
// import PDF35 from "../asset/pdf/ebook35.pdf";
// import PDF36 from "../asset/pdf/ebook36.pdf";
// import PDF37 from "../asset/pdf/ebook37.pdf";
// import PDF38 from "../asset/pdf/ebook38.pdf";
// import PDF39 from "../asset/pdf/ebook39.pdf";
// import PDF40 from "../asset/pdf/ebook40.pdf";
// import PDF41 from "../asset/pdf/ebook41.pdf";
// import PDF42 from "../asset/pdf/ebook42.pdf";
// import PDF43 from "../asset/pdf/ebook43.pdf";
// import PDF44 from "../asset/pdf/ebook44.pdf";

// import P01 from "../asset/pdf/panel01.pdf";
// import P02 from "../asset/pdf/panel02.pdf";
// import P03 from "../asset/pdf/panel03.pdf";
// import P04 from "../asset/pdf/panel04.pdf";

const URL = `https://s3.ap-northeast-2.amazonaws.com/mulsarang2021.archive/iitp2022/ebook/`;

const LogoList = [
  L1,
  L2,
  L3,
  L4,
  L5,
  L6,
  L7,
  L8,
  L9,
  L10,
  L11,
  L12,
  L13,
  L14,
  L15,
  L16,
  L17,
  L18,
  L19,
  L20,
  L21,
  L22,
  L23,
  L24,
  L25,
  L26,
  L27,
  L28,
  L29,
  L30,
  L31,
  L32,
  L33,
  L34,
  L35,
  L36,
  L37,
  L38,
  L39,
  L40,
  L41,
  L42,
  L43,
  L44,
];

const imgList = [
  U1,
  U2,
  U3,
  U4,
  U5,
  U6,
  U7,
  U8,
  U9,
  U10,
  U11,
  U12,
  U13,
  U14,
  U15,
  U16,
  U17,
  U18,
  U19,
  U20,
  U21,
  U22,
  U23,
  U24,
  U25,
  U26,
  U27,
  U28,
  U29,
  U30,
  U31,
  U32,
  U33,
  U34,
  U35,
  U36,
  U37,
  U38,
  U39,
  U40,
  U41,
  U42,
  U43,
  U44,
];

export const UnivList_v2 = [
  {
    name: "경희대학교",
    sName: "경희대",
    year: 2017,
    ordered: 1,
    subOrdered: 0,
    team: "뀨와 아이들",
    title: "Virtual Puppet",
    subTitle:
      "RGB 카메라 2대로 사용자의 손짓과 얼굴표정으로 unity에서 인형을 조종한다",
    logo: L7,
    img: U7,
    bg: BG7,
    pdf: URL + "ebook07.pdf",
    iview: URL + "panel07_final2.pdf",
    dataBg: 7,
  },
  {
    name: "광운대학교",
    sName: "광운대",
    year: 2017,
    ordered: 2,
    subOrdered: 0,
    noVote: true,
    team: "우리 잘 수 있을까",
    title: "포켓트레이너(PocketTrainer)",
    subTitle:
      "인공지능 맞춤형 헬스케어 서비스를 제공하는 PT 트레이너 어플리케이션입니다.",
    logo: L8,
    img: U8,
    bg: BG8,
    pdf: URL + "ebook08.pdf",
    iview: URL + "panel08_final.pdf",
    dataBg: 8,
  },
  {
    name: "단국대학교",
    sName: "단국대",
    year: 2017,
    ordered: 3,
    subOrdered: 0,
    team: "pro-seed",
    noVote: true,
    title: "잇다",
    subTitle: "기숙사생들을 위한 공동배달 서비스",
    logo: L10,
    img: U10,
    bg: BG10,
    pdf: URL + "ebook10.pdf",
    iview: URL + "panel10_final.pdf",
    dataBg: 10,
  },
  {
    name: "조선대학교",
    sName: "조선대",
    year: 2017,
    ordered: 4,
    subOrdered: 0,
    team: "밸런스",
    title: "밸런스 팡팡",
    subTitle: "균형 감각을 키우는 기능형 피트니스 게임",
    logo: L33,
    img: U33,
    bg: BG33,
    pdf: URL + "ebook33.pdf",
    iview: URL + "panel33_final.pdf",
    dataBg: 33,
  },
  {
    name: "중앙대학교",
    sName: "중앙대",
    year: 2017,
    ordered: 5,
    subOrdered: 0,
    noVote: true,
    team: "산꾼도시여자들",
    title: "도란쌤",
    subTitle: "AI 도란쌤과 함께하는 일기 지도 교육 프로그램",
    logo: L34,
    img: U34,
    bg: BG34,
    pdf: URL + "ebook34.pdf",
    iview: URL + "panel34_final.pdf",
    dataBg: 34,
  },
  {
    name: "한동대학교",
    sName: "한동대",
    year: 2017,
    ordered: 6,
    subOrdered: 0,
    team: "LearnTube",
    title: "LearnTube: 영상 부분 저장 및 공유 플랫폼",
    subTitle:
      "정보의 바다에서 이젠 원하는 YouTube 영상의, 원하는 부분을 골라, 나만의 라이브러리를 만들고 공유해요",
    logo: L40,
    img: U40,
    bg: BG40,
    pdf: URL + "ebook40.pdf",
    iview: URL + "panel40_final.pdf",
    dataBg: 40,
  },
  {
    name: "강원대학교",
    sName: "강원대",
    year: 2018,
    ordered: 7,
    subOrdered: 0,
    team: "dtx eye",
    title: "가림치료 순응도 측정을 위한 IoT 패치 개발",
    subTitle:
      "디지털 순응도 측정기술을 통해 어린 환자의 가림패치 착용 여부를 확인하여 가림치료를 돕고, 환자의 가림치료 순응도를 향상시킴",
    logo: L2,
    img: U2,
    bg: BG2,
    pdf: URL + "ebook02.pdf",
    iview: URL + "panel02_final.pdf",
    dataBg: 2,
  },
  {
    name: "건국대학교",
    sName: "건국대",
    year: 2018,
    ordered: 8,
    subOrdered: 0,
    team: "Pohihi",
    title: "Pohihi Studio - 실시간 객체 모자이크 스트리밍 서비스",
    subTitle:
      "사용자가 선택한 종류의 다양한 객체를 인식하고, 실시간으로 가공(모자이크 등)하여 외부 플랫폼에 송출하는 서비스이다",
    logo: L3,
    img: U3,
    bg: BG3,
    pdf: URL + "ebook03.pdf",
    iview: URL + "panel03_final.pdf",
    dataBg: 3,
  },
  {
    name: "숭실대학교",
    sName: "숭실대",
    year: 2018,
    ordered: 9,
    subOrdered: 0,
    noVote: true,
    team: "배리어프리",
    title: "Talking Hand",
    subTitle: "농인들을 위한 수어 통역 어플리케이션",
    logo: L21,
    img: U21,
    bg: BG21,
    pdf: URL + "ebook21.pdf",
    iview: URL + "panel21_final.pdf",
    dataBg: 21,
  },
  {
    name: "한림대학교",
    sName: "한림대",
    year: 2018,
    ordered: 10,
    subOrdered: 0,
    team: "COU",
    title: "오픈소스를 활용한 코딩 플랫폼 설계 및 구현",
    subTitle:
      "오픈소스를 활용하여 코딩 플랫폼을 구현하고 설계 분석을 통하여 모델의 장점을 분석 및 활용한다.",
    logo: L41,
    img: U41,
    bg: BG41,
    pdf: URL + "ebook41.pdf",
    iview: URL + "panel41_final.pdf",
    dataBg: 41,
  },
  {
    name: "한양대학교 ERICA",
    sName: "한양대ERICA",
    year: 2018,
    ordered: 11,
    subOrdered: 0,
    team: "레이븐어스",
    title: "우왕좌왕 세종대왕",
    subTitle: "한글의 맛과 멋을 살린 온 가족 카드놀이",
    logo: L43,
    img: U43,
    bg: BG43,
    pdf: URL + "ebook43.pdf",
    iview: URL + "panel43_final.pdf",
    dataBg: 43,
  },
  {
    name: "동명대학교",
    sName: "동명대",
    year: 2018,
    ordered: 12,
    subOrdered: 1,
    team: "안전한 공대생들",
    title: "안전사고 예방을 위한 아두이노 기반 스마트헬멧",
    subTitle:
      "각종 센서를 이용해 사람들에게 헬멧 착용을 유도하고, 방향지시등 기능을 통해 킥보드 이용자와 운전자들과의 소통을 통해 안전사고 예방.",
    logo: L12,
    img: U12,
    bg: BG12,
    pdf: URL + "ebook12.pdf",
    iview: URL + "panel12_final.pdf",
    dataBg: 12,
  },
  {
    name: "선문대학교",
    sName: "선문대",
    year: 2018,
    ordered: 13,
    subOrdered: 1,
    team: "LTT",
    title: "Dr.Vegetable",
    subTitle: "AI기반 시설작물 병충해 진단 서비스",
    logo: L17,
    img: U17,
    bg: BG17,
    pdf: URL + "ebook17.pdf",
    iview: URL + "panel17_final.pdf",
    dataBg: 17,
  },
  {
    name: "우송대학교",
    sName: "우송대",
    year: 2018,
    ordered: 14,
    subOrdered: 1,
    team: "SolDream",
    title: "AI 챗봇 서비스를 활용한 SNS",
    subTitle: "AI 챗봇 기술을 적용하여 사용자 편의성을 제공한 SNS",
    logo: L25,
    img: U25,
    bg: BG25,
    pdf: URL + "ebook25.pdf",
    iview: URL + "panel25_final.pdf",
    dataBg: 25,
  },
  {
    name: "원광대학교",
    sName: "원광대",
    year: 2018,
    ordered: 15,
    subOrdered: 1,
    team: "디지털 헤리티지",
    title: "미륵사지 타임머신",
    subTitle: "지금은 볼 수 없는 미륵사지를 가상서계에서 보자",
    logo: L26,
    img: U26,
    bg: BG26,
    pdf: URL + "ebook26.pdf",
    iview: URL + "panel26_final.pdf",
    dataBg: 26,
  },
  {
    name: "제주대학교",
    sName: "제주대",
    year: 2018,
    ordered: 16,
    subOrdered: 1,
    noVote: true,
    team: "Phishing Killer",
    title:
      "KoBERT 기반의 실시간 통화내용 분석을 통한 보이스피싱 예방 서비스 개발",
    subTitle:
      "본 연구에서는 KoBERT 기반의 보이스피싱 탐지 모델을 이용하여 실시간으로 통화내용을 분석하고 보이스피싱 위험도를 제공하는 서비스를 개발합니다.",
    logo: L32,
    img: U32,
    bg: BG32,
    pdf: URL + "ebook32.pdf",
    iview: URL + "panel32_final.pdf",
    dataBg: 32,
  },
  {
    name: "대구가톨릭대학교",
    sName: "대구가톨릭대",
    year: 2019,
    ordered: 17,
    subOrdered: 0,
    team: "서치(Search)",
    title: "아토피 피부 질환자를 위한 화장품 검색 및 성분 확인 시스템",
    subTitle:
      "이미지를 통해 화장품의 성분을 편리하게 검색할 수 있는 OCR기반의 성분 정보 검색 시스템",
    logo: L11,
    img: U11,
    bg: BG11,
    pdf: URL + "ebook11.pdf",
    iview: URL + "panel11_final.pdf",
    dataBg: 11,
  },
  {
    name: "안동대학교",
    sName: "안동대",
    year: 2019,
    ordered: 18,
    subOrdered: 0,
    team: "우아한 찜닭들",
    title: "너의 얼굴은。",
    subTitle: "딥러닝과 영상처리 기술을 통한 얼굴인식 시스템입니다.",
    logo: L23,
    img: U23,
    bg: BG23,
    pdf: URL + "ebook23_final.pdf",
    iview: URL + "panel23_final2.pdf",
    dataBg: 23,
  },
  {
    name: "연세대학교 미래",
    sName: "연세대미래",
    year: 2019,
    ordered: 19,
    subOrdered: 0,
    team: "ForestEnv",
    noVote: true,
    title: "ButterflyEffect🦋",
    subTitle:
      "Butterfly Effect는 사용자의 이메일을 분류하고 불필요한 이메일을 삭제함으로써 사용자에게 감축시킨 탄소 배출량을 통계적 수치로 제공합니다.",
    logo: L24,
    img: U24,
    bg: BG24,
    pdf: URL + "ebook24.pdf",
    iview: URL + "panel24_final.pdf",
    dataBg: 24,
  },
  {
    name: "이화여자대학교",
    sName: "이화여대",
    year: 2019,
    ordered: 20,
    subOrdered: 0,
    noVote: true,
    team: "뱅뱅뱅크",
    title: "깊이 추정과 객체 검출을 사용한 조난자 수색 자율주행 드론",
    subTitle:
      "깊이 추정과 객체 검출 기술을 사용한 조난자를 탐지 및 앱을 통한 조난자의 위치 및 상황 알림",
    logo: L27,
    img: U27,
    bg: BG27,
    pdf: URL + "ebook27.pdf",
    iview: URL + "panel27_final.pdf",
    dataBg: 27,
  },
  {
    name: "충북대학교",
    sName: "충북대",
    year: 2019,
    ordered: 21,
    subOrdered: 0,
    team: "슬코모(슬기로운 코딩 모임)",
    title: "딥러닝 기반 물자분류 로봇 팔",
    subTitle:
      "분류 모델을 활용하여 물자에 적힌 숫자들을 인식하고 로봇 팔을 사용하여 물자들을 숫자별로 분류하는 시스템",
    logo: L36,
    img: U36,
    bg: BG36,
    pdf: URL + "ebook36.pdf",
    iview: URL + "panel36_final.pdf",
    dataBg: 36,
  },
  {
    name: "동서대학교",
    sName: "동서대",
    year: 2019,
    ordered: 22,
    subOrdered: 1,
    team: "AI + SE",
    title: "딥러닝 알고리즘 기반 교통법규 위반 공익신고 영상 분석 시스템",
    subTitle: "딥러닝 알고리즘 기반 교통법규 위반 공익신고 영상 분석 시스템",
    logo: L13,
    img: U13,
    bg: BG13,
    pdf: URL + "ebook13.pdf",
    iview: URL + "panel13_final.pdf",
    dataBg: 13,
  },
  {
    name: "배재대학교",
    sName: "배제대",
    year: 2019,
    ordered: 23,
    subOrdered: 1,
    team: "OWL",
    title: "Instance Segmentation을 이용한 차량 갇힘 사고 방지 프로그램",
    subTitle: "인공지능으로 어린이를 보호하자, 어린이 사고예방 AI 솔루션",
    logo: L14,
    img: U14,
    bg: BG14,
    pdf: URL + "ebook14.pdf",
    iview: URL + "panel14_final.pdf",
    dataBg: 14,
  },
  {
    name: "상명대학교",
    sName: "상명대",
    year: 2019,
    ordered: 24,
    subOrdered: 1,
    noVote: true,
    team: "Ani-Time",
    title: "AI를 활용한 유기견 모니터링 시스템",
    subTitle: "AI를 통해 유기견을 제보 받고, 찾아주는 통합 솔루션 서비스",
    logo: L16,
    img: U16,
    bg: BG16,
    pdf: URL + "ebook16.pdf",
    iview: URL + "panel16_final.pdf",
    dataBg: 16,
  },
  {
    name: "한국외국어대학교",
    sName: "한국외국어대",
    year: 2019,
    ordered: 25,
    subOrdered: 1,
    team: "오합지졸",
    title: "거래소간의 시세 차이를 이용한 통계적 차익거래 알고리즘의 설계",
    subTitle:
      "두 거래소 간의 가상화폐 데이터와 환율 데이터를 수집해 전처리 한 후, 볼린저 밴드 지표를 이용하여 매수 매도를 진행하는 알고리즘을 설계하고 개발하였다.",
    logo: L38,
    img: U38,
    bg: BG38,
    pdf: URL + "ebook38.pdf",
    iview: URL + "panel38_final.pdf",
    dataBg: 38,
  },
  {
    name: "호서대학교",
    sName: "호서대",
    year: 2019,
    ordered: 26,
    subOrdered: 1,
    team: "Bubba",
    title: "장애인을 위한 영유아 보육 도움 AI 시스템",
    subTitle:
      "3차원 특징 벡터를 이용해 학습한 딥러닝 모델로 울음소리를 5가지 클래스로 분류하여 모든 부모가 보육을 편하게 할수있도록 도와주는 시스템입니다.",
    logo: L44,
    img: U44,
    bg: BG44,
    pdf: URL + "ebook44.pdf",
    iview: URL + "panel44_final.pdf",
    dataBg: 44,
  },
  {
    name: "가천대학교",
    sName: "가천대",
    year: 2021,
    ordered: 27,
    subOrdered: 0,
    team: "옵티멀 프라임",
    title: "SSVCS(Smart Surveillance Video Control System)",
    subTitle:
      "저화질 영상의 중요 장면만 화질을 개선해 스토리지 효율과 화질 유지를 동시에 해결하는 작품",
    logo: L1,
    img: U1,
    bg: BG1,
    pdf: URL + "ebook01.pdf",
    iview: URL + "panel01_final.pdf",
    dataBg: 1,
  },
  {
    name: "경기대학교",
    sName: "경기대",
    year: 2021,
    ordered: 28,
    subOrdered: 0,
    team: "IDEAL",
    title: "PillGood",
    subTitle: "사용자의 건강 상태 분석을 통한 영양제 및 음식 추천 시스템",
    logo: L4,
    img: U4,
    bg: BG4,
    pdf: URL + "ebook04.pdf",
    iview: URL + "panel04_final.pdf",
    dataBg: 4,
  },
  {
    name: "경북대학교",
    sName: "경북대",
    year: 2021,
    ordered: 29,
    subOrdered: 0,
    team: "키잡이들",
    title: "Kubernetes를 활용한 MSA 기반 빅데이터 공동 관리 플랫폼",
    subTitle:
      "Kubernetes 환경에서 Hadoop/Spark 클러스터를 프로비저닝하고 공동 관리할 수 있는 웹 인터페이스를 제공하는 서비스",
    logo: L5,
    img: U5,
    bg: BG5,
    pdf: URL + "ebook05.pdf",
    iview: URL + "panel05_final.pdf",
    dataBg: 5,
  },
  {
    name: "삼육대학교",
    sName: "삼육대",
    year: 2021,
    ordered: 30,
    subOrdered: 0,
    team: "불암산",
    title: "딥러닝 기반 전동 킥보드 탑승자 안전모 착용 감지 시스템",
    subTitle:
      "딥러닝 모형으로 전동 킥보드 탑승자가 안전모를 올바르게 착용했는지 판별하는 시스템.",
    logo: L15,
    img: U15,
    bg: BG15,
    pdf: URL + "ebook15.pdf",
    iview: URL + "panel15_final.pdf",
    dataBg: 15,
  },
  {
    name: "성균관대학교",
    sName: "성균관대",
    year: 2021,
    ordered: 31,
    subOrdered: 0,
    noVote: true,
    team: "Together",
    title: "MetaKids",
    subTitle: "아이들에게 새로운 탐험과 심리적 안정감을 주는 AI Agent",
    logo: L18,
    img: U18,
    bg: BG18,
    pdf: URL + "ebook18.pdf",
    iview: URL + "panel18_final.pdf",
    dataBg: 18,
  },
  {
    name: "순천향대학교",
    sName: "순천향대",
    year: 2021,
    ordered: 32,
    subOrdered: 0,
    noVote: true,
    team: "PLOS(플로스)",
    title: "구르미(보행 약자를 위한 실내 길 안내 보조 보행 기기)",
    subTitle:
      "진동 알림을 통해 실시간으로 실내 경로를 안내하는 보행기 형태의 기기",
    logo: L20,
    img: U20,
    bg: BG20,
    pdf: URL + "ebook20.pdf",
    iview: URL + "panel20_final.pdf",
    dataBg: 20,
  },
  {
    name: "전남대학교",
    sName: "전남대",
    year: 2021,
    ordered: 33,
    noVote: true,
    subOrdered: 0,
    team: "Euclid",
    title: "알고리즘 기반 합승 교통 서비스 TANNAE",
    subTitle:
      "올해 합법화된 택시 합승을 서비스하는 모바일 어플리케이션 서비스입니다.",
    logo: L30,
    img: U30,
    bg: BG30,
    pdf: URL + "ebook30.pdf",
    iview: URL + "panel30_final.pdf",
    dataBg: 30,
  },
  {
    name: "충남대학교",
    sName: "충남대",
    year: 2021,
    ordered: 34,
    subOrdered: 0,
    noVote: true,
    team: "Four Guys",
    title:
      "LoRa Communication과 Yolo V5를 활용한 실시간 사격 감지 및 탄착군 크기 측정 시스템",
    subTitle:
      "IoT 기술과 YOLO를 활용해 사격을 실시간으로 감지한 후 탄착군의 크기를 어플리케이션에 제공하는 시스템",
    logo: L35,
    img: U35,
    bg: BG35,
    pdf: URL + "ebook35.pdf",
    iview: URL + "panel35_final.pdf",
    dataBg: 35,
  },
  {
    name: "한국항공대학교",
    sName: "한국항공대",
    year: 2021,
    ordered: 35,
    subOrdered: 0,
    team: "PET DUDUCK",
    title: "JMSMART WORLD",
    subTitle: "펫 IoT를 기반으로 한 메타버스 게임",
    logo: L39,
    img: U39,
    bg: BG39,
    pdf: URL + "ebook39.pdf",
    iview: URL + "panel39_final.pdf",
    dataBg: 39,
  },
  {
    name: "국민대학교",
    sName: "국민대",
    year: 2022,
    ordered: 36,
    subOrdered: 0,
    team: "How Cook?",
    title: "How Cook?",
    subTitle: "이미지 및 텍스트 인식 기술을 활용한 레시피 추천 서비스",
    logo: L9,
    img: U9,
    bg: BG9,
    pdf: URL + "ebook09.pdf",
    iview: URL + "panel09_final.pdf",
    dataBg: 9,
  },
  {
    name: "숙명여자대학교",
    sName: "숙명여대",
    year: 2022,
    ordered: 37,
    subOrdered: 0,
    noVote: true,
    team: "CafeInBody(카페인바디)",
    title: "CafeInBody(카페인바디)",
    subTitle:
      "스마트폰과 스마트워치를 이용해 개인별 적정 카페인 섭취량을 추천해주는 카페인 트래킹 어플리케이션입니다.",
    logo: L19,
    img: U19,
    bg: BG19,
    pdf: URL + "ebook19.pdf",
    iview: URL + "panel19_final.pdf",
    dataBg: 19,
  },
  {
    name: "아주대학교",
    sName: "아주대",
    year: 2022,
    noVote: true,
    ordered: 38,
    subOrdered: 0,
    team: "성장통",
    title: "골연령을 이용한 성장관리 프로젝트",
    subTitle:
      "골연령검사를 통해 자녀의 성장추세나 미래의 성인예측키를 측정할 수 있으며, 성 조숙증을 판단할 수 있고, 이를 머신러닝을 통해 얻은 골연령예측모델을 활용하여 골 연령 검사에 필요한 시간을 줄이고, 검사의 일관성과 정확성을 높인다. 또한 휴대전화의 어플리케이션을 통해 검사결과를 확인 및 성장추세를 관리할 수 있도록 한다.",
    logo: L22,
    img: U22,
    bg: BG22,
    pdf: URL + "ebook22.pdf",
    iview: URL + "panel22_final.pdf",
    dataBg: 22,
  },
  {
    name: "인하대학교",
    sName: "인하대",
    year: 2022,
    ordered: 39,
    subOrdered: 0,
    noVote: true,
    team: "AIM",
    title: "무인 자율주행 플랫폼 기반 중앙관제형 화재 방재 및 방범시스템",
    subTitle:
      "자율주행 로봇의 24시간 캠퍼스 순찰 기반 중앙관제 센터 AI 기반 화재 식별 및 실시간 영상 확인을 통한 방재 방범 시스템 구축",
    logo: L29,
    img: U29,
    bg: BG29,
    pdf: URL + "ebook29.pdf",
    iview: URL + "panel29_final.pdf",
    dataBg: 29,
  },
  {
    name: "전북대학교",
    sName: "전북대",
    year: 2022,
    ordered: 40,
    subOrdered: 0,
    team: "Good to C U",
    title: "swap(you, i)",
    subTitle: "real-time으로 FaceSwap을 수행해주는 프로젝트",
    logo: L31,
    img: U31,
    bg: BG31,
    pdf: URL + "ebook31.pdf",
    iview: URL + "panel31_final.pdf",
    dataBg: 31,
  },
  {
    name: "한국과학기술원",
    sName: "한국과학기원",
    year: 2022,
    ordered: 41,
    subOrdered: 0,
    noVote: true,
    team: "SOONI Labs",
    title: "BetterWorld",
    subTitle: "Web.3 전용 커뮤니티 플랫폼",
    logo: L37,
    img: U37,
    bg: BG37,
    pdf: URL + "ebook37_1.pdf",
    iview: URL + "panel37_final.pdf",
    dataBg: 37,
  },
  {
    name: "한밭대학교",
    sName: "한밭대",
    year: 2022,
    ordered: 42,
    subOrdered: 0,
    team: "Attention",
    title: "다양한 유형의 안티포렌식에 강인한 딥페이크 탐지 모델 개발",
    subTitle: "적대적 학습을 통한 다양한 안티포렌식에 강인한 딥페이크 탐지기",
    logo: L42,
    img: U42,
    bg: BG42,
    pdf: URL + "ebook42.pdf",
    iview: URL + "panel42_final.pdf",
    dataBg: 42,
  },
  {
    name: "경운대학교",
    sName: "경운대",
    year: 2022,
    ordered: 43,
    subOrdered: 0,
    team: "업그레이드",
    title: "청각장애인을 위한 인공지능 기반 청각 보조 솔루션",
    subTitle: "청각장애인을 위한 인공지능 기반 청각 보조 솔루션",
    logo: L6,
    img: U6,
    bg: BG6,
    pdf: URL + "ebook06.pdf",
    iview: URL + "panel06_final.pdf",
    dataBg: 6,
  },
  {
    name: "인제대학교",
    sName: "인제대",
    year: 2022,
    ordered: 44,
    subOrdered: 0,
    team: "E338",
    title: "드론을 통한 차종 분류 및 번호판 인식하는 웹 페이지",
    subTitle: "real-time으로 FaceSwap을 수행해주는 프로젝트",
    logo: L28,
    img: U28,
    bg: BG28,
    pdf: URL + "ebook28.pdf",
    iview: URL + "panel28_final.pdf",
    dataBg: 28,
  },
];

export const UnivList = [
  {
    name: "가천대학교",
    ordered: 1,
    logo: L1,
    img: U1,
    link: "https://xsw.gachon.ac.kr/cms/",
  },
  {
    name: "강원대학교",
    ordered: 2,
    logo: L2,
    img: U2,
    link: "http://sw.kangwon.ac.kr/",
  },
  {
    name: "건국대학교",
    ordered: 3,
    logo: L3,
    img: U3,
    link: "http://swedu.konkuk.ac.kr",
  },
  {
    name: "경기대학교",
    ordered: 4,
    logo: L4,
    img: U4,
    link: "https://swuniv.kyonggi.ac.kr/",
  },
  {
    name: "경북대학교",
    ordered: 5,
    logo: L5,
    img: U5,
    link: "https://swedu.knu.ac.kr/main/index.html",
  },
  {
    name: "경운대학교",
    ordered: 6,
    logo: L6,
    img: U6,
    link: "https://www.ikw.ac.kr/ase/main.tc#n",
  },
  {
    name: "경희대학교",
    ordered: 7,
    logo: L7,
    img: U7,
    link: "http://swedu.khu.ac.kr",
  },
  {
    name: "광운대학교",
    ordered: 8,
    logo: L8,
    img: U8,
    link: "http://npsw.kw.ac.kr",
  },
  {
    name: "국민대학교",
    ordered: 9,
    logo: L9,
    img: U9,
    link: "https://software.kookmin.ac.kr/software/index.do",
  },
  {
    name: "단국대학교",
    ordered: 10,
    logo: L10,
    img: U10,
    link: "http://swcu.dankook.ac.kr",
  },
  {
    name: "대구카톨릭대학교",
    ordered: 11,
    logo: L11,
    img: U11,
    link: "http://sw.cu.ac.kr/",
  },
  {
    name: "동명대학교",
    ordered: 12,
    logo: L12,
    img: U12,
    link: "http://tusw.tu.ac.kr/",
  },
  {
    name: "동서대학교",
    ordered: 13,
    logo: L13,
    img: U13,
    link: "http://aisw.dongseo.ac.kr",
  },
  {
    name: "배재대학교",
    ordered: 14,
    logo: L14,
    img: U14,
    link: "http://sw.pcu.ac.kr",
  },
  {
    name: "삼육대학교",
    ordered: 15,
    logo: L15,
    img: U15,
    link: "https://www.syu.ac.kr/swuniv/",
  },
  {
    name: "상명대학교",
    ordered: 16,
    logo: L16,
    img: U16,
    link: "http://swai.smu.ac.kr/",
  },
  {
    name: "선문대학교",
    ordered: 17,
    logo: L17,
    img: U17,
    link: "http://swedu.sunmoon.ac.kr",
  },
  {
    name: "성균관대학교",
    ordered: 18,
    logo: L18,
    img: U18,
    link: "https://skb.skku.edu/swuniv/index.do",
  },
  {
    name: "숙명여자대학교",
    ordered: 19,
    logo: L19,
    img: U19,
    link: "https://sw.sookmyung.ac.kr/main/?load_popup=1",
  },
  {
    name: "순천향대학교",
    ordered: 20,
    logo: L20,
    img: U20,
    link: "https://home.sch.ac.kr/sw/",
  },
  {
    name: "숭실대학교",
    ordered: 21,
    logo: L21,
    img: U21,
    link: "http://spartan.ssu.ac.kr",
  },
  {
    name: "아주대학교",
    ordered: 22,
    logo: L22,
    img: U22,
    link: "https://sw.ajou.ac.kr/sw/introduction/business-introduction.do",
  },
  {
    name: "안동대학교",
    ordered: 23,
    logo: L23,
    img: U23,
    link: "http://sw.anu.ac.kr/",
  },
  {
    name: "연세대학교",
    ordered: 24,
    logo: L24,
    img: U24,
    link: "http://swuniv.yonsei.ac.kr",
  },
  {
    name: "우송대학교",
    ordered: 25,
    logo: L25,
    img: U25,
    link: "http://swhit.wsu.ac.kr",
  },
  {
    name: "원광대학교",
    ordered: 26,
    logo: L26,
    img: U26,
    link: "http://sw.wku.ac.kr/",
  },
  {
    name: "이화여자대학교",
    ordered: 27,
    logo: L27,
    img: U27,
    link: "http://my.ewha.ac.kr/swcu/",
  },
  {
    name: "인제대학교",
    ordered: 28,
    logo: L28,
    img: U28,
    link: "https://www.inje.ac.kr/",
  },
  {
    name: "인하대학교",
    ordered: 29,
    logo: L29,
    img: U29,
    link: "https://www.inha.ac.kr/kr/index.do",
  },
  {
    name: "전남대학교",
    ordered: 30,
    logo: L30,
    img: U30,
    link: "https://www.sojoong.kr/www/",
  },
  {
    name: "전북대학교",
    ordered: 31,
    logo: L31,
    img: U31,
    link: "https://www.jbnu.ac.kr/kor/",
  },
  {
    name: "제주대학교",
    ordered: 32,
    logo: L32,
    img: U32,
    link: "http://sw.jejunu.ac.kr",
  },
  {
    name: "조선대학교",
    ordered: 33,
    logo: L33,
    img: U33,
    link: "http://sw.chosun.ac.kr/",
  },
  {
    name: "중앙대학교",
    ordered: 34,
    logo: L34,
    img: U34,
    link: "https://sw.cau.ac.kr",
  },
  {
    name: "충남대학교",
    ordered: 35,
    logo: L35,
    img: U35,
    link: "https://swuniv.cnu.ac.kr/swuniv/index.do",
  },
  {
    name: "충북대학교",
    ordered: 36,
    logo: L36,
    img: U36,
    link: "http://sw7up.cbnu.ac.kr",
  },
  {
    name: "한국과학기술원",
    ordered: 37,
    logo: L37,
    img: U37,
    link: "https://ita.kaist.ac.kr/",
  },
  {
    name: "한국외국어대학교",
    ordered: 38,
    logo: L38,
    img: U38,
    link: "http://soft.hufs.ac.kr/",
  },
  {
    name: "한국항공대학교",
    ordered: 39,
    logo: L39,
    img: U39,
    link: "http://college.kau.ac.kr/web/index.do?siteFlag=sw_www",
  },
  {
    name: "한동대학교",
    ordered: 40,
    logo: L40,
    img: U40,
    link: "http://csee.handong.edu/swplus/",
  },
  {
    name: "한림대학교",
    ordered: 41,
    logo: L41,
    img: U41,
    link: "http://hlsw.hallym.ac.kr",
  },
  {
    name: "한밭대학교",
    ordered: 42,
    logo: L42,
    img: U42,
    link: "https://www.hanbat.ac.kr/kor/",
  },
  {
    name: "한양대학교",
    ordered: 43,
    logo: L43,
    img: U43,
    link: "http://computer.hanyang.ac.kr",
  },
  {
    name: "호서대학교",
    ordered: 44,
    logo: L44,
    img: U44,
    link: "http://aisw.hoseo.ac.kr",
  },
];

export const UnivLogoList = [
  L1,
  L2,
  L3,
  L4,
  L5,
  L6,
  L7,
  L8,
  L9,
  L10,
  L11,
  L12,
  L13,
  L14,
  L15,
  L16,
  L17,
  L18,
  L19,
  L20,
  L21,
  L22,
  L23,
  L24,
  L25,
  L26,
  L27,
  L28,
  L29,
  L30,
  L31,
  L32,
  L33,
  L34,
  L35,
  L36,
  L37,
  L38,
  L39,
  L40,
  L41,
  L42,
  L43,
  L44,
];
export const UnivImgList = () => {
  return [
    U1,
    U2,
    U3,
    U4,
    U5,
    U6,
    U7,
    U8,
    U9,
    U10,
    U11,
    U12,
    U13,
    U14,
    U15,
    U16,
    U17,
    U18,
    U19,
    U20,
    U21,
    U22,
    U23,
    U24,
    U25,
    U26,
    U27,
    U28,
    U29,
    U30,
    U31,
    U32,
    U33,
    U34,
    U35,
    U36,
    U37,
    U38,
    U39,
    U40,
    U41,
    U42,
    U43,
    U44,
  ];
};
