import React, { useState } from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import Info2Tabs from "../../components/Info2Tabs";
import Poster from "../../asset/img/sub/img_poster.png";
import Reg from "../../asset/img/sub/img_reg.png";
import RegImg from "../../asset/img/sub/img_reg.jpg";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import { useNavigate } from "react-router-dom";

import Co01 from "../../asset/img/sub/ci_co01.png";
import Co02 from "../../asset/img/sub/ci_co02.png";
import Co03 from "../../asset/img/sub/ci_co03.png";
import Co04 from "../../asset/img/sub/ci_co04.png";
import Co05 from "../../asset/img/sub/ci_co05.png";
import Co06 from "../../asset/img/sub/ci_co06.png";
import Co07 from "../../asset/img/sub/ci_co07.png";
import Co08 from "../../asset/img/sub/ci_co08.png";
import Co09 from "../../asset/img/sub/ci_co09.png";
import Co10 from "../../asset/img/sub/ci_co10.png";
import Co11 from "../../asset/img/sub/ci_co11.png";
import Co12 from "../../asset/img/sub/ci_co12.png";
import Co13 from "../../asset/img/sub/ci_co13.png";
import Co14 from "../../asset/img/sub/ci_co14.png";

const Regist = () => {
  const [account] = useRecoilState(accountSelect);
  const navi = useNavigate();
  const handleRegist = () => {
    navi("/");
  };

  const [curTab, setCurTab] = useState(1);
  const handleNotArrow = () => {
    alert("오픈 준비중입니다.");
  };

  return (
    <div className={"wrap main"}>
      <div className={"wrap exhibition"}>
        <div className={"container sub"}>
          <BoardSubVisual />
          <Info2Tabs current="regist" />
          <div className="sub_wrap ew_list work">
            {/* <span className="page_title">관람객 사전등록</span> */}
            <div className="tabs">
              <button
                type="button"
                className={`tab_button btn1 ${curTab === 1 ? "active" : ""}`}
                onClick={() => setCurTab(1)}
              >
                사전등록
              </button>
              <button
                type="button"
                className={`tab_button btn2 ${curTab === 2 ? "active" : ""}`}
                onClick={() => setCurTab(2)}
              >
                공동채용관
              </button>
            </div>
            {/* <div className="reg_area">
              <img src={Poster} />
            </div> */}
            {/* <div className="btn_reg">
              <a
                target="_blank"
                style={{ cursor: "pointer" }}
                onClick={handleRegist}
              >
                사전등록하기
              </a>
            </div> */}
            <div className="sections">
              <div
                className={`content content1 ${curTab === 1 ? "active" : ""}`}
              >
                <div
                  className="btn_reg"
                  style={{ marginBottom: "2em", marginTop: "2em" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      maxWidth: "50em",
                      padding: "0.5em 1em",
                      margin: "0 auto 2em",
                      borderRadius: "1em",
                      fontSize: "0.9em",
                      border: "1px solid #8c7eb8",
                    }}
                  >
                    <span
                      style={{
                        color: "#8c7eb8",
                        fontWeight: "bold",
                        marginBottom: "0.3em",
                      }}
                    >
                      사전등록일정 안내
                    </span>
                    <span style={{ color: "#161b5f", fontSize: "0.9em" }}>
                      일시 : ~ 2022년 12월 7일(수), 18시
                    </span>
                    <span style={{ fontSize: "0.9em" }}>
                      <small>
                        * 사전등록 이후에도 온라인 등록은 가능합니다. (사전등록
                        이벤트는 시간 내 등록한 인원만 참여가능)
                      </small>
                    </span>
                  </div>
                  <a
                    target="_blank"
                    style={{ cursor: "pointer" }}
                    href="https://kpkp.sysforu.co.kr/regist.asp"
                  >
                    사전등록하기
                  </a>
                </div>

                <div className="reg_area">
                  <img src={RegImg} />
                </div>
              </div>
              <div
                className={`content content2 ${curTab === 2 ? "active" : ""}`}
              >
                <div
                  className="btn_reg"
                  style={{ marginBottom: "2em", marginTop: "2em" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      maxWidth: "50em",
                      padding: "0.5em 1em",
                      margin: "0 auto 2em",
                      borderRadius: "1em",
                      fontSize: "0.9em",
                      border: "1px solid #8c7eb8",
                    }}
                  >
                    <span
                      style={{
                        color: "#8c7eb8",
                        fontWeight: "bold",
                        marginBottom: "0.3em",
                      }}
                    >
                      공동채용관 상담 사전 신청
                    </span>
                    <span style={{ color: "#161b5f", fontSize: "0.9em" }}>
                      <br />
                      2022 SW인재페스티벌 행사 내, 공동채용관을 운영합니다.
                      (14개 기업 - 디지털 인재 얼라이언스 가입 기업)
                      <br />
                      <br />
                      채용 상담을 받고 싶은 경우, 아래 사전예약을 진행해 주세요.
                      <br />
                      응답 해 주신 내용은 상담관 운영을 위한 {"<"}참고자료{">"}
                      로 활용 예정입니다.
                      <br />
                      <br />
                    </span>
                    <span style={{ fontSize: "0.9em" }}>
                      <small>
                        * 실제 상담을 위해서는 공동채용관 인포데스크 {"<"}
                        현장에서 상담 신청{">"}을 해야 합니다.
                      </small>
                    </span>
                  </div>
                  <a
                    target="_blank"
                    href={"https://forms.gle/fArbexky8oWv8S7x8"}
                  >
                    사전예약하기
                  </a>
                </div>
                <span className="page_title">
                  공동채용관 현황 <small>(14개 기업)</small>
                </span>
                <div className="reg_area">
                  {/* <img src={Poster} /> */}
                  <div className="table_area">
                    <table className="table tbl04">
                      <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "45%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>NO</th>
                          <th>로고</th>
                          <th>기업명</th>
                          <th>대표자</th>
                          <th>채용 분야</th>
                          <th>채용 규모</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <img src={Co01} />
                          </td>
                          <td>주식회사 컨티넘</td>
                          <td>조원준</td>
                          <td className="left">- 웹 및 CS 애플리케이션 개발</td>
                          <td>1명</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <img src={Co02} />
                          </td>
                          <td>㈜이노그리드</td>
                          <td>김명진</td>
                          <td className="left">- 솔루션 개발</td>
                          <td>10명</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <img src={Co03} />
                          </td>
                          <td>위즈코어 주식회사</td>
                          <td>박덕근</td>
                          <td className="left">
                            - 웹 서비스 개발
                            <br />- 신규 IT서비스 기획
                          </td>
                          <td>3명</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            <img src={Co04} />
                          </td>
                          <td>㈜모아데이타</td>
                          <td>한상진</td>
                          <td className="left">
                            - Data Architect <br />
                            - SW Architect <br />
                            - Product Manager <br />
                            - Data Engineer <br />
                            - AI SW Engineer <br />
                            - 헬스케어 해외영업 <br />
                            - 헬스케어 사업기획 PM <br />- 마케팅
                          </td>
                          <td>8명</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            <img src={Co05} />
                          </td>
                          <td>주식회사 자이냅스</td>
                          <td>주동원</td>
                          <td className="left">
                            - AI 음성, 영상, 자연어처리 Engineer
                          </td>
                          <td>10명</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <img src={Co06} />
                          </td>
                          <td>㈜모아소프트</td>
                          <td>장주수</td>
                          <td className="left">
                            - SW개발자 <br />
                            - 프로젝트 관리자 <br />- SW테스터
                          </td>
                          <td>10명</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>
                            <img src={Co07} />
                          </td>
                          <td>㈜더존비즈온</td>
                          <td>김용우</td>
                          <td className="left">
                            - 웹 개발 <br />
                            - ERP 컨설턴트 <br />- ERP 상담/운영
                          </td>
                          <td>00명</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>
                            <img src={Co08} />
                          </td>
                          <td>주식회사 알엠소프트</td>
                          <td>최광훈</td>
                          <td className="left">
                            - Java 개발, 클라우드 전환 개발
                          </td>
                          <td>20명</td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>
                            <img src={Co09} />
                          </td>
                          <td>㈜데이원컴퍼니</td>
                          <td>이강민</td>
                          <td className="left">
                            - 교육사업 PM <br />
                            - 학습관리 매니저 <br />
                            - 커뮤니티 매니저 <br />- 콘텐츠 상품기획자
                          </td>
                          <td>8명</td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>
                            <img src={Co10} />
                          </td>
                          <td>㈜클루커스</td>
                          <td>홍성완</td>
                          <td className="left">
                            - 클라우드 엔지니어 <br />- 클라우드 운영
                          </td>
                          <td>0명</td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>
                            <img src={Co11} />
                          </td>
                          <td>스마트사운드(주)</td>
                          <td>이정호</td>
                          <td className="left">
                            - 앱개발 <br />
                            - 헬스케어 서비스 기획 및 SW설계 <br />- 펌웨어 설계
                            및 개발
                          </td>
                          <td>10명</td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>
                            <img src={Co12} />
                          </td>
                          <td>㈜네비웍스</td>
                          <td>원준희</td>
                          <td className="left">
                            - 응용SW개발자 <br />
                            - 클라이언트 프로그래머 <br />
                            - 백엔드 개발자 <br />- IT품질관리 등
                          </td>
                          <td>15명</td>
                        </tr>
                        <tr>
                          <td>13</td>
                          <td>
                            <img src={Co13} />
                          </td>
                          <td>아이티센그룹</td>
                          <td>강진모</td>
                          <td className="left">
                            - 개발자 <br />
                            - 클라우드 엔지니어 <br />- 네트워크 엔지니어
                          </td>
                          <td>100명</td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>
                            <img src={Co14} />
                          </td>
                          <td>
                            ㈜네트러닝홀딩스
                            <br />
                            한국지사
                          </td>
                          <td>노원석</td>
                          <td className="left">- 웹서비스 개발</td>
                          <td>5명</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regist;
