import React, { useEffect, useState } from "react";
// import Http from "../utils/AxiosConfig";
import MainSection1 from "../components/MainSection1";
import MainSection2 from "../components/MainSection2";
import MainSection3 from "../components/MainSection3";
import MainSection4 from "../components/MainSection4";
import MainSection5 from "../components/MainSection5";
import MainSection6 from "../components/MainSection6";

const Home = () => {
  return (
    <div className="wrap main">
      <div className="container main">
        <MainSection1 />
        <MainSection2 />
        <MainSection3 />
        {/* <MainSection4 /> */}
        <MainSection5 />
        <MainSection6 />
      </div>
    </div>
  );
};

export default Home;
