import React from "react";
import styled, { css, keyframes } from "styled-components";
import TestImg from "../testFixed2.png";

const LoadingBox = () => {
  return (
    <ContentWrap>
      <CubeWrap>
        <Cube cubeType={1}>
          <Test type={1} src={TestImg}></Test>
          <Test type={2} src={TestImg}></Test>
          <Test type={3} src={TestImg}></Test>
          <Test type={4} src={TestImg}></Test>
          <Test type={5} src={TestImg}></Test>
          <Test type={6} src={TestImg}></Test>
        </Cube>
        <Cube cubeType={2}>
          <Test type={1} src={TestImg}></Test>
          <Test type={2} src={TestImg}></Test>
          <Test type={3} src={TestImg}></Test>
          <Test type={4} src={TestImg}></Test>
          <Test type={5} src={TestImg}></Test>
          <Test type={6} src={TestImg}></Test>
        </Cube>
      </CubeWrap>
      <CubeWrap>
        <Cube cubeType={3}>
          <Test type={1} src={TestImg}></Test>
          <Test type={2} src={TestImg}></Test>
          <Test type={3} src={TestImg}></Test>
          <Test type={4} src={TestImg}></Test>
          <Test type={5} src={TestImg}></Test>
          <Test type={6} src={TestImg}></Test>
        </Cube>
        <Cube cubeType={4}>
          <Test type={1} src={TestImg}></Test>
          <Test type={2} src={TestImg}></Test>
          <Test type={3} src={TestImg}></Test>
          <Test type={4} src={TestImg}></Test>
          <Test type={5} src={TestImg}></Test>
          <Test type={6} src={TestImg}></Test>
        </Cube>
      </CubeWrap>
      <CubeWrap>
        <Cube cubeType={5}>
          <Test type={1} src={TestImg}></Test>
          <Test type={2} src={TestImg}></Test>
          <Test type={3} src={TestImg}></Test>
          <Test type={4} src={TestImg}></Test>
          <Test type={5} src={TestImg}></Test>
          <Test type={6} src={TestImg}></Test>
        </Cube>
        <Cube cubeType={6}>
          <Test type={1} src={TestImg}></Test>
          <Test type={2} src={TestImg}></Test>
          <Test type={3} src={TestImg}></Test>
          <Test type={4} src={TestImg}></Test>
          <Test type={5} src={TestImg}></Test>
          <Test type={6} src={TestImg}></Test>
        </Cube>
      </CubeWrap>
    </ContentWrap>
  );
};

const wrap = keyframes`
from{
    opacity: 0;
}
to{
  opacity: 1;
}
`;

const t1 = keyframes`
0%{
  
  transform:rotateY(0) rotateX(0);
}
20%{
  transform:rotateX(0) rotateY(90deg);
}
40%{
  transform: rotateX(90deg) rotateY(90deg);
}
60%{
  transform: rotateX(180deg) rotateY(90deg);
}
80%{
  transform: rotateX(270deg) rotateY(90deg);
}
100%{
  transform: rotateX(270deg) rotateY(360deg);
}
`;
const t2 = keyframes`
0%{
  
  transform:rotateY(0) rotateX(0);
}
20%{
  transform:rotateX(90deg) rotateY(0);
}
40%{
  transform: rotateX(90deg) rotateY(90deg);
}
60%{
  transform: rotateX(90deg) rotateY(180deg);
}
80%{
  transform: rotateX(90deg) rotateY(270deg);
}
100%{
  transform: rotateX(360deg) rotateY(360deg);
}

`;
const t3 = keyframes`
0%{
  
  transform:rotateY(0) rotateX(-90deg);
}
20%{
  transform:rotateX(0) rotateY(0);
}
40%{
  transform: rotateX(-90deg) rotateY(0);
}
60%{
  transform: rotateX(-180deg) rotateY(0);
}
80%{
  transform: rotateX(-270deg) rotateY(0);
}
100%{
  transform: rotateX(-360deg) rotateY(-90deg);
}

`;
const t4 = keyframes`
0%{
  
  transform:rotateY(0) rotateX(0);
}
20%{
  transform:rotateX(-90deg) rotateY(0);
}
40%{
  transform: rotateX(-180deg) rotateY(0);
}
60%{
  transform: rotateX(-180deg) rotateY(-90deg);
}
80%{
  transform: rotateX(-270deg) rotateY(-90deg);
}
100%{
  transform: rotateX(-360deg) rotateY(-180deg);
}
`;
const t5 = keyframes`
0%{
  
  transform:rotateY(0) rotateX(0);
}
20%{
  transform:rotateX(90deg) rotateY(0);
}
40%{
  transform: rotateX(180deg) rotateY(0);
}
60%{
  transform: rotateX(270deg) rotateY(0);
}
80%{
  transform: rotateX(360deg) rotateY(0);
}
100%{
  transform: rotateX(360deg) rotateY(90deg);
}
`;

const t6 = keyframes`
0%{
  
  transform:rotateY(0) rotateX(0);
}
20%{
  transform:rotateX(0) rotateY(-90deg);
}
40%{
  transform: rotateX(90deg) rotateY(-90deg);
}
60%{
  transform: rotateX(90deg) rotateY(-180deg);
}
80%{
  transform: rotateX(90deg) rotateY(-270deg);
}
100%{
  transform: rotateX(90deg) rotateY(-360deg);
}

`;
const cubeSpin = keyframes`
from { transform: rotateY(0) rotateX(0); }
  to   { transform: rotateY(-360deg) rotateX(360deg);}
`;
const wrapRule = css`
  /* ${t1} 2s 2s linear; */
  ${wrap} 1.5s linear forwards;
`;
const t1Rule = css`
  ${t1} 1.5s ease-in-out forwards;
`;
const t2Rule = css`
  ${t2} 1.5s ease-in-out forwards;
`;
const t3Rule = css`
  ${t3} 1.5s ease-in-out forwards;
`;
const t4Rule = css`
  ${t4} 1.5s ease-in-out forwards;
`;
const t5Rule = css`
  ${t5} 1.5s ease-in-out forwards;
`;
const t6Rule = css`
  ${t6} 1.5s ease-in-out forwards;
`;
const tRule = css`
  ${cubeSpin} 1.5s  linear;
`;

const ContentWrap = styled.div`
  background: black;
  width: 100%;
  height: 722px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CubeWrap = styled.div`
  perspective: 5000px;
  /* margin-top: 10em; */
  animation: ${wrapRule};
`;

const Cube = styled.div`
  position: relative;
  width: 90px;
  height: 90px;
  transform-origin: center center;
  transform-style: preserve-3d;
  ${(props) =>
    props.cubeType === 1 &&
    css`
      animation: ${t1Rule};
    `}
  ${(props) =>
    props.cubeType === 2 &&
    css`
      animation: ${t2Rule};
    `} /* animation: ${t2Rule}; */
    ${(props) =>
    props.cubeType === 3 &&
    css`
      animation: ${t3Rule};
    `} /* animation: ${t2Rule}; */
    ${(props) =>
    props.cubeType === 4 &&
    css`
      animation: ${t4Rule};
    `} /* animation: ${t2Rule}; */
    ${(props) =>
    props.cubeType === 5 &&
    css`
      animation: ${t5Rule};
    `} /* animation: ${t2Rule}; */
    ${(props) =>
    props.cubeType === 6 &&
    css`
      animation: ${t6Rule};
    `} /* animation: ${t2Rule}; */
`;

const Test = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: transparent;
  ${(props) =>
    props.type === 1 &&
    css`
      transform: rotateX(90deg) translateZ(45px);

      background-image: url(${props.src});
      background-position: 0% 0%;
      /* background-color: #e3493b; */
    `}

  ${(props) =>
    props.type === 2 &&
    css`
      transform: translateZ(45px);
      background-image: url(${props.src});
      background-position: 0% 100%;
      /* background-color: #23b5af; */
    `}
    ${(props) =>
    props.type === 3 &&
    css`
      transform: rotateY(90deg) translateZ(45px);
      background-image: url(${props.src});
      background-position: 50% 0%;
      /* background-color: #e8487f; */
    `}
    ${(props) =>
    props.type === 4 &&
    css`
      transform: rotateY(180deg) translateZ(45px);
      background-image: url(${props.src});
      background-position: 50% 100%;
      /* background-color: #28b78d; */
    `}
    ${(props) =>
    props.type === 5 &&
    css`
      transform: rotateY(-90deg) translateZ(45px);
      background-image: url(${props.src});
      /* background-color: #ff5b00; */
      background-position: 100% 0%;
    `}
    ${(props) =>
    props.type === 6 &&
    css`
      transform: rotateX(-90deg) translateZ(45px);
      background-image: url(${props.src});
      background-position: 100% 100%;
      /* background-color: #feeb51; */
    `}
`;

export default LoadingBox;
