import React, { useRef, useState } from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import LoginTabs from "../../components/LoginTabs";
import ModalClose from "../../asset/img/sub/btn_close.png";
import styled from "styled-components";
import {
  BeatLoader,
  ClipLoader,
  MoonLoader,
  PulseLoader,
} from "react-spinners";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const override = {
  display: "block",
  margin: "0 auto",
  // borderColor: "red",
};

const PWD_RULE = /^[0-9]{6}$/;

const ErrorAlert = styled.p`
  font-size: 0.75em;
  color: #e07878;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  height: ${(props) => (props.active ? "auto;" : "0;")};
`;

const Signup = () => {
  const navi = useNavigate();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [account, setAccount] = useState({
    id: "",
    name: "",
    pwd: "",
    cNum: "",
    agree1: false,
    agree2: false,
    confirm: false,
  });
  const [error, setError] = useState({
    id: {
      isError: false,
      msg: "",
    },
    name: {
      isError: false,
      msg: "",
    },
    pwd: {
      isError: false,
      msg: "",
    },
    pwdConfirm: {
      isError: false,
      msg: "비밀번호가 일치하지 않습니다",
    },
  });
  const [snsConfirm, setSnsConfirm] = useState({
    confirmNum: undefined,
    btnDisable: false,
  });
  const idRef = useRef(null);

  const handleInputChange = (e, type) => {
    switch (type) {
      case "id":
        setAccount({
          ...account,
          id: e.target.value.replace("-", ""),
          confirm: false,
        });
        idRef.current.value = e.target.value.replace("-", "");
        setSnsConfirm({ confirmNum: undefined, btnDisable: false });
        break;
      case "name":
        setAccount({ ...account, name: e.target.value });
        setError({
          ...error,
          name: {
            isError: false,
            msg: "",
          },
        });
        break;
      case "pwd":
        setAccount({ ...account, pwd: e.target.value });
        setError({
          ...error,
          pwd: {
            isError: false,
            msg: error.pwd.msg,
          },
        });
        break;
      case "confirm":
        setAccount({ ...account, cNum: e.target.value, confirm: false });
        break;
      default:
        break;
    }
  };

  const handleReqValidNum = async (e) => {
    e.preventDefault();
    if (account.id === "" && account.id.length === 0) {
      alert("휴대전화번호를 입력해주시기 바랍니다.");
      return false;
    }
    const isNewAccount = await axios.get(
      `https://admin.swfestival2022.kr/api/account/checkdupli?phone=${account.id}`,
      {
        validateStatus: function (res) {
          return res < 500;
        },
      }
    );
    const { data } = isNewAccount;
    if (data.success) {
      const snsRes = await axios.post(
        "https://admin.swfestival2022.kr/api/account/sns",
        { phone: account.id },
        {
          validateStatus: function (res) {
            return res < 500;
          },
        }
      );
      const { data: snsData } = snsRes;
      if (snsData.success && snsData.msg !== "") {
        alert("인증번호가 발송되었습니다.");
        console.log(snsData);
        const tempNumber = snsData.msg;
        setSnsConfirm({
          ...snsConfirm,
          confirmNum: tempNumber,
          btnDisable: true,
        });
      }
      // const tempNumber = 111;
      // console.log(account.id, account.confirm);
    } else {
      alert("이미 가입되어 있는 계정입니다.");
      return false;
    }
  };

  const handleValidCNum = () => {
    console.log(account.cNum, snsConfirm.confirmNum);
    if (account.cNum * 1 === snsConfirm.confirmNum * 1) {
      setAccount({
        ...account,
        confirm: true,
      });
      setSnsConfirm({
        confirmNum: undefined,
        btnDisable: true,
      });
      idRef.current.disabled = true;
    } else {
      alert("인증번호가 일치하지 않습니다.");
      setAccount({
        ...account,
        confirm: false,
      });
    }
  };

  const handleComparepwd = (e) => {
    if (account.pwd !== e.target.value) {
      setError({
        ...error,
        pwdConfirm: {
          isError: true,
          msg: error.pwdConfirm.msg,
        },
      });
    } else {
      setError({
        ...error,
        pwdConfirm: {
          isError: false,
          msg: error.pwdConfirm.msg,
        },
      });
    }
  };

  const handleTerms = (e, type) => {
    console.log(e.target.checked);
    if (type === 1) {
      setAccount({ ...account, agree1: e.target.checked });
    } else if (type === 2) {
      setAccount({ ...account, agree2: e.target.checked });
    }
  };

  const handleSubmit = async (e) => {
    if (!PWD_RULE.test(account.pwd)) {
      setError({
        ...error,
        pwd: {
          isError: !PWD_RULE.test(account.pwd),
          msg: "비밀번호는 숫자 6자리로 입력해주시기 바랍니다.",
        },
      });
      return false;
    }

    if (!account.name || account.name === "") {
      setError({
        ...error,
        name: {
          isError: true,
          msg: "이름을 입력해주시기 바랍니다.",
        },
      });
      return false;
    }

    if (!account.agree1 || !account.agree2) {
      alert("필수약관에 동의해주시기 바랍니다.");
      return false;
    }
    if (!account.confirm) {
      alert("휴대전화번호 인증을 해주시기 바랍니다.");
      return false;
    }

    const res = await axios.post(
      "https://admin.swfestival2022.kr/api/account/signup",
      {
        ...account,
      },
      {
        validateStatus: function (res) {
          return res < 500;
        },
      }
    );
    const { data } = res;
    if (data.success) {
      alert("성공적으로 가입되었습니다. 로그인 페이지로 이동합니다.");
      navi("/login");
    } else {
      alert("이미 계정이 존재합니다.");
      return false;
    }
  };

  const disabledFormSubmit = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <div className={"wrap main"}>
      <div className={"container sub"}>
        <BoardSubVisual />
        <LoginTabs current={"signup"} />
        <div className="sub_wrap login">
          <br />
          <div className={"infoArea"}>
            <p>
              * 회원가입 후 <b>'인기상 투표'</b>가 가능합니다.{" "}
              <small>
                <b>
                  (관람안내 {">"} 우수작품 {">"} 투표하기)
                </b>
              </small>
            </p>
            <p>
              * 사전등록은 <b>(관람안내 {">"} 사전등록)</b>에서 가능합니다.
            </p>
          </div>
          <br />
          <span>회원가입</span>
          <form className="login_form" onSubmit={disabledFormSubmit}>
            <ul>
              <li>
                <span>
                  아이디 <b>*</b>
                </span>
                <div className="input_area">
                  <input
                    type="text"
                    name="join_name"
                    className="login_inp phone"
                    placeholder="('-' 을 제외한) 휴대전화번호를 입력해주세요 "
                    ref={idRef}
                    onChange={(e) => handleInputChange(e, "id")}
                  />
                  <button
                    className="termsPopup1"
                    style={{
                      padding: "0.5em",
                      background: `${
                        account.confirm ? "rgb(134 219 138)" : "#8c7eb8"
                      }`,
                    }}
                    onClick={handleReqValidNum}
                    disabled={snsConfirm.btnDisable}
                  >
                    {account.confirm ? (
                      "인증완료"
                    ) : snsConfirm.btnDisable ? (
                      <MoonLoader
                        color={"white"}
                        loading={true}
                        cssOverride={override}
                        speedMultiplier={1}
                        size={20}
                      />
                    ) : (
                      "확인"
                    )}
                  </button>
                </div>
                <ErrorAlert active={error.id.isError}>
                  {error.id.isError && error.id.msg}
                </ErrorAlert>
                {!account.confirm && (
                  <div className="input_area">
                    <input
                      type="text"
                      name="join_name"
                      className="login_inp phone confirmPwd"
                      placeholder="인증번호를 입력해주세요. "
                      onChange={(e) => handleInputChange(e, "confirm")}
                    />
                    <button
                      className="termsPopup1"
                      style={{ padding: "0.5em", background: "#8c7eb8" }}
                      onClick={handleValidCNum}
                    >
                      인증
                    </button>
                  </div>
                )}
              </li>
              <li>
                <span>
                  이름 <b>*</b>
                </span>
                <div className="input_area">
                  <input
                    type="text"
                    name="join_name"
                    className="login_inp"
                    placeholder="성함을 입력해주세요. "
                    onChange={(e) => handleInputChange(e, "name")}
                  />
                </div>
                <ErrorAlert active={error.name.isError}>
                  {error.name.isError && error.name.msg}
                </ErrorAlert>
              </li>
              <li>
                <span>
                  비밀번호 <b>*</b>
                </span>
                <div className="input_area phone">
                  <input
                    type="text"
                    name="join_phone"
                    className="login_inp"
                    placeholder="숫자 6 자리로 입력해주세요."
                    onChange={(e) => handleInputChange(e, "pwd")}
                  />
                </div>
                <ErrorAlert active={error.pwd.isError}>
                  {error.pwd.isError && error.pwd.msg}
                </ErrorAlert>
              </li>
              <li>
                <span>
                  비밀번호 확인 <b>*</b>
                </span>
                <div className="input_area phone">
                  <input
                    type="text"
                    name="join_phone"
                    className="login_inp"
                    placeholder="비밀번호 확인을 위해 다시 입력해주시기 바랍니다."
                    onChange={(e) => handleComparepwd(e)}
                  />
                </div>
                <ErrorAlert active={error.pwdConfirm.isError}>
                  {error.pwdConfirm.isError && error.pwdConfirm.msg}
                </ErrorAlert>
              </li>
            </ul>
            <div className="input_area terms fst">
              <input
                type="checkbox"
                name=""
                id="terms01"
                className="inp-cbx"
                onChange={(e) => handleTerms(e, 1)}
              />
              <label className="cbx" htmlFor="terms01">
                <span>[필수] 회원가입 약관에 동의합니다.</span>
              </label>
              <button
                type="button"
                className="termsPopup1"
                onClick={() => setOpen1(true)}
              >
                내용보기
              </button>
            </div>
            <div className="input_area terms">
              <input
                type="checkbox"
                name=""
                id="terms02"
                className="inp-cbx"
                onChange={(e) => handleTerms(e, 2)}
              />
              <label className="cbx" htmlFor="terms02">
                <span>[필수] 개인정보처리방침에 동의합니다.</span>
              </label>
              <button
                type="button"
                className="termsPopup2"
                onClick={() => setOpen2(true)}
              >
                내용보기
              </button>
            </div>
            <button
              type="button"
              className="login_btn"
              onClick={handleSubmit}
              // onclick="location.href='login.html';"
            >
              등록
            </button>
          </form>
        </div>
      </div>
      {/* <div style={{ height: "100px" }}>
        <p>test</p>
      </div>
      <div>
        <p>id</p>
        <input
          type="text"
          ref={idRef}
          onChange={(e) => handleInputChange(e, "id")}
          defaultValue={account.id}
        />
        <button onClick={handleReqIdConfirm} disabled={snsConfirm.btnDisable}>
          인증번호 받기
        </button>
        <input type="text" onChange={(e) => handleInputChange(e, "confirm")} />
        <button onClick={handleValidCNum}>인증번호 확인</button>
        <p>name</p>
        <input type="text" onChange={(e) => handleInputChange(e, "name")} />
        <p>pwd</p>
        <input type="text" onChange={(e) => handleInputChange(e, "pwd")} />
      </div> */}

      <div
        id="pop_terms1"
        className="popup"
        style={{ display: `${open1 ? "flex" : "none"}` }}
      >
        <div className="modal-bg"></div>
        <div className="modal-cont">
          <h2>회원가입 약관</h2>
          <div className="tableArea popView">
            <br />
            <br />
            &lt;회원가입약관&gt;
            <br />
            <br />
            제 1장 총칙 <br />
            <br />
            제 1조 (목적)
            <br />
            <br />
            이 약관은 "SW인재페스티벌"에서 운영하는 웹서비스의 이용조건 및
            절차에 관한 사항을 명시합니다. <br />
            회원가입에 대한 내용을 충분히 숙지하신 후 가입하여 주시고, 이 약관은
            본 "SW인재페스티벌"에서 운영하는 모든 웹 서비스를 이용하는 모든
            회원에게 적용되며 이를 읽고 이해하는 것은 귀하의 책임입니다.
            <br />
            <br />
            제2조 약관의 공지 및 효력
            <br />
            <br />
            이 약관의 내용은 웹서비스 화면에 게시하거나 기타의 방법으로 회원에게
            공지함으로써 효력을 발생합니다.
            <br />
            "SW인재페스티벌"은 이 약관을 변경할 수 있으며, 변경된 약관은 제1항과
            같은 방법으로 공지함으로써 효력을 발생합니다.
            <br />
            회원가입이란 "SW인재페스티벌 사무국"에서 제공하는 웹서비스에
            가입하는 것으로 웹서비스의 조건과 규정에 합의함을 의미하며,
            "SW인재페스티벌"은 웹서비스의 조건과 조항을 항시 통고와 함께 또는
            통고 없이 전부 또는 일부를 바꿀 권리를 갖습니다. 이런 변화에 대해
            빠른 회신을 못하거나, 고지 받지 못했다는 사유로 이의를 제기할 수
            없으므로 추후에 착오 없으시길 당부 드립니다.
            <br />
            <br />
            제3조 약관 외 준칙
            <br />
            <br />
            이 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법,
            전자거래 기본법, 전자서명법, 통신판매법 및 기타 관계법령의 규정에
            의합니다.
            <br />
            <br />
            제4조 용어의 정의
            <br />
            <br />
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <br />
            회원 : "SW인재페스티벌"의 웹서비스 이용에 관한 계약을 체결한 자
            <br />
            아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고
            "SW인재페스티벌"이 승인하는 문자와 숫자의 조합
            <br />
            비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 숫자의
            조합
            <br />
            탈퇴 : 회원이 서비스 이용 이후 그 이용계약을 종료 시키는 의사표시
            <br />
            휴면 계정 : 최근 1년동안 로그인 하지 않은 이용자로 가입한 회원을
            정의 휴면계정에 해당하는 회원은 개인정보의 보호 및 관리를 위하여
            개인정보 및 학습이력이 삭제될 수 있습니다.
            <br />
            <br />
            제2장 서비스 이용계약
            <br />
            <br />
            제1조 이용 계약의 성립
            <br />
            <br />
            서비스 가입 신청 시 본 약관을 읽고 "동의함" 버튼을 누르면 이 약관에
            동의하는 것으로 간주됩니다.
            <br />
            이용계약은 회원 정보 입력 후 완료를 선택하게 되면 성립합니다.
            <br />
            회원에 가입하여 서비스를 이용하고자 하는 희망자는
            "SW인재페스티벌"에서 요청하는 개인신상정보를 제공해야 합니다.
            <br />
            이용계약은 회원 1인당 1개의 ID로 체결하는 것을 원칙으로 합니다.
            <br />
            <br />
            제2조 이용신청
            <br />
            <br />
            이용신청은 온라인상의 가입신청 양식에 준합니다.
            <br />
            <br />
            제3조 이용신청의 승낙
            <br />
            <br />
            회원이 제2장 제2조에서 정한 모든 사항을 정확히 기재하여 이용신청을
            하였을 때 승낙합니다.
            <br />
            <br />
            제4조 이용신청의 불 승낙
            <br />
            <br />
            웹서비스는 다음에 해당하는 이용신청에 대하여 등록을 거부하거나 등록
            후에라도 회원에게 고지하지 않고 회원정보를 수정 또는 삭제할 수
            있습니다.
            <br />
            다른 사람의 명의를 사용하여 신청하였을 경우
            <br />
            이용 신청 시 필요내용을 허위로 기재하여 신청하였을 경우
            <br />
            사회의 안녕질서 및 미풍양속을 저해할 목적으로 신청하였을 경우
            <br />
            신용정보의 이용과 보호에 관한 법률에 의거 신용불량자로 등록되어 있는
            경우
            <br />
            기타 이용신청자의 귀책사유로 이용승낙이 곤란한 경우
            <br />
            "SW인재페스티벌"은 다음에 해당하는 이용신청에 대하여 승낙 제한
            사유가 해소될 때까지 승낙을 하지 않을 수 있습니다.
            <br />
            "SW인재페스티벌"이 설비의 여유가 없는 경우
            <br />
            "SW인재페스티벌"의 기술상 지장이 있는 경우 <br />
            기타 "SW인재페스티벌"이 필요하다고 인정되는 경우
            <br />
            "SW인재페스티벌"은 사용자의 이용신청을 거절하거나 승낙을 제한하는
            경우 이를 이용신청자에게 알려야 합니다.
            <br />
            <br />
            제5조 계약사항의 변경
            <br />
            <br />
            회원은 이용 신청 시 기재한 사항이 변경되었을 경우에는 온라인 수정을
            해야 합니다.
            <br />
            <br />
            제6조 회원정보의 공유
            <br />
            <br />
            "SW인재페스티벌"이 더 좋은 서비스를 위하여 타 기관과 제휴, 인수,
            분사, 합병 시 회원의 정보는 공유될 수 있습니다.
            <br />
            각종 경품이 제공되는 이벤트의 경우, 경품 협찬사와의 협의에 의해
            당첨자 등록정보를 공유할 수 있습니다.
            <br />
            1항, 2항의 사유가 발생할 경우 "SW인재페스티벌"은 회원에게 해당
            사실을 공지해야 합니다.
            <br />
            <br />
            <br />
            제7조 추가적인 회원정보의 사용
            <br />
            <br />
            "SW인재페스티벌"은 회원이 커뮤니티 서비스 이용 시 제공하는 회원의
            추가 정보를 관리용, 통계용의 정보로만 사용합니다.
            <br />
            <br />
            제8조 정보의 제공
            <br />
            <br />
            "SW인재페스티벌"은 회원이 서비스 이용 및 "SW인재페스티벌"의 각종
            행사 또는 정보서비스에 대해서는 전자우편이나 서신우편 등의 방법으로
            회원들에게 제공할 수 있습니다.
            <br />
            <br />
            제9조 개인정보의 보호
            <br />
            <br />
            "SW인재페스티벌"은 이용자의 개인정보를 수집할 때 반드시 당해
            이용자의 동의를 받습니다.
            <br />
            개인정보를 내부 관리용, 통계용 및 제2장 제6조 이외의 용도로
            이용하거나 이용자의 동의 없는 제3자에게 제공, 분실, 도난, 유출, 변조
            시 그에 따른 이용자의 피해에 대한 모든 책임은 "SW인재페스티벌"이
            집니다.
            <br />
            <br />
            제3장 서비스 이용
            <br />
            <br />
            제1조 서비스 이용
            <br />
            <br />
            서비스 이용은 "SW인재페스티벌"의 업무상 또는 기술상 특별한 지장이
            없는 한 연중무휴, 1일 24시간을 원칙으로 합니다. 단, 정기점검 등
            서비스 개선을 위하여 "SW인재페스티벌"이 필요하다고 인정되는 때에는
            미리 공지한 후 서비스가 일시 중지될 수 있습니다.
            <br />
            <br />
            제2조 서비스 제공의 중지
            <br />
            <br />
            "SW인재페스티벌"은 다음 항에 해당하는 경우 서비스의 제공을 중지할 수
            있습니다.
            <br />
            설비의 보수 등을 위하여 부득이한 경우
            <br />
            전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하는
            경우
            <br />
            기타 귀사가 서비스를 제공할 수 없는 사유가 발생한 경우
            <br />
            <br />
            <br />
            제3조 파일정보의 소거 및 회원 권한의 삭제
            <br />
            <br />
            "SW인재페스티벌"은 서비스용 설비의 용량에 여유가 없다고 판단되면
            필요에 따라 회원의 정보 및 신상정보를 삭제할 수 있습니다.
            <br />
            "SW인재페스티벌"은 서비스 운영상 또는 보안에 문제가 있다고 판단되는
            회원의 정보 및 신상정보를 사전통지 없이 검색할 수 있습니다.
            <br />
            제1항의 경우에 "SW인재페스티벌"은 해당 사항을 사전에 서비스 또는
            전자우편을 통하여 공지합니다.
            <br />
            <br />
            <br />
            제4장 서비스 사용 제한 및 계약 해지
            <br />
            <br />
            제1조 서비스 사용 제한
            <br />
            <br />
            회원은 서비스의 사용에 있어서 다음 각 호에 해당되지 않도록 하여야
            하며, 이에 해당하는 경우 서비스 사용을 제한할 수 있습니다.
            <br />
            다른 회원의 아이디(ID)를 부정 사용하는 행위
            <br />
            범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
            <br />
            선량한 풍속, 기타 사회질서를 해하는 행위
            <br />
            타인의 명예를 훼손하거나 모욕하는 행위
            <br />
            타인의 지적재산권 등의 권리를 침해하는 행위
            <br />
            해킹행위 또는 컴퓨터바이러스의 유포행위
            <br />
            타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로
            전송하는 행위
            <br />
            서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위
            <br />
            기타 관계법령에 위배되는 행위
            <br />
            <br />
            <br />
            제2조 계약 해지
            <br />
            <br />
            회원이 이용계약을 해지하고자 하는 때에는 본인이 서비스 또는
            전자우편을 통하여 해지하고자 하는 날의 1일전까지(단, 해지일이
            법정공휴일인 경우 공휴일 개시 2일전까지) 이를 "SW인재페스티벌"에
            신청하여야 합니다.
            <br />
            "SW인재페스티벌"은 회원이 제4장 제1조의 내용을 위반하고,
            "SW인재페스티벌" 소정의 기간 이내에 이를 해소하지 아니하는 경우
            서비스 이용계약을 해지할 수 있습니다.
            <br />
            "SW인재페스티벌"은 제2항에 의해 해지된 회원이 다시 이용신청을 하는
            경우 일정기간 그 승낙을 제한할 수 있습니다.
            <br />
            <br />
            <br />
            제5장 책임
            <br />
            <br />
            제1조 회원의 의무
            <br />
            <br />
            회원아이디(ID) 및 비밀번호에 관한 모든 관리의 책임은 회원에게
            있습니다.
            <br />
            회원아이디(ID) 및 비밀번호는 "SW인재페스티벌"의 사전승낙 없이는 다른
            사람에게 양도, 임대, 대여할 수 없습니다.
            <br />
            자신의 회원아이디(ID)가 부정하게 사용된 경우, 회원은 반드시
            "SW인재페스티벌"에 그 사실을 통보해야 합니다.
            <br />
            회원은 이용신청서의 기재내용 중 변경된 내용이 있는 경우 서비스를
            통하여 그 내용을 "SW인재페스티벌"에 통지하여야 합니다.
            <br />
            회원은 이 약관 및 관계법령에서 규정한 사항을 준수해야 합니다.
            <br />
            <br />
            <br />
            제2조 "SW인재페스티벌"의 의무
            <br />
            <br />
            "SW인재페스티벌"은 제3장 제1조 및 제2조에서 정한 경우를 제외하고 이
            약관에서 정한 바에 따라 회원이 신청한 서비스 제공 개시일에 서비스를
            이용할 수 있도록 합니다.
            <br />
            "SW인재페스티벌"은 이 약관에서 정한 바에 따라 계속적, 안정적으로
            서비스를 제공할 의무가 있습니다. 단, 부득이한 경우로 회원 개인정보의
            손실이 발생했을 지라도 "SW인재페스티벌"은 전혀 책임지지 않습니다.
            <br />
            "SW인재페스티벌"은 회원의 개인신상정보를 본인의 승낙 없이 타인에게
            누설, 배포하지 않습니다. 단, 전기통신관련법령 등 관계법령에 의해
            국가기관 등의 요구가 있는 경우에는 그러하지 않습니다.
            <br />
            "SW인재페스티벌"은 회원으로부터 제기되는 의견이나 불만이 정당하다고
            인정되는 경우에는 즉시 처리해야 합니다. 다만 즉시 처리가 곤란한
            경우에는 회원에게 그 사유와 처리일정을 통보하여야 합니다.
            <br />
            <br />
            제3조 게시물 또는 내용물의 삭제
            <br />
            <br />
            "SW인재페스티벌"은 서비스의 게시물 또는 내용물이 제4장 제1조의
            규정에 위반되거나 "SW인재페스티벌" 소정의 게시기간을 초과하는 경우
            사전 통지나 동의 없이 이를 삭제할 수 있습니다.
            <br />
            <br />
            제6장 손해배상 및 면책조항
            <br />
            <br />
            제1조 손해배상
            <br />
            <br />
            "SW인재페스티벌"은 서비스 이용과 관련하여 회원에게 어떠한 손해가
            발생하더라도 동 손해가 "SW인재페스티벌"의 중대한 과실에 의한 경우를
            제외하고 이에 대하여 책임을 지지 않습니다.
            <br />
            <br />
            제2조 면책조항
            <br />
            <br />
            "SW인재페스티벌"은 천재지변 또는 이에 준하는 불가항력으로 인하여
            서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
            면제됩니다.
            <br />
            "SW인재페스티벌"은 회원의 귀책사유로 인한 서비스 이용의 장애에
            대하여 책임을 지지 않습니다.
            <br />
            "SW인재페스티벌"은 회원이 서비스를 이용하여 기대하는 손익이나
            서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
            <br />
            "SW인재페스티벌"은 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도,
            정확성 등 내용에 관하여는 책임을 지지 않습니다.
            <br />
            회원 아이디(ID)와 비밀번호의 관리 및 이용상의 부주의로 인하여
            발생되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 모두
            회원에게 있습니다.
            <br />
            회원이 제4장 제1조, 기타 이 약관의 규정을 위반함으로 인하여
            "SW인재페스티벌"이 회원 또는 제3자에 대하여 책임을 부담하게 되고,
            이로써 "SW인재페스티벌"에게 손해가 발생하게 되는 경우, 이 약관을
            위반한 회원은 "SW인재페스티벌"에게 발생하는 모든 손해를 배상하여야
            하며, 동 손해로부터 "SW인재페스티벌"을 면책시켜야 합니다.
            <br />
            <br />
            제7장 기타
            <br />
            <br />
            제1조 약관의 변동
            <br />
            <br />
            이용약관 개정 시 최소 7일 이전 고지의 의무가 있고 소비자에게 불리한
            경우 30일 이전 고지해야 합니다.
            <br />
            <br />
            제2조 분쟁의 해결
            <br />
            <br />
            "SW인재페스티벌"과 회원은 서비스와 관련하여 발생한 분쟁을 원만하게
            해결하기 위하여 필요한 모든 노력을 하여야 합니다.
            <br />
            제1항의 규정에도 불구하고, 동 분쟁으로 인하여 소송이 제기될 경우
            민사소송법상의 관할법원을 전속적 관할 법원으로 합니다.
            <br />
            <br />
            <br />
          </div>
          <a className="close close1" onClick={() => setOpen1(false)}>
            <img src={ModalClose} alt="닫기" />
          </a>
        </div>
      </div>

      <div
        id="pop_terms2"
        className="popup"
        style={{ display: `${open2 ? "flex" : "none"}` }}
      >
        <div className="modal-bg"></div>
        <div className="modal-cont">
          <h2>개인정보처리방침 안내</h2>
          <div className="tableArea popView">
            <br />
            <br />
            &lt;개인정보취급방침안내&gt; <br />
            <br />
            개인정보처리방침)
            <br />
            <br />
            "SW인재페스티벌"이 취급하는 모든 개인정보는 개인정보 보호법 및
            "SW인재페스티벌" 개인정보처리방침 등 관련 법령상의 개인정보 보호
            규정을 준수하여 수집·보유·처리되고 있습니다.
            <br />
            <br />
            "SW인재페스티벌"은 개인정보 보호법에 따라 이용자의 개인정보 보호 및
            권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수
            있도록 다음과 같은 처리방침을 두고 있습니다. "SW인재페스티벌"은
            개인정보 처리방침을 변경하는 경우에는 시행의 시기, 변경된 내용을
            정보주체가 쉽게 확인할 수 있도록 변경 전·후를 비교하여 공개하도록 할
            예정입니다.
            <br />
            <br />
            개인정보의 처리목적, 개인정보의 처리 및 보유기간, 처리하는
            개인정보의 항목)
            <br />
            "SW인재페스티벌" 대표 홈페이지(https://www.swhrfestival.kr)는 직접
            개인정보를 수집하지 않으며, 개인정보 보호법 제32조에 따라 등록,
            공개하는 개인정보파일은 아래에서 확인하실 수 있습니다.
            <br />
            개인정보보호 종합지원포털(www.privacy.go.kr)
            <br />
            개인정보민원
            <br />
            개인정보의 열람 등 요구 <br />
            개인정보파일 목록 검색
            <br />
            기관명에 "SW인재페스티벌" 입력 후 조회
            <br />
            <br />
            개인정보의 제3자 제공)
            <br />
            "SW인재페스티벌"은 원칙적으로 정보주체의 개인정보를 수집·이용
            목적으로 명시한 범위 내에서 처리하며, 아래의 경우를 제외하고는
            정보주체의 사전 동의 없이는 본래의 목적 범위를 초과하여 처리하거나
            제3자에게 제공하지 않습니다.
            <br />
            정보주체로부터 별도의 동의를 받는 경우
            <br />
            법률에 특별한 규정이 있는 경우
            <br />
            정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나
            주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체
            또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고
            인정되는 경우
            <br />
            통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을
            알아 볼 수 없는 형태로 개인정보를 제공하는 경우
            <br />
            개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지
            아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서
            보호위원회의 심의·의결을 거친 경우 조약, 그 밖의 국제협정의 이행을
            위하여 외국정보 또는 국제기구에 제공하기 위하여 필요한 경우
            <br />
            범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
            <br />
            법원의 재판업무 수행을 위하여 필요한 경우
            <br />
            형 및 감호, 보호처분의 집행을 위하여 필요한 경우
            <br />
            <br />
            개인정보처리 위탁)
            <br />
            "SW인재페스티벌"에서 관리하는 개인정보의 처리를 다른 공공기관 또는
            다른 전문기관에 위탁하는 경우, 그 업무에 종사하거나 종사하였던 자에
            대해 직무상 알게 된 개인정보를 누설 또는 권한 없이 처리하거나 타인의
            이용에 제공하는 등 부당한 목적을 위하여 사용하지 않도록 위탁부서에서
            아래에 대한 사항에 관하여 필요한 제한이나 절차를 정하고 수탁기관으로
            하여금 준수하도록 하고 있으며, 실태점검도 실시하고 있습니다.
            <br />
            재 위탁 금지에 관한 사항
            <br />
            개인정보파일의 복사에 관한 사항
            <br />
            개인정보의 관리상황 검사 및 소속직원의 교육에 관한 사항
            <br />
            수탁기관에서 준수하여야 할 의무를 위반한 경우의 손해배상 등에 관한
            사항
            <br />
            <br />
            정보주체의 권리·의무 및 그 행사 방법)
            <br />
            정보주체는 아래와 같은 권리를 행사 할 수 있으며, 만14세 미만 아동의
            법정대리인은 그 아동의 개인정보에 대한 열람, 정정·삭제, 처리정지를
            요구할 수 있습니다.
            <br />
            <br />
            개인정보 열람 요구)
            <br />
            "SW인재페스티벌"에서 보유하고 있는 개인정보파일은 「개인정보
            보호법」 제35조(개인정보의 열람)에 따라 자신의 개인정보에 대한
            열람을 요구할 수 있습니다. 다만, 개인정보 열람 요구는 법 제35조
            5항에 의하여 아래와 같이 제한될 수 있습니다.
            <br />
            법률에 따라 열람이 금지되거나 제한되는 경우
            <br />
            다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그
            밖의 이익을 부당하게 침해할 우려가 있는 경우
            <br />
            공공기관이 아래 각 목의 어느 하나에 해당하는 업무를 수행할 때 중대한
            지장을 초래하는 경우
            <br />
            조세의 부과·징수 또는 환급에 관한 업무
            <br />
            학력·기능 및 채용에 관한 시험, 자격 심사에 관한 업무
            <br />
            보상금·급부금 산정 등에 대하여 진행 중인 평가 또는 판단에 관한 업무
            <br />
            다른 법률에 따라 진행 중인 감사 및 조사에 관한 업무
            <br />
            <br />
            개인정보 정정·삭제 요구)
            <br />
            "SW인재페스티벌"에서 보유하고 있는 개인정보파일은 「개인정보
            보호법」 제36조(개인정보의 정정·삭제)에 따라 정정·삭제를 요구할 수
            있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어
            있는 경우에는 그 삭제를 요구할 수 없습니다.
            <br />
            <br />
            개인정보 처리정지 요구)
            <br />
            "SW인재페스티벌"에서 보유하고 있는 개인정보파일은 「개인정보보호법」
            제37조(개인정보의 처리정지 등)에 따라 처리정지를 요구할 수 있습니다.
            다만, 개인정보 처리정지 요구는 법 제37조 2항에 의하여 거절될 수
            있습니다.
            <br />
            법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한
            경우
            <br />
            다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그
            밖의 이익을 부당하게 침해할 우려가 있는 경우 <br />
            공공기관이 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관
            업무를 수행할 수 없는 경우
            <br />
            개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지
            못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지
            의사를 명확하게 밝히지 아니한 경우
            <br />
            <br />
            개인정보의 파기)
            <br />
            "SW인재페스티벌"은 원칙적으로 개인정보 보존기간이 경과되거나,
            개인정보 처리목적이 달성된 경우에는 지체 없이 파기합니다. 파기의
            절차, 기한 및 방법은 아래와 같습니다.
            <br />
            <br />
            파기 절차)
            <br />
            개인정보는 목적 달성 후 즉시 또는 별도의 공간에 옮겨져 내부 방침 및
            기타 관련법령에 따라 일정기간 저장된 후 파기됩니다. 별도의 공간으로
            옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로
            이용되지 않습니다.
            <br />
            <br />
            파기 기한 및 파기 방법)
            <br />
            보유기간이 만료되었거나 개인정보의 처리목적달성, 해당 업무의 폐지 등
            그 개인정보가 불필요하게 되었을 때에는 지체 없이 파기합니다. 전자적
            파일형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
            종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
            파기합니다.
            <br />
            <br />
            개인정보의 안전성 확보 조치)
            <br />
            "SW인재페스티벌"은 개인정보 보호법 제29조에 따라 다음과 같이 안전성
            확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.
            <br />
            <br />
            개인정보 처리직원의 최소화 및 교육)
            <br />
            개인정보를 처리하는 직원은 반드시 필요한 인원에 한하여 지정 ·
            관리하고 있으며 처리직원을 대상으로 안전한 관리를 위한 교육을
            실시하고 있습니다.
            <br />
            <br />
            개인정보에 대한 접근 제한)
            <br />
            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
            부여·변경·말소를 통하여 개인정보에 대한 접근통제를 위한 필요한
            조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
            접근을 통제하고 있습니다.
            <br />
            <br />
            접속기록의 보관)
            <br />
            개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 6개월
            이상 보관·관리하고 있습니다.
            <br />
            <br />
            개인정보의 암호화)
            <br />
            개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다. 또한
            중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도
            보안기능을 사용하고 있습니다.
            <br />
            <br />
            보안프로그램 설치 및 주기적 점검·갱신)
            <br />
            해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
            위하여 보안프로그램을 설치하고 주기적으로 갱신·점검하고 있습니다.
            <br />
            <br />
            비인가자에 대한 출입 통제)
            <br />
            개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로
            두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
            <br />
            <br />
            정기적인 자체 점검 실시)
            <br />
            개인정보 처리 관련 안정성 확보를 위해 정기적으로 소속·산하기관을
            포함하여 개인정보 보호관리 점검을 실시하고 있습니다.
            <br />
            <br />
            내부관리계획의 수립 및 시행)
            <br />
            개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
            있습니다.
            <br />
            <br />
            개인정보 보호책임자 및 담당자)
            <br />
            "SW인재페스티벌"은 개인정보를 보호하고 개인정보와 관련한 불만을
            처리하기 위하여 아래와 같이 개인정보 보호책임자 및 담당자를 지정하고
            있습니다.
            <br />
            <br />
            개인정보 보호책임관: (주) 전자신문 홍선표 차장
            <br />
            개인정보보호 담당자: (주) 전자신문 홍선표 차장
            <br />
            연락처: 02-2168-9333
            <br />
            이메일: sekmaster@etnews.com <br />
            <br />
            권익침해 구제방법)
            <br />
            개인정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보
            분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에
            분쟁해결이나 상담 등을 신청할 수 있습니다.
            <br />
            개인정보 분쟁조정위원회: (국번없이) 118 (privacy.kisa.or.kr)
            <br />
            개인정보 침해신고센터: (국번없이) 118 (privacy.kisa.or.kr)
            <br />
            대검찰청 사이버범죄수사단: 02-3480-3571 (cybercid@spo.go.kr)
            <br />
            경찰청 사이버테러대응센터: 1566-0112 (www.netan.go.kr)
            <br />
            또한, 개인정보의 열람, 정정·삭제, 처리정지 등에 대한 정보주체자의
            요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리
            또는 이익을 침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
            청구할 수 있습니다.
            <br />
            행정심판에 대한 자세한 사항은 국민권익위원회(www.acrc.go.kr)
            홈페이지를 참고하시기 바랍니다.
            <br />
            <br />
            <br />
          </div>
          <a className="close close1" onClick={() => setOpen2(false)}>
            <img src={ModalClose} alt="닫기" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Signup;
