import React, { useRef } from "react";
import { Link } from "react-router-dom";
// import { CSSTransition } from "react-transition-group";
import AnimateHeight from "react-animate-height";

const HeaderAnimate = ({
  reset,
  mainTitle,
  subInfo,
  curMenu,
  type,
  toggle,
  setReset,
  isLogin,
  refreshAnimation,
}) => {
  const aRef = useRef(null);

  const handleSetCurMenu = () => {
    if (type === curMenu) {
      setReset(true);
      toggle("main");
    } else {
      setReset(false);
      toggle(type);
    }
  };
  const handleLink = () => {
    setReset(true);
    toggle("main");
    refreshAnimation();
  };

  const toggleSetCurMenu = () => {
    setReset(false);
    toggle(type);
  };

  return (
    <>
      <Link
        ref={aRef}
        onClick={handleSetCurMenu}
        onMouseOver={toggleSetCurMenu}
        to={subInfo[0].link}
      >
        <span>{mainTitle}</span>
      </Link>
      {subInfo.length > 0 && (
        <ul
          className={"submenu" + (!reset && curMenu === type ? " active" : "")}
          style={{
            display: `block`,
          }}
        >
          {subInfo.map((item, index) => {
            if (item.reqLogin) {
              return (
                isLogin && (
                  <AnimateHeight
                    duration={500}
                    height={!reset && curMenu === type ? "auto" : 0}
                    key={`s_${item.type}_${index}`}
                  >
                    <li>
                      <Link to={item.link} onClick={handleLink}>
                        {item.name}
                      </Link>
                    </li>
                  </AnimateHeight>
                )
              );
            } else {
              return (
                <AnimateHeight
                  duration={500}
                  height={!reset && curMenu === type ? "auto" : 0}
                  key={`s_${item.type}_${index}`}
                >
                  <li>
                    <Link to={item.link} onClick={handleLink}>
                      {item.name}
                    </Link>
                  </li>
                </AnimateHeight>
              );
            }
          })}
        </ul>
      )}
    </>
  );
};

export default HeaderAnimate;
