import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const PageStyle = styled.a`
  margin: 0 0.5em;
  width: 2em;
  line-height: 2em;
  color: #969696;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  ${(props) =>
    props.current &&
    css`
      background-color: #707070;
      color: white;
    `}
`;
const PageElement = styled.button`
  ${(props) =>
    props.current &&
    css`
      font-weight: 800;
    `}
`;

export const NoticePaging = ({ totalPages, curPage, dataScale, setPage }) => {
  const [totalBlock, setTotalBlock] = useState(1);
  const [curBlock, setCurBlock] = useState(1);
  //   const [nextBlockPage, setNextBlockPage] = useState(1);
  //   const [prevBlockPage, setPrevBlockPage] = useState(1);

  useEffect(() => {
    setTotalBlock(Math.ceil(totalPages / dataScale));
    setCurBlock(Math.ceil(curPage / dataScale));

    // setNextBlockPage(Math.ceil(curPage / dataScale))
  }, [totalPages, curPage]);

  const calcNextBlockPage = () => {
    const calcPage = Math.ceil(curPage / dataScale) * dataScale + 1;
    if (calcPage <= totalPages) {
      return (
        <PageStyle>
          <i className="fa fa-angle-right" onClick={() => setPage(calcPage)} />
        </PageStyle>
      );
    }
  };

  const calcPrevBlockPage = () => {
    const calcPage = (Math.ceil(curPage / dataScale) - 2) * dataScale + 1;
    if (calcPage > 0) {
      return (
        <PageStyle>
          <i className="fa fa-angle-left" onClick={() => setPage(calcPage)}></i>
        </PageStyle>
      );
    }
  };

  return (
    <>
      <PageStyle>
        <i className={"fa fa-angle-double-left"} onClick={() => setPage(1)} />
      </PageStyle>
      {calcPrevBlockPage()}
      {Array.from(new Array(dataScale), (el, index) => {
        const pageText = index + 1 + (curBlock - 1) * dataScale;
        if (pageText <= totalPages) {
          return (
            <PageStyle
              key={`page_${index + 1}`}
              current={curPage === pageText}
              onClick={() => setPage(pageText)}
            >
              {pageText}
              {/* <PageElement
                current={curPage === pageText}
                onClick={() => setPage(pageText)}
              >
                
              </PageElement> */}
            </PageStyle>
          );
        }
      })}
      {calcNextBlockPage()}
      <PageStyle>
        <i
          className="fa fa-angle-double-right"
          onClick={() => setPage(totalPages)}
        />
      </PageStyle>
    </>
  );
};
