import React, { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import BoardSubVisual from "../../components/BoardSubVisual";
import { FlipLoadingSpin } from "../../components/FlipLoadingSpin";
import PdfViewDeskTopModule from "../../components/PdfViewDeskTopModule";
import PdfViewMobile from "../../components/PdfViewMobile";
import ProgramTabs from "../../components/ProgramTags";
import Test02Pdf from "../../asset/pdf/test02.pdf";
import { accountSelect } from "../../recoil/AtomConfig";
import { UnivList_v2 } from "../../utils/LoadUnivImg";
import { VoteInfo } from "../../components/program/VoteInfo";
import PdfViewUnivInfo from "../../components/PdfViewUnivInfo";
import PdfViewUnivInfoM from "../../components/PdfViewUnivInfoM";
import axios from "axios";
import { VoteOpen } from "../../utils/DateLimit";
import TotalEbook from "../../components/TotalEbook";
import prevList from "../../asset/img/main/prevList2.png";
import EPrevLIst from "../../components/EPrevList";

const UNIV_LIST = UnivList_v2;

const Ework = () => {
  const isVoteOpen = VoteOpen();
  const [uList, setUList] = useState([
    ...UnivList_v2.sort(() => Math.random() - 0.5),
  ]);
  const [state, setState] = useRecoilState(accountSelect);
  const [open, setOpen] = useState(false);
  const [iOpen, setIOpen] = useState(false);
  const [isMOpen, setIsMOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    id: undefined,
    url: null,
  });
  const [iModalInfo, setIModalInfo] = useState({
    id: undefined,
    url: null,
  });
  const [loading, setLoading] = useState(false);

  const typeRef = useRef(null);
  const keyRef = useRef(null);

  const togglePdfView = (name, url) => {
    setModalInfo({
      name: name,
      url: url,
    });
    // setUrl(url);
    setOpen(true);
  };
  const toggleIPdfView = (name, url) => {
    setIModalInfo({
      name: name,
      url: url,
    });
    setIOpen(true);
  };

  const toggleModalClose = () => {
    setOpen(false);
    setModalInfo({
      name: undefined,
      url: undefined,
    });
  };

  const toggleIModalClose = () => {
    setIOpen(false);
    setIModalInfo({
      name: undefined,
      url: undefined,
    });
  };
  console.log(state);

  const handleVote = async (name) => {
    const { id } = state;
    if (id) {
      const res = await axios.post(
        "https://admin.swfestival2022.kr/api/vote",

        { userId: id, univName: name },
        {
          headers: {
            accessToken: state.accessToken,
          },
          validateStatus: function (status) {
            return status < 500;
          },
        }
      );
      console.log(res);
      console.log("vote id is : ", name);
      if (res.status === 200 && res.data.success === true) {
        alert("성공적으로 투표되었습니다.");
        setState((prev) => {
          const pObject = { ...prev };
          pObject.voteList = [...prev.voteList, { univName: name }];
          return { ...pObject };
        });
        return false;
      } else if (res.status === 200 && res.data.success === false) {
        alert(res.data.msg);
      }
    } else {
      alert("로그인 후 이용해주시기 바랍니다.");
    }
  };

  const handleVoteDel = async (name) => {
    const { id } = state;
    if (id) {
      const res = await axios.delete(
        "https://admin.swfestival2022.kr/api/vote",
        {
          data: { userId: id, univName: name },
          headers: {
            accessToken: state.accessToken,
          },
          validateStatus: function (status) {
            return status < 500;
          },
        }
      );
      console.log(res);
      console.log("vote id is : ", name);
      if (res.status === 200 && res.data.success === true) {
        alert("성공적으로 삭제되었습니다.");
        setState((prev) => {
          const pObject = { ...prev };
          pObject.voteList = [
            ...prev.voteList.filter((f) => f.univName !== name),
          ];
          return { ...pObject };
        });
        return false;
      } else if (res.status === 200 && res.data.success === false) {
        alert(res.data.msg);
      }
    } else {
      alert("로그인 후 이용해주시기 바랍니다");
    }
  };

  const handleSearchFilter = () => {
    console.log("click");
    const type = typeRef.current.value;
    const keyword = keyRef.current.value;

    switch (type) {
      case "uName":
        setUList([...UNIV_LIST.filter((f) => f.name.indexOf(keyword) !== -1)]);
        break;
      case "tName":
        setUList([...UNIV_LIST.filter((f) => f.team.indexOf(keyword) !== -1)]);
        break;
      case "sName":
        setUList({
          ...UNIV_LIST.filter((f) => f.subTitle.indexOf(keyword) !== -1),
        });
        break;
      default:
        setUList([...UNIV_LIST]);
        break;
    }
  };

  const handleFilterKo = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setUList([...UNIV_LIST.sort((a, b) => (a.name > b.name ? 1 : -1))]);
    } else {
      setUList([...UNIV_LIST.sort(() => Math.random() - 0.5)]);
    }
  };

  const handlePopPrevList = () => {
    setIsMOpen(true);
  };

  return (
    <div className={"wrap main"}>
      <div className="wrap exhibition">
        <div className="container sub">
          <BoardSubVisual />
          <ProgramTabs current={"ework"} />
          <div className="sub_wrap ew_list work">
            <VoteInfo />
            <div className="search_area">
              <div className="input_area">
                <select className="inp_search" ref={typeRef}>
                  <option value="uName">대학명</option>
                  <option value="tName">팀명</option>
                  <option value="sName">작품명</option>
                </select>
                <input
                  type="text"
                  name="inp_search"
                  className="inp_search"
                  placeholder="검색어 입력"
                  ref={keyRef}
                />
                <button
                  type="button"
                  name="btn_search"
                  className="btn_search"
                  onClick={handleSearchFilter}
                >
                  검색
                </button>
              </div>
              {/* <button type="button" onClick={() => setIsMOpen(true)}>
                open
              </button> */}
            </div>

            <div
              className="tbl_top"
              style={{ border: "none", paddingBottom: "0" }}
            ></div>
            <div className="tbl_top" style={{ justifyContent: "flex-start" }}>
              <div className="sorting_area">
                <div className="input_area">
                  <input
                    type="checkbox"
                    name="sortingBtn"
                    id="abc"
                    className="inp_sorting"
                    onClick={(e) => handleFilterKo(e)}
                  />
                  <label htmlFor="abc">
                    <span className="sorting_box"></span>
                    <p>가나다 순으로 보기</p>
                  </label>
                </div>
              </div>
              <div className="sorting_area">
                <a
                  className="openBlank"
                  target={"_blank"}
                  href={"http://bypub.kr/ebook/msit2022/index.html"}
                >
                  <div className="input_area">
                    <label htmlFor="abc3">
                      <p>우수작품 전체보기</p>
                    </label>
                  </div>
                </a>

                <div className="input_area">
                  <label htmlFor="abc2" onClick={() => handlePopPrevList()}>
                    <p>우수작품 리스트 보기</p>
                  </label>
                </div>
              </div>
              {/* <a href="" className="pdf_download"
								>41개 우수작품 리스트 보기 (PDF)</a
							> */}
              <p
                style={{
                  display: "block",
                  width: "100%",
                  fontSize: "0.8em",
                  marginTop: "1em",
                }}
              >
                ※ 공정한 투표를 위해, 작품순서 랜덤 노출
              </p>
            </div>

            <ul>
              {uList.map((item, index) => (
                <li key={`ework_${index}`}>
                  <div className="bg_list" data-bg={`${item.dataBg}`}></div>
                  <div className="text_area">
                    <p className="year">
                      {item.year === 2019 || item.year === 2018
                        ? item.year +
                          " " +
                          (item.subOrdered === 0 ? "상" : "하")
                        : item.year}{" "}
                    </p>
                    <div className="univ_area">
                      <img src={item.logo} />
                      <div className="univ_text">
                        <span className="univ_name">{item.name}</span>
                        {/* <p className="area">(서울)</p> */}
                      </div>
                    </div>
                    <div className="work_area">
                      <div>
                        <p>팀&nbsp;&nbsp;&nbsp;명</p>
                        <span className="team">{item.team}</span>
                      </div>
                      <div>
                        <p>작품명</p>
                        <span className="work">{item.title}</span>
                      </div>
                    </div>
                    <div className="btn_area">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => togglePdfView(item.name, item.pdf)}
                      >
                        우수작품
                      </button>
                      <button
                        type="button"
                        className="btn"
                        onClick={() => toggleIPdfView(item.name, item.iview)}
                      >
                        대학소개
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {open && (
        <ModelWrap className="modal">
          <ModalBody className="modalBody">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                padding: "1em",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "1em", fontWeight: "bold" }}>
                  2022 SW인재페스티벌 우수작품
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: `${
                      state.id && state.accessToken
                        ? "space-between"
                        : "flex-end"
                    }`,
                    alignItems: "center",
                    width: "15%",
                  }}
                >
                  {isVoteOpen ? (
                    state.id &&
                    state.accessToken &&
                    state.voteList.filter((f) => f.univName === modalInfo.name)
                      .length > 0 ? (
                      <button
                        onClick={() => handleVoteDel(modalInfo.name)}
                        style={{
                          backgroundColor: "#04bad2",
                          border: "none",
                          color: "#fff",
                          borderRadius: "1em",
                          cursor: "pointer",
                        }}
                      >
                        투표취소
                      </button>
                    ) : uList.filter(
                        (f) => f.name === modalInfo.name && !f.noVote
                      ).length > 0 ? (
                      <button
                        onClick={() => handleVote(modalInfo.name)}
                        style={{
                          backgroundColor: "#04bad2",
                          border: "none",
                          color: "#fff",
                          borderRadius: "1em",
                          cursor: "pointer",
                        }}
                      >
                        투표하기
                      </button>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      color: "white",
                      cursor: "pointer",
                      fontSize: "1em",
                    }}
                    onClick={toggleModalClose}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
            <div>
              {!isMobile && loading && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "1000",
                  }}
                >
                  <FlipLoadingSpin text={"2022 SW 인재페스티벌"} />
                </div>
              )}

              {isMobile ? (
                <PdfViewMobile url={modalInfo.url} />
              ) : (
                <PdfViewDeskTopModule
                  url={modalInfo.url}
                  setLoad={setLoading}
                />
              )}
            </div>
          </ModalBody>
        </ModelWrap>
      )}
      {isMOpen && (
        <ModelWrap className={"modal"}>
          <ModalBody className={"modalBody"}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                padding: "1em",
                background: "black",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                ></span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: `${
                      state.id && state.accessToken
                        ? "space-between"
                        : "flex-end"
                    }`,
                    alignItems: "center",
                    width: "15%",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsMOpen(false)}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
            <EPrevLIst />
          </ModalBody>
        </ModelWrap>
      )}
      {iOpen && (
        <ModelWrap className={"modal"}>
          <ModalBody className={"modalBody"}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                padding: "1em",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "1em", fontWeight: "bold" }}>
                  2022 SW인재페스티벌 대학소개
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: `${
                      state.id && state.accessToken
                        ? "space-between"
                        : "flex-end"
                    }`,
                    alignItems: "center",
                    width: "15%",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={toggleIModalClose}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
            <div>
              {isMobile ? (
                <PdfViewUnivInfoM url={iModalInfo.url} />
              ) : (
                <PdfViewUnivInfo url={iModalInfo.url} />
              )}
            </div>
          </ModalBody>
        </ModelWrap>
      )}
    </div>
  );
};

const ModelWrap = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
`;

export default Ework;
