import React from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import UnivTabs from "../../components/UnivTabs";
import Map from "../../asset/img/sub/img_univMap.png";
import { isMobile } from "react-device-detect";

const UnivCurrent = () => {
  return (
    <div className="wrap main">
      <div className={"wrap main"}>
        <div className="container sub">
          <BoardSubVisual />
          <UnivTabs current="current" />
          <div className="sub_wrap introduce univ">
            <div className="univ_current">
              <span className="map_tit" style={{ marginBottom: "1em" }}>
                지역별 선정현황
                <small style={{ display: "unset", marginLeft: "0.4em" }}>
                  (44개교)
                </small>
              </span>
              <p
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "60em",
                  margin: "0 auto 0.5em",
                  paddingRight: "0.75em",
                }}
              >
                <small>*사업기간 종료된 대학 : 회색 표기</small>
              </p>

              <div className="map_area">
                <img className="map" src={Map} alt="univMap" />
              </div>
            </div>
            <div className="univ_tableArea">
              <span className="map_tit">
                연도별 선정현황
                <small>44개교 ('22년도)</small>
              </span>
              {!isMobile ? (
                <>
                  <table className="tableHorizontal">
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "25%" }} />
                    </colgroup>
                    <tbody>
                      <tr className="thead">
                        <td>
                          <span>
                            <strong>선정연도</strong>
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            <strong>NO</strong>
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            <strong>대학명</strong>
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            <strong>총괄책임교수&nbsp;</strong>
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            <strong>비고</strong>
                            <br />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan="6">
                          <p>
                            <span>2017년</span>
                          </p>
                          <p>
                            <span>(6개)</span>
                          </p>
                        </td>
                        <td>
                          <span>1</span>
                        </td>
                        <td>
                          <span>경희대</span>
                        </td>
                        <td>
                          <span>조진성</span>
                        </td>
                        <td>
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>2</span>
                        </td>
                        <td>
                          <span>광운대</span>
                        </td>

                        <td>
                          <span>이혁준</span>
                        </td>
                        <td>
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>3</span>
                        </td>
                        <td>
                          <span>
                            단국대
                            <br />
                          </span>
                        </td>

                        <td>
                          <span>
                            나연묵
                            <br />
                          </span>
                        </td>
                        <td>협의회장</td>
                      </tr>
                      <tr>
                        <td>
                          <span>4</span>
                        </td>
                        <td>
                          <span>
                            조선대
                            <br />
                          </span>
                        </td>

                        <td>
                          <span>
                            정일용
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>5</span>
                        </td>
                        <td>
                          <span>
                            중앙대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            박재현
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>6</span>
                        </td>
                        <td>
                          <span>
                            한동대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            이 강<br />
                          </span>
                        </td>
                        <td>교육분과장</td>
                      </tr>
                      <tr>
                        <td rowSpan="5">
                          <p>
                            <span>2018년 상반기</span>
                          </p>
                          <p>
                            <span>(5개)</span>
                          </p>
                        </td>
                        <td>
                          <span>7</span>
                        </td>
                        <td>
                          <span>
                            강원대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            임현승
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>8</span>
                        </td>
                        <td>
                          <span>
                            건국대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            조용범
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>9</span>
                        </td>
                        <td>
                          <span>
                            숭실대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            신용태
                            <br />
                          </span>
                        </td>
                        <td>산학협력분과장</td>
                      </tr>
                      <tr>
                        <td>
                          <span>10</span>
                        </td>
                        <td>
                          <span>
                            한림대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            이선우
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>11</span>
                        </td>
                        <td>
                          <span>
                            한양대 ERICA
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            이동호
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td rowSpan="5">
                          <p>
                            <span>2018년 하반기</span>
                          </p>
                          <p>
                            <span>(5개)</span>
                          </p>
                        </td>
                        <td>
                          <span>12</span>
                        </td>
                        <td>
                          <span>
                            동명대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            조미경
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>13</span>
                        </td>
                        <td>
                          <span>
                            선문대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            이 현<br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>14</span>
                        </td>
                        <td>
                          <span>
                            우송대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            김 원<br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>15</span>
                        </td>
                        <td>
                          <span>
                            원광대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            정성태
                            <br />
                          </span>
                        </td>
                        <td>해외교류분과장</td>
                      </tr>
                      <tr>
                        <td>
                          <span>16</span>
                        </td>
                        <td>
                          <span>
                            제주대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            송왕철
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td rowSpan="5">
                          <span>
                            2019년<span>&nbsp;상반기</span>
                            <br />
                            (5개)
                          </span>
                        </td>
                        <td>
                          <span>17</span>
                        </td>
                        <td>
                          <span>대구 가톨릭대</span>
                        </td>
                        <td>
                          <span>
                            김미혜
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>18</span>
                        </td>
                        <td>
                          <span>안동대</span>
                        </td>
                        <td>
                          <span>김병순</span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>19</span>
                        </td>
                        <td>
                          <span>연세대 미래</span>
                        </td>
                        <td>
                          <span>윤상균</span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>20</span>
                        </td>
                        <td>
                          <span>이화여대</span>
                        </td>
                        <td>
                          <span>신경식</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>21</span>
                        </td>
                        <td>
                          <span>충북대</span>
                        </td>
                        <td>
                          <span>
                            이건명
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td rowSpan="5">
                          <span>
                            2019년<span>&nbsp;하반기</span> <br />
                            (5개)
                          </span>
                        </td>
                        <td>
                          <span>22</span>
                        </td>
                        <td>
                          <span>동서대</span>
                        </td>
                        <td>
                          <span>
                            문미경
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>23</span>
                        </td>
                        <td>
                          <span>배재대</span>
                        </td>
                        <td>
                          <span>정회경</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>24</span>
                        </td>
                        <td>
                          <span>상명대</span>
                        </td>
                        <td>
                          <span>장준호</span>
                        </td>
                        <td>
                          가치확산분과장
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>25</span>
                        </td>
                        <td>
                          <span>한국외대</span>
                        </td>
                        <td>
                          <span>김낙현</span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>26</span>
                        </td>
                        <td>
                          <span>호서대</span>
                        </td>
                        <td>
                          <span>
                            선복근
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td rowSpan="9">
                          <p>
                            <span>2021년</span>
                          </p>
                          <p>
                            <span>(9개)</span>
                          </p>
                        </td>
                        <td>
                          <span>27</span>
                        </td>
                        <td>
                          <span>가천대</span>
                        </td>
                        <td>
                          <span>김 원</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>28</span>
                        </td>
                        <td>
                          <span>경기대</span>
                        </td>
                        <td>
                          <span>권기현</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>29</span>
                        </td>
                        <td>
                          <span>경북대</span>
                        </td>
                        <td>
                          <span>고석주</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>30</span>
                        </td>
                        <td>
                          <span>성균관대</span>
                        </td>
                        <td>
                          <span>이은석</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>31</span>
                        </td>
                        <td>
                          <span>순천향대</span>
                        </td>
                        <td>
                          <span>전창완</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>32</span>
                        </td>
                        <td>
                          <span>전남대</span>
                        </td>
                        <td>
                          <span>김경백</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>33</span>
                        </td>
                        <td>
                          <span>충남대</span>
                        </td>
                        <td>
                          <span>김형식</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>34</span>
                        </td>
                        <td>
                          <span>삼육대*</span>
                        </td>
                        <td>
                          <div>
                            <span>오덕신</span>
                          </div>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>35</span>
                        </td>
                        <td>
                          <span>한국항공대*</span>
                        </td>
                        <td>
                          <div>
                            <span>최영식</span>
                          </div>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="new">
                    <colgroup>
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "25%" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td rowSpan="9">
                          <div>
                            <span>2022년&nbsp;</span>
                          </div>
                          <div>
                            <span>(9개)</span>
                          </div>
                        </td>
                        <td>
                          <span>36</span>
                        </td>
                        <td>
                          <span>국민대</span>
                        </td>
                        <td>
                          <span>이상환</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>37</span>
                        </td>
                        <td>
                          <span>숙명여대</span>
                        </td>
                        <td>
                          <span>이종우</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>38</span>
                        </td>
                        <td>
                          <span>아주대</span>
                        </td>
                        <td>
                          <span>강경란</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>39</span>
                        </td>
                        <td>
                          <span>인하대</span>
                        </td>
                        <td>
                          <span>권장우</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>40</span>
                        </td>
                        <td>
                          <span>전북대</span>
                        </td>
                        <td>
                          <span>편기현</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>41</span>
                        </td>
                        <td>
                          <span>한국과학기술원</span>
                        </td>
                        <td>
                          <span>배두환</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>42</span>
                        </td>
                        <td>
                          <span>한밭대</span>
                        </td>
                        <td>
                          <span>황경호</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>43</span>
                        </td>
                        <td>
                          <span>경운대*</span>
                        </td>
                        <td>
                          <div>
                            <span>박양우</span>
                          </div>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>44</span>
                        </td>
                        <td>
                          <span>인제대*</span>
                        </td>
                        <td>
                          <div>
                            <span>김희철</span>
                          </div>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <table className="tableHorizontal">
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "25%" }} />
                    </colgroup>
                    <tbody>
                      <tr className="thead">
                        <td>
                          <span>
                            <strong>선정연도</strong>
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            <strong>NO</strong>
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            <strong>대학명</strong>
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            <strong>총괄책임교수&nbsp;</strong>
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            <strong>비고</strong>
                            <br />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan="6">
                          <p>
                            <span>2017년</span>
                          </p>
                          <p>
                            <span>(6개)</span>
                          </p>
                        </td>
                        <td>
                          <span>1</span>
                        </td>
                        <td>
                          <span>경희대</span>
                        </td>
                        <td>
                          <span>조진성</span>
                        </td>
                        <td>
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>2</span>
                        </td>
                        <td>
                          <span>광운대</span>
                        </td>

                        <td>
                          <span>이혁준</span>
                        </td>
                        <td>
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>3</span>
                        </td>
                        <td>
                          <span>
                            단국대
                            <br />
                          </span>
                        </td>

                        <td>
                          <span>
                            나연묵
                            <br />
                          </span>
                        </td>
                        <td>협의회장</td>
                      </tr>
                      <tr>
                        <td>
                          <span>4</span>
                        </td>
                        <td>
                          <span>
                            조선대
                            <br />
                          </span>
                        </td>

                        <td>
                          <span>
                            정일용
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>5</span>
                        </td>
                        <td>
                          <span>
                            중앙대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            박재현
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>6</span>
                        </td>
                        <td>
                          <span>
                            한동대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            이 강<br />
                          </span>
                        </td>
                        <td>교육분과장</td>
                      </tr>
                      <tr>
                        <td rowSpan="5">
                          <p>
                            <span>
                              2018년 <br />
                              상반기
                            </span>
                          </p>
                          <p>
                            <span>(5개)</span>
                          </p>
                        </td>
                        <td>
                          <span>7</span>
                        </td>
                        <td>
                          <span>
                            강원대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            임현승
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>8</span>
                        </td>
                        <td>
                          <span>
                            건국대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            조용범
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>9</span>
                        </td>
                        <td>
                          <span>
                            숭실대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            신용태
                            <br />
                          </span>
                        </td>
                        <td>산학협력분과장</td>
                      </tr>
                      <tr>
                        <td>
                          <span>10</span>
                        </td>
                        <td>
                          <span>
                            한림대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            이선우
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>11</span>
                        </td>
                        <td>
                          <span>
                            한양대 ERICA
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            이동호
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td rowSpan="5">
                          <p>
                            <span>
                              2018년 <br />
                              하반기
                            </span>
                          </p>
                          <p>
                            <span>(5개)</span>
                          </p>
                        </td>
                        <td>
                          <span>12</span>
                        </td>
                        <td>
                          <span>
                            동명대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            조미경
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>13</span>
                        </td>
                        <td>
                          <span>
                            선문대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            이 현<br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>14</span>
                        </td>
                        <td>
                          <span>
                            우송대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            김 원<br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>15</span>
                        </td>
                        <td>
                          <span>
                            원광대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            정성태
                            <br />
                          </span>
                        </td>
                        <td>해외교류분과장</td>
                      </tr>
                      <tr>
                        <td>
                          <span>16</span>
                        </td>
                        <td>
                          <span>
                            제주대
                            <br />
                          </span>
                        </td>
                        <td>
                          <span>
                            송왕철
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td rowSpan="5">
                          <span>
                            2019년
                            <span>
                              &nbsp;
                              <br />
                              상반기
                            </span>
                            <br />
                            (5개)
                          </span>
                        </td>
                        <td>
                          <span>17</span>
                        </td>
                        <td>
                          <span>대구 가톨릭대</span>
                        </td>
                        <td>
                          <span>
                            김미혜
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>18</span>
                        </td>
                        <td>
                          <span>안동대</span>
                        </td>
                        <td>
                          <span>김병순</span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>19</span>
                        </td>
                        <td>
                          <span>연세대 미래</span>
                        </td>
                        <td>
                          <span>윤상균</span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>20</span>
                        </td>
                        <td>
                          <span>이화여대</span>
                        </td>
                        <td>
                          <span>신경식</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>21</span>
                        </td>
                        <td>
                          <span>충북대</span>
                        </td>
                        <td>
                          <span>
                            이건명
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td rowSpan="5">
                          <span>
                            2019년
                            <span>
                              &nbsp;
                              <br />
                              하반기
                            </span>{" "}
                            <br />
                            (5개)
                          </span>
                        </td>
                        <td>
                          <span>22</span>
                        </td>
                        <td>
                          <span>동서대</span>
                        </td>
                        <td>
                          <span>
                            문미경
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>23</span>
                        </td>
                        <td>
                          <span>배재대</span>
                        </td>
                        <td>
                          <span>정회경</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>24</span>
                        </td>
                        <td>
                          <span>상명대</span>
                        </td>
                        <td>
                          <span>장준호</span>
                        </td>
                        <td>
                          가치확산분과장
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>25</span>
                        </td>
                        <td>
                          <span>한국외대</span>
                        </td>
                        <td>
                          <span>김낙현</span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>26</span>
                        </td>
                        <td>
                          <span>호서대</span>
                        </td>
                        <td>
                          <span>
                            선복근
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td rowSpan="9">
                          <p>
                            <span>2021년</span>
                          </p>
                          <p>
                            <span>(9개)</span>
                          </p>
                        </td>
                        <td>
                          <span>27</span>
                        </td>
                        <td>
                          <span>가천대</span>
                        </td>
                        <td>
                          <span>김 원</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>28</span>
                        </td>
                        <td>
                          <span>경기대</span>
                        </td>
                        <td>
                          <span>권기현</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>29</span>
                        </td>
                        <td>
                          <span>경북대</span>
                        </td>
                        <td>
                          <span>고석주</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>30</span>
                        </td>
                        <td>
                          <span>성균관대</span>
                        </td>
                        <td>
                          <span>이은석</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>31</span>
                        </td>
                        <td>
                          <span>순천향대</span>
                        </td>
                        <td>
                          <span>전창완</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>32</span>
                        </td>
                        <td>
                          <span>전남대</span>
                        </td>
                        <td>
                          <span>김경백</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>33</span>
                        </td>
                        <td>
                          <span>충남대</span>
                        </td>
                        <td>
                          <span>김형식</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>34</span>
                        </td>
                        <td>
                          <span>삼육대*</span>
                        </td>
                        <td>
                          <div>
                            <span>오덕신</span>
                          </div>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>35</span>
                        </td>
                        <td>
                          <span>한국항공대*</span>
                        </td>
                        <td>
                          <div>
                            <span>최영식</span>
                          </div>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="new">
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "25%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "25%" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td rowSpan="9">
                          <div>
                            <span>2022년&nbsp;</span>
                          </div>
                          <div>
                            <span>(9개)</span>
                          </div>
                        </td>
                        <td>
                          <span>36</span>
                        </td>
                        <td>
                          <span>국민대</span>
                        </td>
                        <td>
                          <span>이상환</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>37</span>
                        </td>
                        <td>
                          <span>숙명여대</span>
                        </td>
                        <td>
                          <span>이종우</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>38</span>
                        </td>
                        <td>
                          <span>아주대</span>
                        </td>
                        <td>
                          <span>강경란</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>39</span>
                        </td>
                        <td>
                          <span>인하대</span>
                        </td>
                        <td>
                          <span>권장우</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>40</span>
                        </td>
                        <td>
                          <span>전북대</span>
                        </td>
                        <td>
                          <span>편기현</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>41</span>
                        </td>
                        <td>
                          <span>한국과학기술원</span>
                        </td>
                        <td>
                          <span>배두환</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>42</span>
                        </td>
                        <td>
                          <span>한밭대</span>
                        </td>
                        <td>
                          <span>황경호</span>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>43</span>
                        </td>
                        <td>
                          <span>경운대*</span>
                        </td>
                        <td>
                          <div>
                            <span>박양우</span>
                          </div>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>44</span>
                        </td>
                        <td>
                          <span>인제대*</span>
                        </td>
                        <td>
                          <div>
                            <span>김희철</span>
                          </div>
                        </td>
                        <td>
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              <p className="tbl_caption">* 특화 트랙 : 중·소규모 대학</p>
              <span className="map_tit">
                사업기간종료 대학<small>14개교</small>
              </span>
              <table>
                <colgroup>
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "10%" }} />
                </colgroup>
                <tbody>
                  <tr className="thead">
                    <td>
                      <span>
                        <strong>선정연도</strong>
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <strong>NO</strong>
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <strong>대학명</strong>
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <strong>총괄책임교수</strong>
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <strong>소속지역&nbsp;</strong>
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <strong>비고</strong>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={8} className="udb">
                      <p>
                        <span>2015년</span>
                      </p>
                      <p>
                        <span>(8개)</span>
                      </p>
                    </td>
                    <td>
                      <span>
                        1<br />
                      </span>
                    </td>
                    <td>
                      <span>
                        가천대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        김 원<br />
                      </span>
                    </td>
                    <td>
                      <span>
                        경기
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        2<br />
                      </span>
                    </td>
                    <td>
                      <span>
                        경북대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        고석주
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        대구
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        3<br />
                      </span>
                    </td>
                    <td>
                      <span>
                        고려대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        이원규
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        서울
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        4<br />
                      </span>
                    </td>
                    <td>
                      <span>
                        서강대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        서정연
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        서울
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        5<br />
                      </span>
                    </td>
                    <td>
                      <span>
                        성균관대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        정태명
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        경기
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        6<br />
                      </span>
                    </td>
                    <td>
                      <span>
                        세종대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        백성욱
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        서울
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        7<br />
                      </span>
                    </td>
                    <td>
                      <span>
                        아주대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        류기열
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        경기
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="udb">
                      <span>
                        8<br />
                      </span>
                    </td>
                    <td className="udb">
                      <span>
                        충남대
                        <br />
                      </span>
                    </td>
                    <td className="udb">
                      <span>
                        김형신
                        <br />
                      </span>
                    </td>
                    <td className="udb">
                      <span>
                        대전
                        <br />
                      </span>
                    </td>
                    <td className="udb">
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={6} className="udb">
                      <p>
                        <span>2016년</span>
                      </p>
                      <p>
                        <span>(6개)</span>
                      </p>
                    </td>
                    <td>
                      <span>
                        9<br />
                      </span>
                    </td>
                    <td>
                      <span>
                        국민대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        임성수
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        서울
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        10
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        동국대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        이강우
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        서울
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        11
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        부산대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        염근혁
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        부산
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        12
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        서울여대
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        엄성용
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        서울
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        13
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        한국과학기술원
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        배두환
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        대전
                        <br />
                      </span>
                    </td>
                    <td>
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="udb">
                      <span>
                        14
                        <br />
                      </span>
                    </td>
                    <td className="udb">
                      <span>
                        한양대
                        <br />
                      </span>
                    </td>
                    <td className="udb">
                      <span>
                        유민수
                        <br />
                      </span>
                    </td>
                    <td className="udb">
                      <span>
                        서울
                        <br />
                      </span>
                    </td>
                    <td className="udb">
                      <span>
                        <br />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnivCurrent;
