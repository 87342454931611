import React from "react";

export const MenuOpen = () => {
  const TARGET_DATE = new Date(`2022-11-21 00:00:00`);
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const KR_TAREGET_DATE =
    TARGET_DATE.getTime() + TARGET_DATE.getTimezoneOffset() * 60 * 1000;
  const FINAL_DATE = new Date(KR_TAREGET_DATE + KR_TIME_DIFF);

  const nowDate = new Date();
  const nowDate_C = nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000;
  const FINAL_NOW = new Date(nowDate_C + KR_TIME_DIFF);

  return FINAL_DATE < FINAL_NOW;
};

export const FinalOpen = () => {
  const TARGET_DATE = new Date(`2022-12-01 00:00:00`);
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const KR_TAREGET_DATE =
    TARGET_DATE.getTime() + TARGET_DATE.getTimezoneOffset() * 60 * 1000;
  const FINAL_DATE = new Date(KR_TAREGET_DATE + KR_TIME_DIFF);

  const nowDate = new Date();
  const nowDate_C = nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000;
  const FINAL_NOW = new Date(nowDate_C + KR_TIME_DIFF);

  return FINAL_DATE < FINAL_NOW;
};

export const VoteOpen = () => {
  const Target_date = new Date(`2022/12/08 10:00:00`);
  const Target_endDate = new Date(`2022/12/09 14:00:00`);
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

  const KR_TAREGET_DATE =
    Target_date.getTime() + Target_date.getTimezoneOffset() * 60 * 1000;
  const FINAL_DATE = new Date(KR_TAREGET_DATE + KR_TIME_DIFF);

  const KR_TAREGET_End_DATE =
    Target_endDate.getTime() + Target_endDate.getTimezoneOffset() * 60 * 1000;
  const FINAL_END_DATE = new Date(KR_TAREGET_End_DATE + KR_TIME_DIFF);

  const nowDate = new Date();
  const nowDate_C = nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000;
  const FINAL_NOW = new Date(nowDate_C + KR_TIME_DIFF);

  return FINAL_NOW > FINAL_DATE && FINAL_NOW < FINAL_END_DATE;
};

export const IsFirstDay = () => {
  const Target_date = new Date(`2022/12/08 00:00:00`);
  const Target_endDate = new Date(`2022/12/08 23:59:59`);
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const KR_TAREGET_DATE =
    Target_date.getTime() + Target_date.getTimezoneOffset() * 60 * 1000;
  const FINAL_DATE = new Date(KR_TAREGET_DATE + KR_TIME_DIFF);

  const KR_TAREGET_End_DATE =
    Target_endDate.getTime() + Target_endDate.getTimezoneOffset() * 60 * 1000;
  const FINAL_END_DATE = new Date(KR_TAREGET_End_DATE + KR_TIME_DIFF);

  const nowDate = new Date();
  const nowDate_C = nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000;
  const FINAL_NOW = new Date(nowDate_C + KR_TIME_DIFF);

  return FINAL_NOW > FINAL_DATE && FINAL_NOW < FINAL_END_DATE;
};

export const IsSecondDay = () => {
  const Target_date = new Date(`2022/12/09 00:00:00`);
  const Target_endDate = new Date(`2022/12/09 23:59:59`);
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const KR_TAREGET_DATE =
    Target_date.getTime() + Target_date.getTimezoneOffset() * 60 * 1000;
  const FINAL_DATE = new Date(KR_TAREGET_DATE + KR_TIME_DIFF);

  const KR_TAREGET_End_DATE =
    Target_endDate.getTime() + Target_endDate.getTimezoneOffset() * 60 * 1000;
  const FINAL_END_DATE = new Date(KR_TAREGET_End_DATE + KR_TIME_DIFF);

  const nowDate = new Date();
  const nowDate_C = nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000;
  const FINAL_NOW = new Date(nowDate_C + KR_TIME_DIFF);

  return FINAL_NOW > FINAL_DATE && FINAL_NOW < FINAL_END_DATE;
};
