import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import PdfViewMobile from "../components/PdfViewMobile";
import { isMobile } from "react-device-detect";
import TestPdf from "../kangwon2.pdf";
import PdfViewDeskTopModule from "../components/PdfViewDeskTopModule";
import { FlipLoadingSpinner } from "../components/FlipLoading";
import { FlipLoadingSpin } from "../components/FlipLoadingSpin";

const TestPage = () => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const togglePdfView = (url) => {
    setUrl(url);
    setOpen(true);
  };
  return (
    <div>
      <p>this is test Page</p>
      <div>
        <ul>
          <li style={{ padding: "1em" }}>
            test Data 1{" "}
            <button
              style={{ padding: "1em" }}
              onClick={() =>
                togglePdfView(
                  "https://s3.ap-northeast-2.amazonaws.com/mulsarang2021.archive/pdf01.pdf"
                )
              }
            >
              viewPdf 1{" "}
            </button>
          </li>
          <li style={{ padding: "1em" }}>
            test Data 2{" "}
            <button
              style={{ padding: "1em" }}
              onClick={() =>
                togglePdfView(
                  "https://s3.ap-northeast-2.amazonaws.com/mulsarang2021.archive/pdf01.pdf"
                )
              }
            >
              viewPdf 2
            </button>
          </li>
        </ul>

        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div>
            <div>
              <span>tesddt</span>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <ModelWrap className="modal">
          <ModalBody className="modalBody">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                padding: "1em",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "1em", fontWeight: "bold" }}>
                  2022 SW인재페스티벌 우수작품
                </span>
                <button
                  style={{
                    border: "none",
                    background: "transparent",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(false)}
                >
                  닫기
                </button>
              </div>
            </div>
            <div>
              {!isMobile && loading && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "1000",
                  }}
                >
                  <FlipLoadingSpin text={"2022 SW 인재페스티벌"} />
                </div>
              )}

              {isMobile ? (
                <PdfViewMobile url={TestPdf} />
              ) : (
                <PdfViewDeskTopModule url={TestPdf} setLoad={setLoading} />
              )}
            </div>
          </ModalBody>
        </ModelWrap>
      )}
    </div>
  );
};

const loadingAnimation = keyframes`
0%{
  width:0px;
}
100%{
  width:100%;
}
`;

const loadingAnimation2 = keyframes`
0%{
  width:100%;
  background-position: 0px 0px;
}
100%{
  width:100%;
  background-position: 50px 50px;
}
`;

const loadingRule = css`
  ${loadingAnimation} 3s ease-in-out forwards;
`;

const loadingRule2 = css`
  ${loadingAnimation2} .5s linear infinite;
`;

const LoadingBar = styled.div`
  height: 20px;

  position: relative;
  background: linear-gradient(to bottom, #f9f9f9, blue);
  margin-bottom: 1em;
  border-radius: 50px;
  animation: ${loadingRule};

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    background-size: 50px 50px;

    animation: ${loadingRule2};
  }
`;

const ModelWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
`;

export default TestPage;
