import { FlipLoadingSpinner, FlipL_Text } from "./FlipLoading";

export const FlipLoadingSpin = ({ text }) => {
  console.log(text);
  const textArray = text.split("");

  return (
    <div>
      <FlipL_Text>
        <span>loading</span>
      </FlipL_Text>

      {textArray.map((value, index) => {
        return (
          <FlipLoadingSpinner key={`spin_${index}`} index={index + 1}>
            {value}
          </FlipLoadingSpinner>
        );
      })}
    </div>
  );

  //   return textArray.map((value, index) => (
  //     <FlipLoadingSpinner key={`spin_${index}`} index={index + 1}>
  //       {value}
  //     </FlipLoadingSpinner>
  //   ));
};
