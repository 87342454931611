import React from "react";
import { Link } from "react-router-dom";

const InfoTabs = ({ current }) => {
  return (
    <div className={"sub_nav depth3"}>
      <ul>
        <li className={current === "introduce" ? "active" : ""}>
          <Link to="/introduce">행사소개</Link>
        </li>
        <li className={current === "last" ? "active" : ""}>
          <Link to="/last">지난행사 소개</Link>
        </li>
        <li className={current === "list" ? "active" : ""}>
          <Link to="/univList">참여대학</Link>
        </li>
      </ul>
    </div>
  );
};

export default InfoTabs;
