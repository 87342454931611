import React from "react";
import { Link } from "react-router-dom";

const ProgramTabs = ({ current }) => {
  return (
    <div className={"sub_nav depth5"}>
      <ul>
        <li className={current === "ework" ? "active" : ""}>
          <Link to="/ework">우수작품 · 대학소개</Link>
        </li>
        <li className={current === "special" ? "active" : ""}>
          <Link to="/special">명사특강</Link>
        </li>
        <li className={current === "concert" ? "active" : ""}>
          <Link to="/concert">토크콘서트</Link>
        </li>
        <li className={current === "lecture" ? "active" : ""}>
          <Link to="/lecture">강연</Link>
        </li>
        <li className={current === "award" ? "active" : ""}>
          <Link to="/award">시상식</Link>
        </li>
      </ul>
    </div>
  );
};
export default ProgramTabs;
