import React, { useEffect, useState } from "react";

import PdfViewDeskTop from "./PdfViewDeskTop";
// import "../fliptest.scss";
// import "../fliptest2.scss";
import FlipDeskTopMove from "./FlipDesktopMove";
import LoadingBox from "./LoadingBox";
import { act } from "react-dom/test-utils";

const PdfViewDeskTopModule = ({ url, setLoad }) => {
  const [active, setActive] = useState(false);
  const [backActive, setBackActive] = useState(false);
  const [imgSize, setImgSize] = useState({
    width: 800,
    height: 0,
  });
  const [loading, setLoading] = useState(true);
  const [refList, setRefList] = useState([]);
  const [pageN, setPageN] = useState(1);
  const [zValue, setZValue] = useState(10);

  const toggleActive = () => {
    setActive(false);
    setPageN(pageN + 1);
  };

  const toggleBackActive = () => {
    setBackActive(false);
    setPageN(pageN - 1);
  };

  const handleActive = () => {
    setActive(true);
    let timer_1 = setTimeout(() => toggleActive(), 1000);
    // let timer_3 = setTimeout(() => setPageN(pageN + 1), 500);
    return () => {
      clearTimeout(timer_1);
    };
  };

  const handleBackActive = () => {
    setBackActive(true);
    let timer_2 = setTimeout(() => toggleBackActive(), 1000);
    // let timer_4 = setTimeout(() => setPageN(pageN - 1), 500);
    return () => {
      clearTimeout(timer_2);
    };
  };

  // console.log(loading);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        position: "relative",
      }}
    >
      {!loading && (
        <div
          className={"step2"}
          style={{
            position: "absolute",
            width: "800px",
            height: `${imgSize.height}px`,
            visibility: "visible",
            zIndex: `${active || backActive ? 9999 : 10}`,
          }}
        >
          <FlipDeskTopMove
            pageN={pageN}
            active={active}
            backActive={backActive}
            imgSize={imgSize}
            refList={refList}
          />
        </div>
      )}
      {loading && (
        <div
          style={{
            position: "fixed",
            zIndex: 11111,
            top: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100px",
            background: "white",
            height: "100px",
            borderRadius: "50%",
          }}
        >
          <p>Loading...</p>
        </div>
      )}

      <div>
        <PdfViewDeskTop
          url={url}
          size={imgSize}
          next={active}
          prev={backActive}
          nextActive={handleActive}
          prevActive={handleBackActive}
          setRefList={setRefList}
          getSize={setImgSize}
          toggle={setLoading}
        />
        {/* {loading && <LoadingBox />} */}
      </div>
    </div>
  );
};

export default PdfViewDeskTopModule;
