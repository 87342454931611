import React from "react";
import { Link } from "react-router-dom";
import BoardSubVisual from "../../components/BoardSubVisual";
import InfoTabs from "../../components/InfoTabs";
import { UnivList as Univ } from "../../utils/LoadUnivImg";

const UNIV_LIST = Univ;

const UnivList = () => {
  return (
    <div className="wrap main">
      <div className="wrap main">
        <div className="container sub">
          <BoardSubVisual />
          <InfoTabs current="list" />
          <div className="sub_wrap last_event">
            <span className="page_title">참여대학</span>
            <section className="univ_list section06">
              <ul className="part_list wow fadeInUp" data-wow-delay=".5s">
                {[...UNIV_LIST]
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((item, index) => (
                    <li key={`univ_${index}`}>
                      <a
                        href={item.link}
                        target={"_blank"}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={item.logo} alt={item.name + "_logo"} />
                      </a>
                    </li>
                  ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnivList;
