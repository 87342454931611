import React from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import ProgramTabs from "../../components/ProgramTags";
import Ul01 from "../../asset/img/sub/img_ul01.png";
import Ul02 from "../../asset/img/sub/img_ul02.png";
import Ul03 from "../../asset/img/sub/img_ul03.png";
import Ul04 from "../../asset/img/sub/img_ul04.png";

const Lecture = () => {
  return (
    <div className="wrap main">
      <div className="wrap main">
        <div className="container sub">
          <BoardSubVisual />
          <ProgramTabs current="lecture" />
          <div className="sub_wrap additional">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <span
                className="page_title"
                style={{ marginLeft: 0, marginRight: 0, textAlign: "left" }}
              >
                SW중심대학 강연
                <br />
                <small>일시 : 22. 12. 8(목), 15:00~16:00</small>
                <small style={{ display: "block" }}>장소 : 컨퍼런스 홀</small>
              </span>
              <div>
                <a
                  type="button"
                  style={{
                    padding: "0.5em 1em",
                    color: "white",
                    background: "#8c7eb8",
                    border: "none",
                    borderRadius: "5em",
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  target={"_blank"}
                  href={
                    "https://docs.google.com/forms/d/e/1FAIpQLSemvZjonIsJtWaEyHhGyPISpYy0CItf8mrOlPlAgTk5QNd9Wg/viewform"
                  }
                >
                  사전신청
                </a>
              </div>
            </div>

            <div className="ul_list lecture">
              <ul>
                <li>
                  <div className="text_area">
                    <div className="tit_area">
                      <span>
                        <b>1부 :</b> AI의 미래
                      </span>
                    </div>
                    <div className="img_area">
                      <img src={Ul01} alt="lecture thumbnail1" />
                    </div>
                    <div className="info">
                      <p>
                        <b>장의진</b> 교수
                      </p>
                      <p>일시 : 22. 12. 8(목), 15:00 ~ 15:25</p>
                      <p>장소 : 컨퍼런스 홀</p>
                      <p>대상 : 고등학생 및 일반</p>
                      <p style={{ marginTop: "1em" }}>
                        현) 숭실대학교 스파르탄 SW교육원 교수
                      </p>
                      <p>전) ㈜엘엔제이테크 기술연구소 수석연구원</p>
                      <p>전) 한국저작권위원회 저작권기술팀 책임연구원</p>
                    </div>
                  </div>
                  {/* <div className="video_area">
                    <iframe
                      src="https://www.youtube.com/embed/P141UtTUvJA"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen=""
                    ></iframe>
                  </div> */}
                </li>
                <li>
                  <div className="text_area">
                    <div className="tit_area">
                      <span>
                        <b>2부 :</b> 생활 속 SW
                      </span>
                    </div>
                    <div className="img_area">
                      <img src={Ul02} alt="lecture thumbnail2" />
                    </div>
                    <div className="info">
                      <p>
                        <b>양재희</b> 특임 교수
                      </p>
                      <p>일시 : 22. 12. 8(목), 15:30 ~ 15:55</p>
                      <p>장소 : 컨퍼런스 홀</p>
                      <p>대상 : 고등학생 및 일반</p>

                      <p style={{ marginTop: "1em" }}>
                        현) 이화여대 컴퓨터공학전공 특임교수
                      </p>
                      <p>전) 한남대학교 멀티미디어학부 겸임교수</p>
                    </div>
                  </div>
                  {/* <div className="video_area">
                    <iframe
                      src="https://www.youtube.com/embed/P141UtTUvJA"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen=""
                    ></iframe>
                  </div> */}
                </li>
              </ul>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <span
                  className="page_title"
                  style={{ marginLeft: 0, marginRight: 0, textAlign: "left" }}
                >
                  기업 강연
                  <br />
                  <small>일시 : 22. 12. 8(목), 16:00~17:00</small>
                  <small style={{ display: "block" }}>장소 : 컨퍼런스 홀</small>
                </span>
                <a
                  type="button"
                  style={{
                    padding: "0.5em 1em",
                    color: "white",
                    background: "#8c7eb8",
                    border: "none",
                    borderRadius: "5em",
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  target={"_blank"}
                  href={
                    "https://docs.google.com/forms/d/e/1FAIpQLScyz2flcAJFDw37_Mc-cD3FRvcVQ6uyQ7gblA72dvhC1eSZkw/viewform"
                  }
                >
                  사전신청
                </a>
              </div>
              <div className="ul_list">
                <ul>
                  <li>
                    <div className="text_area">
                      <div className="tit_area">
                        <span>
                          <b>1부 :</b> 개발자 성장과 커뮤니티 활동
                        </span>
                      </div>
                      <div className="img_area">
                        <img src={Ul03} alt="" />
                      </div>
                      <div className="info">
                        <p>
                          <b>김상기</b> DevRel
                        </p>
                        <p>일시 : 22. 12. 8(목), 16:00 ~ 16:25</p>
                        <p>장소 : 컨퍼런스 홀</p>
                        <p>대상 : 대학생 및 일반</p>

                        <p style={{ marginTop: "1em" }}>
                          현) SK텔레콤 Tech HR팀
                        </p>
                        <p>현) SK그룹 DR 플랫폼 구축 및 운영</p>
                      </div>
                    </div>
                    {/* <div className="video_area">
                      <iframe
                        src="https://www.youtube.com/embed/P141UtTUvJA"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      ></iframe>
                    </div> */}
                  </li>
                  <li>
                    <div className="text_area">
                      <div className="tit_area">
                        <span>
                          <b>2부 :</b> IT 기업이 바라는 개발자 덕목 10가지
                        </span>
                      </div>
                      <div className="img_area">
                        <img src={Ul04} alt="" />
                      </div>
                      <div className="info">
                        <p>
                          <b>오인수</b> 넷마블 AI센터장
                        </p>
                        <p>일시 : 22. 12. 8(목), 16:30 ~ 16:55</p>
                        <p>장소 : 컨퍼런스 홀</p>
                        <p>대상 : 대학생 및 일반</p>
                        <p style={{ marginTop: "1em" }}>
                          현) 넷마블 AI센터 센터장
                        </p>
                        <p>전) 넷마블 앤파크 CTO</p>
                        <p>
                          전) 마구마구, 마구더리얼, ASDF 하버보드 등 게임 개발
                          리드
                        </p>
                      </div>
                    </div>
                    {/* <div className="video_area">
                      <iframe
                        src="https://www.youtube.com/embed/P141UtTUvJA"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      ></iframe>
                    </div> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lecture;
