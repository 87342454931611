import React from "react";
import styled from "styled-components";

export const EbookWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;

export const EbookPager = styled.div`
  position: absolute;
  top: 45%;
  width: 100%;
  z-index: 111;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
