import React from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import BoardTabs from "../../components/BoardTabs";

import Thumb01 from "../../asset/img/sub/thumb_event01.png";
import Thumb02 from "../../asset/img/sub/thumb_event02.png";
import { Link } from "react-router-dom";

const EventList = () => {
  return (
    <div className="wrap main">
      <div className="wrap main">
        <div className="container sub">
          <BoardSubVisual />
          <BoardTabs current={"event"} />
          <div className="sub_wrap">
            <span className="page_title">이벤트</span>
            <div className="board_wrap">
              <div className={"thumb_list event"}>
                <ul>
                  <li>
                    <Link to="/event/1">
                      <img src={Thumb01} />
                    </Link>
                  </li>
                  <li>
                    <Link to="/event/2">
                      <img src={Thumb02} />
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <img
              className="eventImg"
              src={EventSample}
              alt=""
              useMap={"#eventLink"}
              width="700"
              height="2051"
            />
            <map name="eventLink" ref={imgMapRef}>
              <area
                shape="rect"
                coords="92,1819,334,1899"
                href="join.html"
                target="_blank"
                title="사전 등록 이벤트 참여하기"
                alt="사전 등록 이벤트 참여하기"
              />
              <area
                shape="rect"
                coords="366,1819,608,1899"
                href="http://naver.me/xVp1sJla"
                target="_blank"
                title="사전 등록 이벤트 참여하기"
                alt="사전 등록 이벤트 참여하기"
              />
            </map> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventList;
