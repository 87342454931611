import React, { useRef, useState } from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import Info2Tabs from "../../components/Info2Tabs";
import Booth2 from "../../asset/img/sub/img_booth.png";
import Booth3 from "../../asset/img/main/booth.png";
import BoothFinal from "../../asset/img/main/regMap.png";
import { boothList } from "../../utils/BoothList";

const Booth = () => {
  const booth = boothList;

  const [list, setList] = useState([...booth]);

  const [keyword, setKeyword] = useState("");
  const keyRef = useRef(null);

  const handleSearch = () => {
    setKeyword(keyRef.current.value);
  };

  const handleSort = (sortType) => {
    switch (sortType) {
      case "kor":
        setList([...booth.sort((a, b) => (a.name > b.name ? 1 : -1))]);
        break;
      case "num":
        setList([...booth.sort((a, b) => (a.number > b.number ? 1 : -1))]);
        break;
      default:
        break;
    }
  };

  return (
    <div className={"wrap main"}>
      <div className={"wrap exhibition"}>
        <div className={"container sub"}>
          <BoardSubVisual />
          <Info2Tabs current={"booth"} />
          <div className={"sub_wrap ew_list work"}>
            <span className={"page_title"}>부스배치도</span>
            <div className={"booth_area"}>
              <a href={BoothFinal} target={"_blank"}>
                <img src={BoothFinal} alt="booth location" />
              </a>
            </div>
            <div className="search_area booth">
              <div className={"boothSortWrap"}>
                <div className={"boothSort"}>
                  <div className={"input_area"}>
                    <input
                      type="radio"
                      name="sortKor"
                      id="sortKor"
                      className="inp_sorting"
                      onClick={() => handleSort("kor")}
                      // onClick={(e) => handleFilterKo(e)}
                    />
                    <label htmlFor="sortKor">
                      <span className="sorting_box"></span>
                      <p>가나다 순으로 보기</p>
                    </label>
                  </div>
                </div>
                <div className={"boothSort"}>
                  <div className={"input_area"}>
                    <input
                      type="radio"
                      name="sortKor"
                      id="sortNum"
                      className="inp_sorting"
                      onClick={() => handleSort("num")}
                      // onClick={(e) => handleFilterKo(e)}
                    />
                    <label htmlFor="sortNum">
                      <span className="sorting_box"></span>
                      <p>부스번호 순으로 보기</p>
                    </label>
                  </div>
                </div>
              </div>

              <div className="input_area">
                <input
                  type="text"
                  name="inp_search"
                  className="inp_search"
                  placeholder="검색어 입력"
                  ref={keyRef}
                />
                <button
                  type="button"
                  name="btn_search"
                  className="btn_search"
                  onClick={handleSearch}
                >
                  검색
                </button>
              </div>
            </div>

            <div className="booth_list">
              <div className="bl01">
                <div className="bl_tit">
                  <span>홍보입시관</span>
                  <p>A01 ~ A44</p>
                </div>
                <ul>
                  {list
                    .filter(
                      (f) =>
                        f.number.indexOf("A") !== -1 &&
                        f.name.indexOf(keyword) !== -1
                    )
                    .map((item, index) => (
                      <li key={`A_${index}`}>
                        <span>{item.number}</span>
                        <p>{item.name}</p>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="bl02">
                <div className="bl_tit">
                  <span>우수작품관</span>
                  <p>B01 ~ B44</p>
                </div>
                <ul>
                  {list
                    .filter(
                      (f) =>
                        f.number.indexOf("B") !== -1 &&
                        f.name.indexOf(keyword) !== -1
                    )
                    .map((item, index) => (
                      <li key={`A_${index}`}>
                        <span>{item.number}</span>
                        <p>{item.name}</p>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="bl03">
                <div className="bl_tit">
                  <span>기업부스</span>
                  <p>C01 ~ C12</p>
                </div>
                <ul>
                  {list
                    .filter(
                      (f) =>
                        f.number.indexOf("C") !== -1 &&
                        f.number.indexOf("~") === -1 &&
                        f.name.indexOf(keyword) !== -1
                    )
                    .map((item, index) => (
                      <li key={`A_${index}`}>
                        <span>{item.number}</span>
                        <p>{item.name}</p>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="bl05">
                <div className="bl_tit">
                  <span>공동채용관 D01</span>
                </div>
                <ul>
                  {list
                    .filter(
                      (f) =>
                        f.number.indexOf("D") !== -1 &&
                        f.name.indexOf(keyword) !== -1
                    )
                    .map((item, index) => (
                      <li key={`A_${index}`}>
                        <p>{item.name}</p>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booth;
