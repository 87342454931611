import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const accountSelect = atom({
  key: "accountSelect",
  default: {
    id: undefined,
    accessToken: undefined,
    voteList: [],
  },
  effects_UNSTABLE: [persistAtom],
});

export const ebookSelect = atom({
  key: "ebookSelect",
  default: {
    list: [],
  },
});
