import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import NStyle from "../styles/NormalPdf.module.scss";
import ArrowPng from "../btn_prev_w.png";
import { FlipLoadingSpin } from "./FlipLoadingSpin";
import { ScaleLoader } from "react-spinners";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewUnivInfo = ({ url }) => {
  const [numPages, setNumbPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [loading, setLoading] = useState(true);

  const documentLoadSuccess = ({ numPages }) => {
    setNumbPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber + 1 <= numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const pageRenderSuccess = () => {
    console.log("loading...");
    setLoading(false);
  };

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      {pageNumber !== numPages && (
        <div
          style={{ position: "absolute", top: "45%", right: 0, zIndex: 11111 }}
        >
          <button
            type="button"
            onClick={handleNextPage}
            style={{
              borderRadius: "50%",
              padding: "1em",
              border: "none",
              width: "60px",
              height: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ArrowPng} style={{ transform: "rotateY(180deg)" }} />
          </button>
        </div>
      )}
      {pageNumber !== 1 && (
        <div
          style={{ position: "absolute", top: "45%", left: 0, zIndex: 11111 }}
        >
          <button
            type="button"
            onClick={handlePrevPage}
            style={{
              borderRadius: "50%",
              padding: "1em",
              border: "none",
              width: "60px",
              height: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ArrowPng} />
          </button>
        </div>
      )}
      {/* <div
        style={{ position: "absolute", top: "45%", left: "50", zIndex: 11111 }}
      >
        <p>loading</p>
      </div> */}

      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && (
          <ScaleLoader color="#36d7d7" style={{ position: "fixed" }} />
        )}
        <iframe
          src={`https://docs.google.com/gview?url=${url}&embedded=true`}
          width={1000}
          height={800}
          frameBorder={"0"}
          onLoad={onLoad}
          // scrolling={"no"}
          allowFullScreen={"true"}
          // style={{ background: "white" }}
        />
      </div> */}

      <Document
        className={NStyle.wrap}
        file={url}
        onLoadSuccess={documentLoadSuccess}
        externalLinkTarget="_blank"
        externalLinkRel="_blank"
        loading={""}
        options={{
          cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
      >
        <Page
          className={NStyle.page}
          scale={1}
          pageNumber={pageNumber}
          onRenderSuccess={() => pageRenderSuccess()}
          onRenderError={(e) => console.log(e)}
          onGetTextError={(e) => console.log(e)}
        />
      </Document>
    </div>
  );
};

export default PdfViewUnivInfo;
