import React from "react";
import { Link } from "react-router-dom";

const LoginTabs = ({ current }) => {
  return (
    <div className={"sub_nav depth2"}>
      <ul>
        <li className={current === "login" ? "active" : ""}>
          <Link to="/login">로그인</Link>
        </li>
        <li className={current === "signup" ? "active" : ""}>
          <Link to="/signup">회원가입</Link>
        </li>
      </ul>
    </div>
  );
};

export default LoginTabs;
