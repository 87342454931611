import React from "react";
import styled, { css } from "styled-components";
import BoardSubVisual from "../components/BoardSubVisual";

const TempDiv = styled.div`
  ${(props) =>
    props.type === 1
      ? css`
          background-color: rgba(117, 101, 165, 0.7);
        `
      : props.type === 2
      ? css`
          background-color: rgba(117, 101, 165, 0.5);
        `
      : props.type === 3
      ? css`
          background-color: rgba(117, 101, 165, 0.2);
        `
      : ""}
`;

const TempLi = styled.li`
  ${(props) =>
    props.active && props.type === 1
      ? css`
          background-color: rgba(117, 101, 165, 0.7);
        `
      : props.active && props.type === 2
      ? css`
          background-color: rgba(117, 101, 165, 0.5);
        `
      : css``}
`;

const Confirm = () => {
  const current = "introduce";
  return (
    <div>
      <p>confirm area</p>
      <div className={"wrap main"} style={{ marginTop: "5em" }}>
        <div className="wrap exhibition">
          <div className="container sub">
            <TempDiv
              type={0}
              className={"sub_nav depth3"}
              style={{ marginTop: "1em" }}
            >
              <ul>
                <TempLi
                  type={0}
                  className={current === "introduce" ? "active" : ""}
                >
                  <a href="#">행사소개</a>
                </TempLi>
                <TempLi type={0} className={current === "last" ? "active" : ""}>
                  <a href="#">지난행사 소개</a>
                </TempLi>
                <TempLi type={0} className={current === "list" ? "active" : ""}>
                  <a href="#">선정대학</a>
                </TempLi>
              </ul>
            </TempDiv>
            <TempDiv
              type={1}
              className={"sub_nav depth3"}
              style={{ marginTop: "1em" }}
            >
              <ul>
                <TempLi type={1} active={true}>
                  <a href="#">행사소개</a>
                </TempLi>
                <TempLi type={1} className={current === "last" ? "active" : ""}>
                  <a href="#">지난행사 소개</a>
                </TempLi>
                <TempLi type={1} className={current === "list" ? "active" : ""}>
                  <a href="#">선정대학</a>
                </TempLi>
              </ul>
            </TempDiv>

            <TempDiv
              type={2}
              className={"sub_nav depth3"}
              style={{ marginTop: "1em" }}
            >
              <ul>
                <TempLi type={2} active={true}>
                  <a href="#">행사소개</a>
                </TempLi>
                <TempLi type={2} className={current === "last" ? "active" : ""}>
                  <a href="#">지난행사 소개</a>
                </TempLi>
                <TempLi type={2} className={current === "list" ? "active" : ""}>
                  <a href="#">선정대학</a>
                </TempLi>
              </ul>
            </TempDiv>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
