import React, { useEffect, useState } from "react";
import {
  FlipMobileBody,
  FlipMobileWrap,
  FlipMobileBodyReverse,
} from "../styles/flip/Mobile";

const FlipDeskTopMove = ({
  active,
  pageN,
  backActive,
  width,
  height,
  refList,
  imgSize,
}) => {
  return (
    <>
      <FlipMobileWrap>
        <FlipMobileBody
          seq="seq1"
          active={active}
          width={imgSize.width}
          height={imgSize.height}
          curImg={refList.filter((f) => f.page === pageN)[0].blob}
        >
          <FlipMobileBody
            seq="seq2"
            active={active}
            width={imgSize.width}
            height={imgSize.height}
            curImg={refList.filter((f) => f.page === pageN)[0].blob}
          >
            <FlipMobileBody
              seq="seq3"
              active={active}
              width={imgSize.width}
              height={imgSize.height}
              curImg={refList.filter((f) => f.page === pageN)[0].blob}
            >
              <FlipMobileBody
                seq="seq4"
                active={active}
                width={imgSize.width}
                height={imgSize.height}
                curImg={refList.filter((f) => f.page === pageN)[0].blob}
              >
                <FlipMobileBody
                  seq="seq5"
                  active={active}
                  width={imgSize.width}
                  height={imgSize.height}
                  curImg={refList.filter((f) => f.page === pageN)[0].blob}
                >
                  <FlipMobileBody
                    seq="seq6"
                    active={active}
                    width={imgSize.width}
                    height={imgSize.height}
                    curImg={refList.filter((f) => f.page === pageN)[0].blob}
                  >
                    <FlipMobileBody
                      seq="seq7"
                      active={active}
                      width={imgSize.width}
                      height={imgSize.height}
                      curImg={refList.filter((f) => f.page === pageN)[0].blob}
                    >
                      <FlipMobileBody
                        seq="seq8"
                        active={active}
                        width={imgSize.width}
                        height={imgSize.height}
                        curImg={refList.filter((f) => f.page === pageN)[0].blob}
                      ></FlipMobileBody>
                    </FlipMobileBody>
                  </FlipMobileBody>
                </FlipMobileBody>
              </FlipMobileBody>
            </FlipMobileBody>
          </FlipMobileBody>
        </FlipMobileBody>
      </FlipMobileWrap>

      <FlipMobileWrap>
        <FlipMobileBodyReverse
          seq="seq1"
          active={backActive}
          width={imgSize.width}
          height={imgSize.height}
          backImg={
            refList.filter(
              (f) => f.page === (pageN - 1 <= 0 ? pageN : pageN - 1)
            )[0].blob
          }
        >
          <FlipMobileBodyReverse
            seq="seq2"
            active={backActive}
            width={imgSize.width}
            height={imgSize.height}
            backImg={
              refList.filter(
                (f) => f.page === (pageN - 1 <= 0 ? pageN : pageN - 1)
              )[0].blob
            }
          >
            <FlipMobileBodyReverse
              seq="seq3"
              active={backActive}
              width={imgSize.width}
              height={imgSize.height}
              backImg={
                refList.filter(
                  (f) => f.page === (pageN - 1 <= 0 ? pageN : pageN - 1)
                )[0].blob
              }
            >
              <FlipMobileBodyReverse
                seq="seq4"
                active={backActive}
                width={imgSize.width}
                height={imgSize.height}
                backImg={
                  refList.filter(
                    (f) => f.page === (pageN - 1 <= 0 ? pageN : pageN - 1)
                  )[0].blob
                }
              >
                <FlipMobileBodyReverse
                  seq="seq5"
                  active={backActive}
                  width={imgSize.width}
                  height={imgSize.height}
                  backImg={
                    refList.filter(
                      (f) => f.page === (pageN - 1 <= 0 ? pageN : pageN - 1)
                    )[0].blob
                  }
                >
                  <FlipMobileBodyReverse
                    seq="seq6"
                    active={backActive}
                    width={imgSize.width}
                    height={imgSize.height}
                    backImg={
                      refList.filter(
                        (f) => f.page === (pageN - 1 <= 0 ? pageN : pageN - 1)
                      )[0].blob
                    }
                  >
                    <FlipMobileBodyReverse
                      seq="seq7"
                      active={backActive}
                      width={imgSize.width}
                      height={imgSize.height}
                      backImg={
                        refList.filter(
                          (f) => f.page === (pageN - 1 <= 0 ? pageN : pageN - 1)
                        )[0].blob
                      }
                    >
                      <FlipMobileBodyReverse
                        seq="seq8"
                        active={backActive}
                        width={imgSize.width}
                        height={imgSize.height}
                        backImg={
                          refList.filter(
                            (f) =>
                              f.page === (pageN - 1 <= 0 ? pageN : pageN - 1)
                          )[0].blob
                        }
                      ></FlipMobileBodyReverse>
                    </FlipMobileBodyReverse>
                  </FlipMobileBodyReverse>
                </FlipMobileBodyReverse>
              </FlipMobileBodyReverse>
            </FlipMobileBodyReverse>
          </FlipMobileBodyReverse>
        </FlipMobileBodyReverse>
      </FlipMobileWrap>
    </>
  );
};

export default FlipDeskTopMove;
