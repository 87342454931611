import React from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import ProgramTabs from "../../components/ProgramTags";
import Video from "../../asset/img/sub/img_tc01.png";
import TC2 from "../../asset/img/sub/img_tc02.png";
import Panel01 from "../../asset/img/sub/img_panel01.png";
import Panel02 from "../../asset/img/sub/img_panel02.png";
import Panel03 from "../../asset/img/sub/img_panel03.png";
import Panel04 from "../../asset/img/sub/img_panel04.png";
import Pu18 from "../../asset/img/sub/logo_pu18.png";
import Pu22 from "../../asset/img/sub/logo_pu22.png";
import Pu44 from "../../asset/img/sub/logo_pu44.png";
import Pu36 from "../../asset/img/sub/logo_pu36.png";
import R1 from "../../asset/img/main/request1.jpeg";
import R2 from "../../asset/img/main/request2.jpeg";
import R3 from "../../asset/img/main/Plnew.jpeg";
import R4 from "../../asset/img/main/request4.jpeg";

const Concert = () => {
  return (
    <div className={"wrap main"}>
      <div className={"wrap main"}>
        <div className={"container sub"}>
          <BoardSubVisual />
          <ProgramTabs current="concert" />
          <div className="sub_wrap additional">
            <span
              className="page_title"
              style={{ marginLeft: 0, marginRight: 0, textAlign: "left" }}
            >
              토크콘서트 : SW관련, 인포테인먼트(infotainment) 형식의 토크콘서트
            </span>

            <div className="tc_cont">
              <ul className="tc_list">
                <li>
                  <div className="tit_area">
                    <span>
                      <b>1부 :</b> SW방의 문제아들
                    </span>
                  </div>
                  <div className={"at_cont"}>
                    <img src={Video} alt="" style={{ width: "9em" }} />
                    <div className="text_area" style={{ fontSize: "0.85em" }}>
                      <span>
                        <b>사회 : 김승주 아나운서</b>
                      </span>
                      <p style={{ padding: "0.3em 0" }}>
                        - 일시 : 22. 12. 9(금) 14:00 ~ 15:00
                        <br />
                        - 장소 : 아트홀 1 <br />- 대상 : 대학생 및 일반
                      </p>

                      <p className="ctext">
                        - 2021 SW인재 페스티벌 (온라인)
                        <br />
                        - ICT for UntactWorld, 2020 ICT 챌린지MC (온라인)
                        <br />- 現 MBC {"<생방송 오늘 아침>"}
                        <br />
                        - 前 목포 MBC 아나운서
                        <br />- 前 전주 MBC 아나운서
                      </p>
                    </div>
                  </div>
                  <div className="text">
                    <p>
                      <b>SW에 살고 죽는 SW인재 4인방의 퀴즈&토크쇼</b>
                    </p>
                    <ul className="panel">
                      <li>
                        <div className="img_area">
                          <img src={Panel01} />
                        </div>
                        <div className="text_area">
                          <p>패널 : 이채린 대표 (클라썸)</p>
                          <p>학력 : 카이스트</p>
                          <p>
                            이력 : 아시아 30세 이하 리더 20인, 클라썸 런칭(2017)
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="img_area">
                          <img src={Panel02} />
                        </div>
                        <div className="text_area">
                          <p>패널 : 김성진 팀장 (카카오)</p>
                          <p>
                            이력 : 현) 카카오 추천팀 개인화 추천 시스템
                            연구/개발
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;전)
                            뉴스 추천 스타트업 창업
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;전)
                            미디어젠 자연어이해(NLU) 모듈 연구/개발
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="img_area">
                          <img src={Panel03} />
                        </div>
                        <div className="text_area">
                          <p>패널 : 박건희 매니저 (지마켓, 개발자)</p>
                          <p>학력 : 국민대</p>
                          <p>
                            이력 : 2021 SW인재페스티벌 우수작품 우수상 수상팀
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="img_area">
                          <img src={Panel04} />
                        </div>
                        <div className="text_area">
                          <p>패널 : 조준영 연구원 (LG전자, 개발자)</p>
                          <p>학력 : 건국대</p>
                          <p>
                            이력 : 2021 SW인재페스티벌 우수작품 우수상 수상팀
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="btn_area">
                    <a
                      href="https://www.youtube.com/watch?v=6Ulqx3dAHnI"
                      target="_blank"
                      className="btn youtube"
                    >
                      토크콘서트 1부 보러가기
                    </a>
                  </div> */}
                </li>
                <li>
                  <div className="tit_area">
                    <span>
                      <b>2부 :</b> 소중대 사랑의 콜센타
                    </span>
                  </div>
                  <div className="at_cont">
                    <img src={TC2} alt="" style={{ width: "9em" }} />
                    <div className="text_area" style={{ fontSize: "0.85em" }}>
                      <span>
                        <b>사회 : 연호진 아나운서</b>
                      </span>
                      <p style={{ padding: "0.3em 0" }}>
                        - 일시 : 22. 12. 9(금) 15:00 ~ 16:00
                        <br />
                        - 장소 : 아트홀 1 <br />- 대상 : 대학생 및 일반
                      </p>
                      <p className="ctext">
                        - 작곡가, 가수 (싱글앨범 발매)
                        <br />
                        - (사)한국문화교육협회 홍보대사 아나운서
                        <br />
                        - MBC ‘놀면뭐하니’ 인터뷰 기자 출연
                        <br />- 넷플릭스 ‘블랙의 신부’, tvN ’멜랑꼴리아‘, KBS
                        ’미스 몬테크리스토‘ 출연
                      </p>
                    </div>
                  </div>

                  <div className="text">
                    <p>
                      <b>
                        사연과 신청곡을 직접 들어보는 SW인재들의 음악&토크쇼
                      </b>
                    </p>
                    <ul className="panel">
                      <li>
                        <div className="img_area">
                          <img src={R1} />
                        </div>
                        <div className="text_area">
                          <span>허유민 학생 (성균관대 소프트웨어학과)</span>
                          <p>
                            사연 : 수험생활을 하면서 많이 듣고, 좋아했던 곡을
                            신청합니다.
                          </p>
                          <p>선곡 : 윤하 - 혜성</p>
                        </div>
                      </li>
                      <li>
                        <div className="img_area">
                          <img src={R2} />
                        </div>
                        <div className="text_area">
                          <span>조우연 학생 (아주대 수학과)</span>
                          <p>
                            사연 : SW인재페스티벌 행사에 작품을 전시할 수 있는
                            값진 경험을 표현하고자 신청했습니다.
                          </p>
                          <p>선곡 : 한동근 - 그대라는 사치</p>
                        </div>
                      </li>
                      <li>
                        <div className="img_area">
                          <img src={R3} />
                        </div>
                        <div className="text_area">
                          <span>박정현 학생 (호서대 컴퓨터공학과)</span>
                          <p>
                            사연 : 학교생활 중 힘들었던 사연을 공개하려고
                            합니다.
                          </p>
                          <p>선곡 : 더 레이 - 청소</p>
                        </div>
                      </li>
                      <li>
                        <div className="img_area">
                          <img src={R4} />
                        </div>
                        <div className="text_area">
                          <span>이강욱 학생 (충북대 정보통신공학부)</span>
                          <p>
                            사연 : SW인재페스티벌 행사에 참여한 모든 분들에게
                            바치는 마음으로 부르겠습니다.
                          </p>
                          <p>선곡 : 김광진 - 편지</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="btn_area">
                    <a
                      href="https://www.youtube.com/watch?v=6Ulqx3dAHnI"
                      target="_blank"
                      className="btn youtube"
                    >
                      토크콘서트 2부 보러가기
                    </a>
                  </div> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Concert;
