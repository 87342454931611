import React from "react";
import { UnivList_v2 } from "../utils/LoadUnivImg";
import styled, { css } from "styled-components";

const TableTr = styled.tr`
  ${(props) =>
    props.type === "header" &&
    css`
      border-bottom: 3px solid black;
      /* background-color:  !important; */
    `}
  &:nth-child(2n) {
    background-color: white;
  }
  &:not(:nth-child(2n)) {
    background-color: #c8cfdf;
  }
`;
const TableTd = styled.td`
  color: #201f4b;
  font-size: 0.9rem;
  ${(props) =>
    props.type === "header" &&
    css`
      font-size: 1rem;
      font-weight: 600;
    `}
`;

const PLIST = UnivList_v2;
const EPrevLIst = () => {
  return (
    <div
      style={{
        display: "block",
        maxWidth: "50em",
        margin: "0 auto",
        textAlign: "center",
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "0 auto",
          paddingTop: "2em",
        }}
      >
        <label>
          <h3
            style={{
              padding: "0.2em 0.5em",
              backgroundColor: "#201f4b",
              width: "11em",
              textAlign: "center",
            }}
          >
            우수작품 발표<small> (44개교)</small>
          </h3>
        </label>

        <p style={{ width: "20em" }}>* 대학명 가나다순</p>
      </div>
      <div style={{ marginTop: "2em", overflowX: "scroll" }}>
        <table style={{ marginBottom: "2em", maxWidth: "600px" }}>
          <thead>
            <TableTr type="header">
              <TableTd type="header">순위</TableTd>
              <TableTd type="header">대학명</TableTd>
              <TableTd type="header">프로젝트 명</TableTd>
              <TableTd type="header">팀명</TableTd>
            </TableTr>
          </thead>
          <colgroup>
            <col style={{ width: "10%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "55%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <tbody>
            {PLIST.sort((a, b) => (a.name > b.name ? 1 : -1)).map(
              (item, index) => (
                <TableTr key={`pLIst_${index}`}>
                  <TableTd>{item.dataBg}</TableTd>
                  <TableTd>{item.sName}</TableTd>
                  <TableTd>{item.title}</TableTd>
                  <TableTd>{item.team}</TableTd>
                </TableTr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EPrevLIst;
