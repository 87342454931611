import styled, { css } from "styled-components";
import {
  MainFlip_after_nextPage,
  MainFlip_nextPage,
  MainFlip_prevPage,
  OtherFlip_after_nextPage,
  OtherFlip_nextPage,
  OtherFlip_prevPage,
  LastFlip_after_nextPage,
  MainFlip_after_prevPage,
  OtherFlip_after_prevPage,
  LastFlip_after_prevPage,
} from "./MobileKeyframe";

// default settings
const mainTimer = 1;
const mainRule = (type) => css`
  ${MainFlip_nextPage} ${mainTimer}s ease-in-out ${type === "r"
    ? "reverse"
    : ""};
`;
const otherRule = (type) => css`
  ${OtherFlip_nextPage} ${mainTimer}s ease-in-out ${type === "r"
    ? "reverse"
    : ""};
`;

const mainPrevRule = css`
  ${MainFlip_prevPage} ${mainTimer}s ease-in-out;
`;
const otherPrevRule = css`
  ${OtherFlip_prevPage} ${mainTimer}s ease-in-out;
`;

// animation rule - change prev page
const mainPrevAfterRule = (backImg) => css`
  ${MainFlip_after_prevPage(backImg)} ${mainTimer * 5}s ${mainTimer *
  -4}s steps(1);
`;
const OtherFlipAfterPrevPageRule = (curIndex, backImg) => css`
  ${OtherFlip_after_prevPage(curIndex, backImg)} ${mainTimer * 5}s ${mainTimer *
  -4}s steps(1);
`;
const LastFlipAfterPrevPageRule = (backImg) => css`
  ${LastFlip_after_prevPage(backImg)} ${mainTimer * 5}s ${mainTimer *
  -4}s steps(1);
`;

// animation rule - change next Page
const mainAfterRule = (curImg, backImg, type) => css`
  ${MainFlip_after_nextPage(curImg)} ${mainTimer * 5}s ${mainTimer *
  -4}s steps(1);
`;
const OtherFlipAfterNextPageRule = (curIndex, curImg, backImg, type) => css`
  ${OtherFlip_after_nextPage(curIndex, curImg)} ${mainTimer * 5}s ${mainTimer *
  -4}s steps(1);
`;
const LastFlipAfterNextPageRule = (curImg, backImg, type) => css`
  ${LastFlip_after_nextPage(curImg)} ${mainTimer * 5}s ${mainTimer *
  -4}s steps(1);
`;

export const FlipMobileWrap = styled.div`
  transform-style: preserve-3d;
`;

export const FlipMobileBody = styled.div`
  position: absolute;
  top: 0;
  transform-origin: left 100%;
  left: 0%;
  border: solid #e0e0e0;
  border-width: 0px 0px;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  width: ${(props) => props.width / 8 + 6.5}px;
  height: ${(props) => props.height}px;

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0%;
    width: 100%;
    height: 100%;
    transform-origin: center;
    background-size: ${(props) => props.width}px ${(props) => props.height}px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0%;
    width: 100%;
    height: 100%;
    /* background-color: transparent; */
    transform-origin: center;
    background-size: ${(props) => props.width}px ${(props) => props.height}px;
  }

  ${(props) =>
    props.seq === "seq1" &&
    css`
      left: 0px;
      ${props.active &&
      css`
        animation: ${mainRule};
      `}
      border-width: 0px 0px 0px 0px;
      &::after {
        background-image: URL(${props.curImg});
        background-position: -2px;
        ${props.active &&
        css`
          animation: ${mainAfterRule(props.curImg)};
        `}
      }
      &::before {
        background-image: URL(${props.curImg});
        background-position: -2px;
        ${props.active &&
        css`
          bottom: -10px;
          animation: ${mainAfterRule(props.curImg)};
        `}
      }
    `}

  ${(props) =>
    props.seq !== "seq1" &&
    css`
      left: calc(100% - 2px);
      /* top: -2px; */
      transform-origin: left;
      ${props.active &&
      css`
        animation: ${otherRule};
      `}
      &::after {
        ${[2, 3, 4, 5, 6, 7].map(
          (value, index) =>
            props.seq === `seq${value}` &&
            css`
              background-image: URL(${props.curImg});
              background-position: ${(value - 1) * -106}px;
              ${props.active &&
              css`
                animation: ${OtherFlipAfterNextPageRule(value, props.curImg)};
              `}
            `
        )};
      }
      &::before {
        ${[2, 3, 4, 5, 6, 7].map(
          (value, index) =>
            props.seq === `seq${value}` &&
            css`
              background-image: URL(${props.curImg});
              background-position: ${(value - 1) * -106}px;
              ${props.active &&
              css`
                bottom: -10px;
                animation: ${OtherFlipAfterNextPageRule(value, props.curImg)};
              `}
            `
        )};
      }
    `}
      ${(props) =>
    props.seq === "seq8" &&
    css`
      border-width: 0px 0px 0px 0;
      width: 60px;
      &::after {
        background-image: URL(${props.curImg});
        background-position: -740px;
        ${props.active &&
        css`
          animation: ${LastFlipAfterNextPageRule(props.curImg)};
        `}
      }
      &::before {
        background-image: URL(${props.curImg});
        ${props.active &&
        css`
          bottom: -10px;
          animation: ${LastFlipAfterNextPageRule(props.curImg)};
        `}
      }
    `}
`;

export const FlipMobileBodyReverse = styled.div`
  position: absolute;
  top: 0;
  transform-origin: 100% 100%;
  right: 100%;
  border: solid #e0e0e0;
  border-width: 0px 0px;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  width: ${(props) => props.width / 8 + 6.5}px;
  height: ${(props) => props.height}px;

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0%;
    width: 100%;
    height: 100%;
    transform-origin: center;
    background-size: ${(props) => props.width}px ${(props) => props.height}px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0%;
    width: 100%;
    height: 100%;
    transform-origin: center;
    background-size: ${(props) => props.width}px ${(props) => props.height}px;
  }

  ${(props) =>
    props.seq === "seq1" &&
    css`
      right: 100%;
      ${props.active &&
      css`
        animation: ${mainPrevRule};
      `}
      border-width: 0px 0px 0px 0px;
      &::after {
        ${props.active &&
        css`
          animation: ${mainPrevAfterRule(props.backImg)};
        `}
      }
      &::before {
        ${props.active &&
        css`
          bottom: -10px;
          animation: ${mainPrevAfterRule(props.backImg)};
        `}
      }
    `}

  ${(props) =>
    props.seq !== "seq1" &&
    css`
      right: calc(100% - 2px);
      /* top: -2px; */
      transform-origin: right;
      ${props.active &&
      css`
        animation: ${otherPrevRule};
      `}
      &::after {
        ${[2, 3, 4, 5, 6, 7].map(
          (value, index) =>
            props.seq === `seq${value}` &&
            css`
              ${props.active &&
              css`
                animation: ${OtherFlipAfterPrevPageRule(
                  value,

                  props.backImg
                )};
              `}
            `
        )};
      }
      &::before {
        ${[2, 3, 4, 5, 6, 7].map(
          (value, index) =>
            props.seq === `seq${value}` &&
            css`
              ${props.active &&
              css`
                bottom: -10px;
                animation: ${OtherFlipAfterPrevPageRule(
                  value,

                  props.backImg
                )};
              `}
            `
        )};
      }
    `}
      ${(props) =>
    props.seq === "seq8" &&
    css`
      border-width: 0px 0px 0px 0;
      width: 60px;
      &::after {
        ${props.active &&
        css`
          animation: ${LastFlipAfterPrevPageRule(props.backImg)};
        `}
      }
      &::before {
        ${props.active &&
        css`
          bottom: -10px;
          animation: ${LastFlipAfterPrevPageRule(props.backImg)};
        `}
      }
    `}
`;
