import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import "./asset/css/reset.css";
import "./asset/css/common.css";
import "./asset/css/layout.css";
import "./asset/css/main.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";

// const element = (

// );
const root = document.getElementById("root");

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(

if (root.hasChildNodes()) {
  const newRoot = ReactDOM.hydrateRoot(root);
  newRoot.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
} else {
  const newRoot = ReactDOM.createRoot(root);
  newRoot.render(
    <BrowserRouter>
      <ScrollTop />
      <App />
    </BrowserRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
