import { keyframes } from "styled-components";

export const backFlip = keyframes`
0%,
20%{
    transform:rotateY(0deg) translateY(0);
}
80%,
100%{
    transform:rotateY(180deg) translateY(0)
}
`;

export const NextFlipDeskTop = keyframes`
0%,
  20% {
    opacity: 1;
    transform: rotateY(0deg) translateY(0);
  }
  80%,
  100% {
    opacity: 1;
    transform: rotateY(180deg) translateY(0);
  }
`;

export const NextFlipAfterDeskTop = keyframes`
0% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  20% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  40% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  60% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  80% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
  100% {
    opacity: 1;
    /* background-position: -178px -2px; */
    transform: rotateY(0deg);
  }
`;

export const NextFlipOther = keyframes`
0%,
  20% {
    opacity: 1;
    transform: rotateY(0deg) translateY(0px) rotateZ(-1deg) translateX(-4px);
  }
  50% {
    opacity: 1;
    transform: rotateY(-20deg) translateY(-1px) rotateZ(-1deg) translateX(-4px);
  }
  
`;
