import React, { useState } from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import InfoTabs from "../../components/InfoTabs";
import P2017 from "../../asset/img/sub/poster_2017.png";
import P2018 from "../../asset/img/sub/poster_2018.png";
import P2019 from "../../asset/img/sub/poster_2019.png";
import P2020 from "../../asset/img/sub/poster_2020.png";
import P2021 from "../../asset/img/sub/poster_2021.png";
import LastEventModal from "../../components/info/LastModal";

const LastEvent = () => {
  const [modal, setModal] = useState({
    open: false,
    year: 0,
  });

  const handleModalOpen = (year) => {
    setModal({
      open: true,
      year: year * 1,
    });
  };

  const handleCloseModal = () => {
    setModal({
      open: false,
      year: 0,
    });
  };
  return (
    <div className={"wrap main"}>
      <div className={"wrap main"}>
        <div className={"container sub"}>
          <BoardSubVisual />
          <InfoTabs current="last" />
          <div className="sub_wrap last_event">
            <span className="page_title">지난행사 소개</span>
            <section className="event_list">
              <ul>
                <li>
                  <img src={P2017} alt="2017년 포스터" />
                  <span className="year">2017</span>
                  <a className="le01" onClick={() => handleModalOpen(2017)}>
                    개요보기
                  </a>
                </li>
                <li>
                  <img src={P2018} alt="2018년 포스터" />
                  <span className="year">2018</span>
                  <a className="le02" onClick={() => handleModalOpen(2018)}>
                    개요보기
                  </a>
                </li>
                <li>
                  <img src={P2019} alt="2019년 포스터" />
                  <span className="year">2019</span>
                  <a className="le03" onClick={() => handleModalOpen(2019)}>
                    개요보기
                  </a>
                </li>
                <li>
                  <img src={P2020} alt="2020년 포스터" />
                  <span className="year">2020</span>
                  <a className="le04" onClick={() => handleModalOpen(2020)}>
                    개요보기
                  </a>
                </li>
                <li>
                  <img src={P2021} alt="2021년 포스터" />
                  <span className="year">2021</span>
                  <a className="le05" onClick={() => handleModalOpen(2021)}>
                    개요보기
                  </a>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      {/* // modal */}
      {modal.open && (
        <LastEventModal year={modal.year} close={handleCloseModal} />
      )}
    </div>
  );
};

export default LastEvent;
