import React from "react";
import { Link } from "react-router-dom";
import MainLink1 from "../asset/img/main/btn_mlink01.png";
import MainLink2 from "../asset/img/main/btn_mlink02.png";
import MainLink3 from "../asset/img/main/btn_mlink03.png";
import MainLink4 from "../asset/img/main/btn_mlink04.png";

const MainSection3 = () => {
  return (
    <section className="section03">
      <div className="intro_area">
        {/* <span className="wow fadeInUp">SW인재페스티벌 안내</span> */}
        <ul style={{ marginTop: 0 }}>
          <li>
            <div className="img_area">
              <img src={MainLink1} alt="" />
            </div>
            <div className="text_area">
              <span>행사안내</span>
              <p>
                SW 전반적인 행사 안내 및 <br />
                참가대학 리스트 확인
              </p>
              <Link to="/introduce">자세히보기</Link>
            </div>
          </li>
          <li>
            <div className="img_area">
              <img src={MainLink2} alt="" />
            </div>
            <div className="text_area">
              <span>전시안내</span>
              <p>참여대학 부스 배치도 확인</p>
              <Link to="/booth">자세히보기</Link>
            </div>
          </li>
          <li>
            <div className="img_area">
              <img src={MainLink3} alt="" />
            </div>
            <div className="text_area">
              <span>관람안내</span>
              <p>
                입장권 할인, 관람 시간 등 <br />
                관람정보 확인
              </p>
              <Link to="/watchInfo">자세히보기</Link>
            </div>
          </li>
          <li>
            <div className="img_area">
              <img src={MainLink4} alt="" />
            </div>
            <div className="text_area">
              <span>프로그램</span>
              <p>
                SW선정대학의 우수 작품과 <br />
                강연 한 눈에 보기
              </p>
              <Link to="/ework">자세히보기</Link>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default MainSection3;
