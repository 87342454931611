import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import styled from "styled-components";
import {
  FlipBack,
  FlipBack_ver2,
  FlipForward,
  FlipForward2,
  FlipForward_ver2,
} from "../components/FlipMove";
import "../temp5.css";
import "../temp6.css";
// import { FlipForward } from "../components/FlipMove";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const PDF_URL =
  "https://s3.ap-northeast-2.amazonaws.com/mulsarang2021.archive/pdf01.pdf";

const Temp7 = () => {
  // settings react - pdf viewer element
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [loading, setLoading] = useState(true);

  const [active, setActive] = useState(false);
  const [backActive, setBackActive] = useState(false);
  const [change, setChange] = useState(false);
  const [imgSize, setImgSize] = useState({
    width: 400,
    height: 300,
  });

  const [loadingText, setLoadingText] = useState("SW 인재 페스티벌");
  const [loadingPercent, setLoadingPercent] = useState(0);

  // settings all pages index list
  const pageIndexList = new Array();

  const documentRef = useRef(null);

  // settings each pages ref for access DOM
  const ref1Page = useRef(null);
  const ref2Page = useRef(null);
  const ref3Page = useRef(null);
  const ref4Page = useRef(null);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handleRenderSuccess = (index) => {
    pageIndexList.push(index);
    if (pageIndexList.length === numPages) {
      console.log("all page render is success");
      setLoading(false);
      setImgSize({
        ...imgSize,
        width: ref1Page.current.width,
        height: ref1Page.current.height,
      });
      documentRef.current.height = ref1Page.current.height;
    }
  };

  const handleAssignCanvasRef = (canvasIndex) => {
    switch (canvasIndex) {
      case 1:
        return ref1Page;
      case 2:
        return ref2Page;
      case 3:
        return ref3Page;
      case 4:
        return ref4Page;
      default:
        return undefined;
    }
  };

  const handleAssignCanvasClass = (curPage, canvasIndex) => {
    switch (canvasIndex) {
      case curPage:
        return "pf_image right";
      // 현재 왼쪽 페이지
      case curPage - 1:
        return "pf_image left";
      case curPage + 2:
        return "pf_image right ready";
      default:
        return "pf_image back";
    }
  };

  const handleNextPage = () => {
    setPageNumber(pageNumber + 2);
    setChange(false);
    setTimeout(() => setChange(true), 100);
    setBackActive(true);
    setTimeout(() => setBackActive(false), 500);
  };

  const handlePrevPage = () => {
    setPageNumber(pageNumber - 2);
    setChange(false);
    setTimeout(() => setChange(true), 550);
    setActive(true);
    setTimeout(() => setActive(false), 1000);
  };

  const handleLoadingText = () => {
    return (
      <LoadingContent percent={loadingPercent}>
        2022 SW 인재 페스티벌
      </LoadingContent>
    );
  };

  const handleLoadingStatus = (loaded, total) => {
    setLoadingPercent((loaded / total).toFixed(2) * 100);
  };

  return (
    <>
      <div>
        <p> render {loading ? "true" : "false"}</p>
        <p> loading {loadingPercent}</p>
        <p> curPage {pageNumber}</p>
        <button onClick={handleNextPage}>+</button>
        <button onClick={handlePrevPage}>-</button>
      </div>

      <div className={"pf_wrap"}>
        {!loading && (
          <>
            <FlipForward_ver2
              backActive={backActive}
              change={change}
              page={pageNumber}
              refList={[ref1Page, ref2Page, ref3Page, ref4Page]}
              width={imgSize.width}
              height={imgSize.height}
            />
            <FlipBack_ver2
              active={active}
              change={change}
              page={pageNumber}
              refList={[ref1Page, ref2Page, ref3Page, ref4Page]}
              width={imgSize.width}
              height={imgSize.height}
            />
          </>
        )}

        <Document
          className={"pf_body"}
          file={PDF_URL}
          inputRef={documentRef}
          onLoadSuccess={handleDocumentLoadSuccess}
          loading={handleLoadingText}
          onLoadProgress={({ loaded, total }) =>
            handleLoadingStatus(loaded, total)
          }
          externalLinkTarget="_blank"
          externalLinkRel="_blank"
          options={{
            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
        >
          {Array.from(new Array(numPages), (el, index) => {
            return (
              <Page
                key={`page_${index + 1}`}
                width={400}
                height={imgSize.height}
                scale={pageScale}
                loading={handleLoadingText}
                canvasRef={handleAssignCanvasRef(index + 1)}
                className={handleAssignCanvasClass(pageNumber, index + 1)}
                onRenderSuccess={() => handleRenderSuccess(index + 1)}
                pageNumber={index + 1}
              />
            );
          })}
        </Document>
      </div>
    </>
  );
};

export default Temp7;

const LoadingContent = styled.span`
  font-size: 3em;
  font-weight: bold;
  color: #d9d9d9;
  position: relative;

  &::before {
    content: "2022 SW 인재 페스티벌";
    position: absolute;
    background: linear-gradient(
      90deg,
      rgba(3, 196, 183, 1) 41%,
      rgba(255, 0, 211, 0.41220238095238093) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    color: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(4, 193, 169, 1) 30%,
      rgba(0, 212, 255, 1) 100%
    );
    width: ${(props) => props.percent + "%"};
    overflow: hidden;
    white-space: nowrap;
  }
`;
