import React from "react";

import P2017 from "../../asset/img/sub/poster_2017.png";
import P2018 from "../../asset/img/sub/poster_2018.png";
import P2019 from "../../asset/img/sub/poster_2019.png";
import P2020 from "../../asset/img/sub/poster_2020.png";
import P2021 from "../../asset/img/sub/poster_2021.png";
import ModalClose from "../../asset/img/sub/btn_close.png";

const LastEventModal = ({ year, close }) => {
  const handleCloseModal = () => {
    close();
  };
  const renderModalBody = (year) => {
    switch (year) {
      case 2017:
        return (
          <>
            <div className="img_area">
              <img src={P2017} alt="2017 poster" />
            </div>
            <div className="text_area">
              <span className="year">2017</span>
              <span className="title">SW교육페스티벌_SW중심대학관</span>
              <p>일시 : 11.24 (금) - 25 (토)</p>
              <p>장소 : 킨텍스 1전시장 1홀</p>
              <p>주최 : 과학기술정보통신부</p>
              <p>주관 : 한국장학재단, 정보통신기획평가원, SW중심 대학협의회</p>
            </div>
          </>
        );
      case 2018:
        return (
          <>
            <div className="img_area">
              <img src={P2018} alt="2018 poster" />
            </div>
            <div className="text_area">
              <span className="year">2018</span>
              <span className="title">SW인재페스티벌</span>
              <p>일시 : 11.30 (금) - 12.01 (토)</p>
              <p>장소 : 킨텍스 1전시장 3홀</p>
              <p>주최 : 과학기술정보통신부</p>
              <p>
                주관 : 정보통신기획평가원, SW중심대학협의회, 한국정보산업연합회
              </p>
            </div>
          </>
        );
      case 2019:
        return (
          <>
            <div className="img_area">
              <img src={P2019} alt="2019 poster" />
            </div>
            <div className="text_area">
              <span className="year">2019</span>
              <span className="title">SW인재페스티벌</span>
              <p>일시 : 12.04 (수) - 12.05 (목)</p>
              <p>장소 : 세종대 광개토관 컨벤션홀</p>
              <p>주최 : 과학기술정보통신부</p>
              <p>
                주관 : 정보통신기획평가원, SW중심대학협의회, 한국정보산업연합회
              </p>
            </div>
          </>
        );
      case 2020:
        return (
          <>
            <div className="img_area">
              <img src={P2020} alt="2020 poster" />
            </div>
            <div className="text_area">
              <span className="year">2020</span>
              <span className="title">SW인재페스티벌</span>
              <p>일시 : 12.03 (목) - 12.04 (금)</p>
              <p>장소 : 온라인 + 세종대</p>
              <p>주최 : 과학기술정보통신부</p>
              <p>주관 : 정보통신기획평가원, SW중심대학협의회</p>
            </div>
          </>
        );
      case 2021:
        return (
          <>
            <div className="img_area">
              <img src={P2021} alt="2021 poster" />
            </div>
            <div className="text_area">
              <span className="year">2021</span>
              <span className="title">SW인재페스티벌</span>
              <p>일시 : 12.02 (목) - 12.03 (금)</p>
              <p>장소 : 세빛섬 및 온라인 전시</p>
              <p>주최 : 과학기술정보통신부</p>
              <p>주관 : 정보통신기획평가원, SW중심대학협의회</p>
            </div>
          </>
        );
      default:
        return;
    }
  };

  return (
    <div className="popup" style={{ display: "flex" }}>
      <div className="modal-bg" onClick={handleCloseModal}></div>
      <div className="modal-cont le">
        <div className="event_detail">{renderModalBody(year)}</div>
        <a className="close close1" onClick={handleCloseModal}>
          <img src={ModalClose} alt="닫기" />
        </a>
      </div>
    </div>
  );
};

export default LastEventModal;
