import React from "react";

export const SubContainer = ({ children }) => {
  return (
    <div className={"wrap main"}>
      <div className={"wrap main"}>
        <div className={"container sub"}>{children}</div>
      </div>
    </div>
  );
};
