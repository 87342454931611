import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainMore from "../asset/img/main/btn_more.png";
import { parseDateFormat } from "../utils/DateFormat";

const MainSection5 = () => {
  const [list, setList] = useState({
    loading: true,
    data: [],
  });

  useEffect(() => {
    const getNoticeTop3 = async () => {
      const res = await axios.get(
        `https://admin.swfestival2022.kr/board/notice/top3`
      );
      // const res = await axios.get(`http://localhost:8080/board/notice/top3`);
      if (res.status === 200) {
        setList({ loading: false, data: [...res.data] });
      } else if (res.status === 204) {
        setList({ loading: false, data: [] });
      }
    };
    getNoticeTop3();
  }, []);
  // 공지사항 api 조회 ( top 3 )

  return (
    <section className="section05">
      <div className="board_area">
        <span>
          공지사항
          <br />
          <small>페스티벌 공지사항 확인하기</small>
        </span>
        <Link to="/notice" className="more_btn">
          <img src={MainMore} />
        </Link>
        <ul className="notice_list">
          {!list.loading && list.data.length > 0 ? (
            list.data.map((item, index) => (
              <li key={`n_list_${index}`}>
                <Link to={`/notice/view/${item.id}`}>
                  <span>{item.title}</span>
                  <p>{parseDateFormat(item.createdAt)}</p>
                </Link>
              </li>
            ))
          ) : (
            <li>
              <a>
                <span>등록된 공지사항이 존재하지 않습니다.</span>
                <p></p>
              </a>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};

export default MainSection5;
