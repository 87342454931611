import React from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import {
  UnivSection1,
  UnivSection2,
  UnivSection3,
  UnivSection4,
} from "../../components/univ/IntroSections";
import UnivTabs from "../../components/UnivTabs";

const UnivIntro = () => {
  return (
    <div className="wrap main">
      <div className={"wrap main"}>
        <div className={"container sub"}>
          <BoardSubVisual />
          <UnivTabs current="intro" />
          <div className="sub_wrap introduce univ">
            <UnivSection1 />
            <UnivSection2 />
            <UnivSection3 />
            <UnivSection4 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnivIntro;
