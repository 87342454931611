import React from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import ProgramTabs from "../../components/ProgramTags";
import { InfoSection4 } from "../../components/info/InfoSection";

const Award = () => {
  return (
    <div className={"wrap main"}>
      <div className={"wrap main"}>
        <div className={"container sub"}>
          <BoardSubVisual />
          <ProgramTabs current="award" />
          <div className="sub_wrap introduce">
            <div className="intro_content">
              <div className="intro_section">
                <InfoSection4 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Award;
