import React from "react";
import { Link } from "react-router-dom";

const BoardTabs = ({ current }) => {
  return (
    <div className="sub_nav depth3">
      <ul>
        <li className={current === "notice" ? "active" : ""}>
          <Link to="/notice">공지사항</Link>
        </li>
        <li className={current === "event" ? "active" : ""}>
          <Link to="/eventList">이벤트</Link>
        </li>
        <li className={current === "faq" ? "active" : ""}>
          <Link to="/faq">자주묻는 질문</Link>
        </li>
      </ul>
    </div>
  );
};

export default BoardTabs;
