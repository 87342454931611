import React from "react";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";

export const VoteInfo = () => {
  const [account] = useRecoilState(accountSelect);
  return (
    <>
      <span
        className="page_title"
        style={{ marginLeft: 0, marginRight: 0, textAlign: "left" }}
      >
        우수작품 · 대학소개
      </span>
      <div className="guide_area">
        <ul>
          <li>
            <span className="guide_title">인기상 투표 안내</span>
          </li>
          <li>
            <span>내&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;용</span>
            <p>온라인 투표를 통해 인기상 시상</p>
          </li>
          <li>
            <span>시상 규모</span>
            <p>최다 득표 2개 대학(수상팀 제외)</p>
          </li>
          <li>
            <span>투표 방법</span>
            <p style={{ color: "#061e48", fontWeight: "600" }}>
              ① 회원가입 및 로그인
              <br />
              ② 각 대학 '우수작품' 클릭
              <br />③ 인기상 '투표하기' 클릭
              {/* (여러작품에 투표 가능 / 단, 같은 작품에 중복 투표 불가) */}
            </p>
          </li>
          <li>
            <span>투표 개수</span>
            <p style={{ color: "#061e48", fontWeight: "600" }}>
              1인당 2표, 같은 작품에 중복투표 불가
            </p>
          </li>
          <li>
            <span>투표 시간</span>
            <p style={{ color: "#061e48", fontWeight: "600" }}>
              22.12.8.(목), 오전10시 ~ 22.12.9.(금), 오후2시
            </p>
          </li>
          <li>
            <span>결과 발표</span>
            <p>22.12.9.(금), 16:30~ / 폐막식</p>
          </li>
        </ul>
        <div className="counting_area">
          {account && account.accessToken ? (
            account.voteList.length > 0 ? (
              <>
                <span>{2 - account.voteList.length}</span>
                <p>(남은 투표수)</p>
              </>
            ) : (
              <>
                <span>2</span>
                <p>(남은 투표수)</p>
              </>
            )
          ) : (
            <>
              <span>2</span>
              <p>(남은 투표수)</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
