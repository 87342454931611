import React from "react";
import BoardSubVisual from "../../components/BoardSubVisual";
import Info2Tabs from "../../components/Info2Tabs";
import LogoMap from "../../asset/img/sub/logo_map.png";
import LogoImg from "../../asset/img/sub/img_map.png";
import Bus from "../../asset/img/sub/ico_bus.png";
import Metro from "../../asset/img/sub/ico_metro.png";

const WatchInfo = () => {
  return (
    <div className="wrap main">
      <div className={"wrap exhibition"}>
        <div className={"container sub"}>
          <BoardSubVisual />
          <Info2Tabs current={"info"} />
          <div className="sub_wrap ew_list work">
            <span className="page_title">관람안내</span>
            <div className={"guide_area watch"}>
              <ul>
                <li>
                  <span>사전등록</span>
                  <p>~ 2022년 12월 7일(수), 18시</p>
                  <p style={{ marginLeft: "7.5em" }}>
                    <small>
                      * 사전등록 이후에도 온라인 등록은 가능합니다. (사전등록
                      이벤트는 안내 시간 내 등록한 인원만 참여가능)
                    </small>
                  </p>
                </li>
                <li>
                  <span>현장등록</span>
                  <p>
                    · 아트홀1관 입구 {"<"}등록QR코드 스캔 및 정보 입력{">"}
                  </p>
                  <p style={{ marginLeft: "7.5em" }}>
                    · 등록대 {"<"}손목띠{">"} 수령
                  </p>
                  <p style={{ marginLeft: "7.5em" }}>· (입장) 바코드 태그</p>
                  <p style={{ marginLeft: "7.5em" }}>· (퇴장) 바코드 태그</p>
                </li>
                <li>
                  <span>운영시간</span>
                  <p>· 12/8(목), 10:00 ~ 17:30 (입장마감 : 17:00)</p>
                  <p style={{ marginLeft: "7.5em" }}>
                    · 12/9(금), 10:00 ~ 17:00 (입장마감 : 16:30)
                  </p>
                </li>
              </ul>
            </div>
            <a className="page_title" style={{ cursor: "pointer" }}>
              오시는 길
            </a>
            <div className="watch_map">
              <div className="map_info">
                <div className="info">
                  <ul>
                    <li>
                      <span>대표전화</span>
                      <p>02 3453 8922</p>
                    </li>
                    <li>
                      <span>팩스</span>
                      <p>02 3453 8922</p>
                    </li>
                    <li>
                      <span>행사장소</span>
                      <p>
                        서울시 중구 을지로 281(을지로7가 2-1) 동대문디자인플라자
                      </p>
                    </li>
                    <li>
                      <a
                        style={{ marginLeft: "7em", color: "#a0a0a0" }}
                        href={"https://ddp.or.kr/?menuno=235"}
                        target="_blank"
                      >
                        <p style={{ cursor: "pointer" }}>
                          <small style={{ display: "block" }}>
                            ※ DDP 상세안내 바로가기 (클릭)
                          </small>
                        </p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="directions">
                <ul>
                  <li>
                    <div className="tit">
                      <img src={Bus} />
                      <span>버스</span>
                    </div>
                    <div className="text">
                      <span>
                        동대문역사문화공원(02-173, 02-174) 정류장 하차
                      </span>
                      <p>
                        간선: 105, 144, 152, 261, 301, 420, N13, N16, N30, N62
                      </p>
                      <p>지선: 2012, 2015, 2233, 7212</p>
                      <p>광역: 9403</p>
                    </div>
                  </li>
                  <li>
                    <div className="tit">
                      <img src={Metro} />
                      <span>지하철</span>
                    </div>
                    <div className="text">
                      <span>
                        지하철 2∙4∙5호선 동대문역사문화공원역 1∙2번 출구
                      </span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="map">
                <img src={LogoImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchInfo;
