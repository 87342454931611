import { css, keyframes } from "styled-components";

// handle prevPage
export const MainFlip_prevPage = keyframes`
0%,
    20% {
      top:-11px;
      transform: rotateX(10deg) rotateY(6deg);
    }
    80%,
    100% {
      top:-11px;
      transform: rotateX(10deg) rotateY(174deg);
    }
`;

export const OtherFlip_prevPage = keyframes`
0%,
    20% {
      transform: rotateY(0deg) translateY(0px);
    }
    50% {
      transform: rotateY(-15deg) translateY(0px);
    }
`;

export const MainFlip_after_prevPage = (backImg) => keyframes`
${[0, 1, 2, 3, 4].map(
  (value, index) => css`
    ${value * 20}% {
      /* 가변영역 */
      /* background-image;
        background-position; */
      background: white;

      /* background-position: -178px -2px; */
      transform: rotateY(0deg);
    }
    ${10 + value * 20}% {
      /* 가변영역 */
      /* background-image;
        background-position; */
      background-image: URL(${backImg});
      background-position: -2px;
      /* background-position: 67px; */
      /* background-position: -178px -2px; */
      transform: rotateY(180deg);
    }
  `
)}
`;

export const OtherFlip_after_prevPage = (
  curIndex,

  backImg
) => keyframes`
${[0, 1, 2, 3, 4].map(
  (value, index) => css`
    ${value * 20}% {
      /* background-image: nth($images, ($j + 1));
    
        
      background-position: ${-534 + (curIndex - 1) * 106}px; */
      background: white;
      transform: rotateY(0deg);
    }
    ${11 + value * 20 + (index - 1) * 0.5}% {
      background-image: URL(${backImg});
      background-position: ${(curIndex - 1) * -106}px;

      /* background-image: nth($images, ($j + 2));
        background-position: #{-238 - (($i - 2) * 30)}px -2px; */
      transform: rotateY(180deg);
    }
  `
)}
`;

export const LastFlip_after_prevPage = (backImg) => keyframes`
${[0, 1, 2, 3, 4].map(
  (value, index) => css`
    ${value * 20}% {
      background: white;

      transform: rotateY(0deg);
    }
    ${13 + value * 20}% {
      background-image: URL(${backImg});
      background-position: -740px;
      transform: rotateY(180deg);
    }
  `
)}
`;

// handle nextPage
export const MainFlip_nextPage = keyframes`
0%,
    20% {
      top:-11px;
      transform: rotateX(10deg) rotateY(-6deg);
    }
    80%,
    100% {
      top:-11px;
      transform: rotateX(10deg) rotateY(-174deg);
    }
`;

export const OtherFlip_nextPage = keyframes`
0%,
    20% {
      transform: rotateY(0deg) translateY(0px);
    }
    50% {
      transform: rotateY(15deg) translateY(0px);
    }
`;

export const MainFlip_after_nextPage = (curImg, backImg) => keyframes`
${[0, 1, 2, 3, 4].map(
  (value, index) => css`
    ${value * 20}% {
      /* 가변영역 */
      /* background-image;
        background-position; */
      background-image: URL(${curImg});
      background-position: -2px;
      /* background-position: -178px -2px; */
      transform: rotateY(0deg);
    }
    ${10 + value * 20}% {
      /* 가변영역 */
      /* background-image;
        background-position; */

      background: white;

      /* background-position: 67px; */
      /* background-position: -178px -2px; */
      transform: rotateY(180deg);
    }
  `
)}
`;

export const OtherFlip_after_nextPage = (
  curIndex,
  curImg,
  backImg
) => keyframes`
${[0, 1, 2, 3, 4].map(
  (value, index) => css`
    ${value * 20}% {
      /* background-image: nth($images, ($j + 1));
    
        background-position: #{-148 + (($i - 2) * 30)}px -2px; */
      background-image: URL(${curImg});
      background-position: ${(curIndex - 1) * -106}px;
      transform: rotateY(0deg);
    }
    ${11 + value * 20 + (index - 1) * 0.5}% {
      background: white;
      /* background-image: nth($images, ($j + 2));
        background-position: #{-238 - (($i - 2) * 30)}px -2px; */
      transform: rotateY(180deg);
    }
  `
)}
`;

export const LastFlip_after_nextPage = (curImg, backImg) => keyframes`
${[0, 1, 2, 3, 4].map(
  (value, index) => css`
    ${value * 20}% {
      background-image: URL(${curImg});
      background-position: -740px;
      transform: rotateY(0deg);
    }
    ${13 + value * 20}% {
      background-image: URL(${backImg});
      background-position: -800px;
      transform: rotateY(180deg);
    }
  `
)}
`;
