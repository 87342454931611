import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import TempPdf from "../asset/pdf/pdf_ew01.pdf";
import { createHeightAndBlob } from "../utils/ImgBlob";
import { EbookPager } from "./TotalEbookStyle";
import FlipStyle from "../styles/flip/FlipStyle.module.scss";
import { useRecoilState } from "recoil";
import { ebookSelect } from "../recoil/AtomConfig";
import FlipMoveTotal from "./FlipMoveTotal";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const TotalEbookElement = ({
  init,
  initImgSize,
  next,
  curInit,
  prev,
  nextActive,
  prevActive,
}) => {
  const [ebook, setEbook] = useRecoilState(ebookSelect);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [pageInit, setPageInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [imgSize, setImgSize] = useState({
    width: 800,
    height: 1061,
  });

  const [rImgs, setRImgs] = useState([]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const finalLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderSuccess = (page, index) => {
    // console.log(page, index);
    // console.log(rImgs);
    // const template = { page: 0 };
    const tempList = [];
    switch (index) {
      case 1:
        const p1BlobData = createHeightAndBlob(page, ref1);
        if (rImgs.filter((f) => f.page === p1BlobData.page).length === 0) {
          tempList.push(p1BlobData);
          //   console.log(p1BlobData);
        }
        break;
      case 2:
        const p2BlobData = createHeightAndBlob(page, ref2);
        if (rImgs.filter((f) => f.page === p2BlobData.page).length === 0) {
          tempList.push(p2BlobData);
          //   console.log(p2BlobData);
        }
        break;
      case 3:
        const p3lobData = createHeightAndBlob(page, ref3);
        if (rImgs.filter((f) => f.page === p3lobData.page).length === 0) {
          tempList.push(p3lobData);
          //   console.log(p3lobData);
        }

        break;
    }
    // console.log(tempList);
    setRImgs([...rImgs, ...tempList]);
    setPageInit(false);
  };

  const setClassName = (index, curPage) => {
    switch (index) {
      case 1:
        return `${FlipStyle.pdfPage} ${FlipStyle.page}`;
        break;
      case 2:
        return `${FlipStyle.pdfPage} ${FlipStyle.page} ${FlipStyle.current} `;
        break;
      case 3:
        return `${FlipStyle.pdfPage} ${FlipStyle.page}`;
        break;
    }
  };

  useEffect(() => {
    if (curInit && rImgs.length > 0) {
      console.log("sdfsdfsdf");
      console.log(rImgs);
      initImgSize({ width: imgSize.width, height: rImgs[0].height });
      init(false);
      //   setEbook((prev) => {
      //     const temp = { ...prev };
      //     temp.list = [...rImgs];
      //     return { ...temp };
      //   });
    }
    // if (!curInit) {
    //   setEbook((prev) => {
    //     const temp = { ...prev };
    //     temp.list = [...rImgs];
    //     return { ...temp };
    //   });
    // }

    // refChange([...rImgs]);
  }, [rImgs]);

  //   const handleNext = () => {
  //     const newPage = pageNumber + 1;
  //     setPageNumber(newPage);

  //     // pageChange(newPage);
  //   };
  //   const handlePrev = () => {
  //     const newPage = pageNumber - 1;
  //     setPageNumber(newPage);
  //     // pageChange(newPage);
  //   };
  useEffect(() => {
    if (next) {
      if (pageNumber + 1 <= numPages) {
        setPageNumber(pageNumber + 1);
      }
    }
  }, [next]);

  useEffect(() => {
    if (prev) {
      if (pageNumber - 1 > 0) {
        setPageNumber(pageNumber - 1);
      }
    }
  }, [prev]);

  //   console.log(ebook);

  return (
    <>
      {/* {!curInit && ebook.list.length > 0 && (
        <div
          className={"step2"}
          style={{
            position: "absolute",
            width: "800px",
            height: `${imgSize.height}px`,
            visibility: "visible",
            zIndex: `${next || prev ? 9999 : 10}`,
          }}
        >
          <FlipMoveTotal
            pageN={pageNumber}
            active={next}
            backActive={prev}
            imgSize={imgSize}
            refList={ebook.list}
            // refList={refList}
          />
        </div>
      )} */}
      <EbookPager>
        <button type="button" onClick={prevActive}>
          prev
        </button>
        <button type="button" onClick={nextActive}>
          next
        </button>
      </EbookPager>
      <div className={FlipStyle.pdfDocumentWrap}>
        <Document
          className={FlipStyle.pdfDocumentBody}
          file={TempPdf}
          onLoadSuccess={finalLoadSuccess}
          options={{
            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
        >
          {/* {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`totalEbook_${index}`}
              className={setClassName(1, pageNumber)}
              width={imgSize.width}
              scale={pageScale}
              canvasRef={(ref) => ref && console.log(ref)}
              pageNumber={index + 1}
              //   onRenderSuccess={() => console.log("renderSuccess", index + 1)}
            />
          ))} */}
          {pageNumber - 1 > 0 && (
            <Page
              className={setClassName(1, pageNumber)}
              width={imgSize.width}
              scale={pageScale}
              pageNumber={pageNumber - 1}
              canvasRef={ref1}
              onRenderSuccess={() => renderSuccess(pageNumber - 1, 1)}
            />
          )}
          <Page
            className={setClassName(2, pageNumber)}
            width={imgSize.width}
            scale={pageScale}
            pageNumber={pageNumber}
            canvasRef={ref2}
            onRenderSuccess={() => renderSuccess(pageNumber, 2)}
          />
          {pageNumber !== numPages && (
            <Page
              className={setClassName(3, pageNumber)}
              width={imgSize.width}
              scale={pageScale}
              pageNumber={pageNumber + 1}
              canvasRef={ref3}
              onRenderSuccess={() => renderSuccess(pageNumber + 1, 3)}
            />
          )}
        </Document>
      </div>
    </>
  );
};

export default TotalEbookElement;
