import "./App.css";
import { Link, Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import Header from "./containers/layout/Header";
import Temp7 from "./containers/Temp7";
import Temp9 from "./containers/Temp9";
import TestPage from "./containers/TestPage";
import Notice from "./containers/board/Notice";
import Event from "./containers/board/Event";
import Faq from "./containers/board/Faq";
import Ework from "./containers/program/Ework";
import NoticeDetail from "./containers/board/NoticeDetail";

import { RecoilRoot } from "recoil";
import Login from "./containers/login/Login";
import Signup from "./containers/login/Signup";
import Introduce from "./containers/info/Introduce";
import Special from "./containers/program/Special";
import LastEvent from "./containers/info/Last";
import Concert from "./containers/program/Concert";
import UnivCurrent from "./containers/univ/UnivCurrent";
import UnivIntro from "./containers/univ/UnivIntro";
import Lecture from "./containers/program/Lecture";
import UnivList from "./containers/info/UnivList";
import HeaderPrev from "./containers/layout/HeaderPrev";
import PrevIndex from "./containers/prev";
import Booth from "./containers/info2/booth";
import WatchInfo from "./containers/info2/WatchInfo";
import Regist from "./containers/info2/Regist";
import { Helmet } from "react-helmet";
import { MenuOpen } from "./utils/DateLimit";
import EventList from "./containers/board/EventList";
import PrevRegist from "./containers/prev/PrevRegist";
import Award from "./containers/program/Award";
import F_icon from "./asset/img/common/favicon.ico";
import F_img from "./asset/img/common/favicon.png";
import FindPWd from "./containers/login/FindPWD";
import Confirm from "./containers/Confirm";

function App() {
  const isOpen = MenuOpen();
  console.log(isOpen);
  return (
    <>
      <Helmet>
        <title>SW인재페스티벌 2022</title>
        <meta name="description" content="" />
        <meta name="Keywords" content="SW인재페스티벌 2022" />
        <meta
          http-equiv="Copyright"
          content="사단법인 소프트웨어중심대학협의회"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="robots" content="index,follow" />
        <meta name="format-detection" content="telephone=no" />

        <meta property="og:title" content="SW인재페스티벌 2022" />
        <meta property="og:description" content="SW인재페스티벌 2022" />
        <meta property="og:type" content="website" />
        {/* <meta property="og:image" content="asset/img/common/share_img.jpg" /> */}
        <meta property="og:url" content="" />
        <link rel="shortcut icon" href={F_icon} type="image/x-icon" />
        <link rel="icon" href={F_img} type="image/x-icon" />
      </Helmet>
      <RecoilRoot>
        <Routes>
          {/* <Route element={<HeaderPrev />}>
            <Route path="/" element={<PrevIndex />} />
            <Route path="/prevRegist" element={<PrevRegist />} />
          </Route> */}
          {/* {!isOpen && (
            
          )} */}

          <Route element={<Header />}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            {/* <Route path={`${!isOpen ? "/home" : "/"}`} element={<Home />} />
            {isOpen && <Route path="/home" element={<Home />} />} */}
            <Route path="/confirm" element={<Confirm />} />

            <Route path="/temp7" element={<Temp7 />} />

            <Route path="/temp9" element={<Temp9 />} />
            <Route path="/testPage" element={<TestPage />} />
            {/* info */}
            <Route path="/introduce" element={<Introduce />} />
            <Route path="/last" element={<LastEvent />} />
            <Route path="/univList" element={<UnivList />} />

            {/* info2 */}

            <Route path="/watchInfo" element={<WatchInfo />} />
            <Route path="/regist" element={<Regist />} />
            <Route path="/booth" element={<Booth />} />
            {/* univ */}
            <Route path="/univIntro" element={<UnivIntro />} />
            <Route path="/univCurrent" element={<UnivCurrent />} />
            {/* program */}
            <Route path="/ework" element={<Ework />} />
            <Route path="/special" element={<Special />} />
            <Route path="/concert" element={<Concert />} />
            <Route path="/lecture" element={<Lecture />} />
            <Route path="/award" element={<Award />} />
            {/* login */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/findpwd" element={<FindPWd />} />

            {/* board */}
            <Route exact path="/notice" element={<Notice />} />
            <Route exact path="/notice/view/:id" element={<NoticeDetail />} />

            <Route path="/eventList" element={<EventList />} />
            <Route path="/event/:type" element={<Event />} />
            <Route path="/faq" element={<Faq />} />
          </Route>
        </Routes>
      </RecoilRoot>
    </>
  );
}

export default App;
