import React from "react";
import Univ1 from "../../asset/img/sub/btn_univ01.png";
import Univ2 from "../../asset/img/sub/btn_univ02.png";
import Univ3 from "../../asset/img/sub/btn_univ03.png";
import Univ4 from "../../asset/img/sub/btn_univ04.png";
import Univ5 from "../../asset/img/sub/btn_univ05.png";
import Ul1 from "../../asset/img/sub/img_uIntro01.png";
import Ul2 from "../../asset/img/sub/img_uIntro02.png";
import Icon01 from "../../asset/img/sub/ico_uIntro01.png";
import Icon02 from "../../asset/img/sub/ico_uIntro02.png";
import Icon03 from "../../asset/img/sub/ico_uIntro03.png";
import Icon04 from "../../asset/img/sub/ico_uIntro04.png";
import Icon05 from "../../asset/img/sub/ico_uIntro05.png";

export const UnivSection1 = () => {
  return (
    <div className="intro_top univ">
      <div className="text_area">
        <span>
          SW 중심대학을 소개합니다
          <small>
            시대가 요구하고 사회가 요구하며 디지털 세대가 요구하는 소프트웨어
            교육의 필연성
          </small>
        </span>
        <p>
          SW중심대학은 대학교육을 소프트웨어 중심으로 혁신함으로써
          <br />
          소프트웨어 교육 패러다임과 시스템의 대전환을 통해
          <br />
          소프트웨어 인재양성과 가치확산을 실현 합니다
        </p>
      </div>
      <ul>
        <li>
          <img className="off" src={Univ1} alt="1 img" />
        </li>
        <li>
          <img className="off" src={Univ2} alt="2 img" />
        </li>
        <li>
          <img className="off" src={Univ3} alt="3 img" />
        </li>
        <li>
          <img className="off" src={Univ4} alt="4 img" />
        </li>
        <li>
          <img className="off" src={Univ5} alt="5 img" />
        </li>
      </ul>
    </div>
  );
};

export const UnivSection2 = () => {
  return (
    <div className="intro_content univ">
      <div className="intro_section">
        <div className="is_list list2">
          <div className="text_area">
            <span>사업추진배경</span>
            <p>
              <b>
                4차 산업을 선도하는 SW전공융합 인재 양성체계를 구축하고
                현장중심의 문제해결능력을 갖춘 전문교육강화
              </b>
              (SW중심대학) 대학교육을 SW산업계 수요에 맞게 혁신함으로써,
              학생기업사회의 SW경쟁력을 강화하고 진정한 SW가치확산을 실현하는
              대학
            </p>
          </div>
          <div className="text_area">
            <span>사업추진경과 및 대학 선정/종료 현황</span>
            <img className="history" src={Ul1} alt="1 history" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const UnivSection3 = () => {
  return (
    <div className="intro_top univ">
      <div className="text_area">
        <span>
          SW 중심대학 주요내용
          <small> &nbsp; </small>
        </span>
      </div>
      <img className="introduce" src={Ul2} alt="2 history" />
    </div>
  );
};

export const UnivSection4 = () => {
  return (
    <div className="intro_content univ scd">
      <ul>
        <li>
          <div className="title_area">
            <img src={Icon01} alt="" />
            <span>
              SW를 잘 가르치는
              <br />
              혁신적인 교육체계 구축
            </span>
          </div>
          <div className="text_area">
            <p>
              우수한 교육역량과 실무경험을 갖춘 강의전담 교수 및 산학협력
              중점교수의 참여 확대(5인 이상)
            </p>
            <p>
              학과/대학 신설 및 타 융합 전공 등의 트랙 개발을 통해 새로운
              교육체계 구축
            </p>
            <p>기업이 참여한 교과과정 개발·운영을 통한 졸업 후 취업 연계</p>
            <p>
              공학교육인증 및 ACM·IFFE 권장 커리큘럼 반영, 혁신적 학습방법 도입,
              오픈 소스 SW 및 영어 교육 강화
            </p>
          </div>
        </li>
        <li>
          <div className="title_area">
            <img src={Icon02} alt="" />
            <span>
              비전공자 대상 SW교육 및 <br />
              융합교육 활성화
            </span>
          </div>
          <div className="text_area">
            <p>SW기초교육 과정을 개설(학점 '선 이수제', '선택교과')</p>
            <p>
              심화과정 희망 비전공자에게, 대학별 특화되어 있는
              SW융합교육과정(연계 또는 복수전공)에 참여기회 제공
              <br />
              (예: 빅데이터 분석, 관광/경영/의료+SW융합과정 등)
            </p>
          </div>
        </li>
        <li>
          <div className="title_area">
            <img src={Icon03} alt="" />
            <span>
              실효적 산학협력
              <br />
              프로그램 운영
            </span>
          </div>
          <div className="text_area">
            <p>
              기업의 현장 애로사항 해결을 위한 '문제해결형 산학협력 프로젝트'
              추진
            </p>
            <p>장기 인턴십 및 해외 교육과정 운영</p>
          </div>
        </li>
        <li>
          <div className="title_area">
            <img src={Icon04} alt="" />
            <span>
              SW특성을 반영한 제도개선 및 <br />
              SW가치확산 지원
            </span>
          </div>
          <div className="text_area">
            <p>
              수시전형 내 SW우수자 전형 신설 등, 별도 선발기준 마련, SW분야
              특성을 반영한 교원평가 개선
              <br />
              (예: SCI 실적 축소/폐지)
            </p>
            <p>
              SW선도학교 등 인근 초·중등학교와 연계한 일일 특강 및 청소년 SW캠프
              등 개최
            </p>
          </div>
        </li>
        <li>
          <div className="title_area">
            <img src={Icon05} alt="" />
            <span>
              SW중심대학 간 <br />
              협력강화
            </span>
          </div>
          <div className="text_area">
            <p>SW중심대학협의회를 구성·운영하여 SW중심대학 간 협력 강화</p>
            <p>
              대학 별로 운영 중인 해외 인턴십·교육 프로그램의 공동 활용 및
              정보통신기획평가원 지원 프로그램과 연계하여 통합운영
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};
