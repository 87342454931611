import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Flip2Sec,
  FlipNextBody,
  FlipNextPageWrap,
  FlipPrev,
} from "../styles/FilpDesktop";
import {
  FlipBodyMobile,
  FlipSecMobile_ver2,
  FlipSecMobile_ver2_reverse,
  FlipWrapMobile,
} from "../styles/FlipMobile";
import { createHeightAndBlob } from "../utils/ImgBlob";

export const FlipBack = ({ active, change, nImgPath, width, height }) => {
  return (
    <div className={"tempArea2"}>
      <div className={"flipBody"}>
        <div
          className={`flips sec1 ${active ? "active" : ""} ${
            change ? "change" : ""
          }`}
          style={{ height: height }}
        >
          <FlipImgBack
            change={change}
            height={height}
            width={width}
            cropIndex={1}
            src={nImgPath}
            style={{ height: "100%" }}
            className={"backgroundImg imgCrop1" + (change ? " change" : "")}
          />
          <div
            className={`flips sec2 ${active ? "active" : ""} ${
              change ? "change" : ""
            }`}
            style={{ height: height }}
          >
            <FlipImgBack
              change={change}
              height={height}
              width={width}
              cropIndex={2}
              src={nImgPath}
              style={{ height: "100%" }}
              className={"backgroundImg imgCrop2" + (change ? " change" : "")}
            />
            <div
              className={`flips sec3 ${active ? "active" : ""} ${
                change ? "change" : ""
              }`}
              style={{ height: height }}
            >
              <FlipImgBack
                change={change}
                height={height}
                width={width}
                cropIndex={3}
                src={nImgPath}
                style={{ height: "100%" }}
                className={"backgroundImg imgCrop3" + (change ? " change" : "")}
              />
              <div
                className={`flips sec4 ${active ? "active" : ""} ${
                  change ? "change" : ""
                }`}
                style={{ height: height }}
              >
                <FlipImgBack
                  change={change}
                  height={height}
                  width={width}
                  cropIndex={4}
                  src={nImgPath}
                  style={{ height: "100%", width: "400px" }}
                  className={
                    "backgroundImg imgCrop4" + (change ? " change" : "")
                  }
                />
                <div
                  className={`flips sec5 ${active ? "active" : ""} ${
                    change ? "change" : ""
                  }`}
                  style={{ height: height }}
                >
                  <FlipImgBack
                    change={change}
                    height={height}
                    width={width}
                    cropIndex={5}
                    src={nImgPath}
                    style={{ height: "100%" }}
                    className={
                      "backgroundImg imgCrop5" + (change ? " change" : "")
                    }
                  />
                  <div
                    className={`flips sec6 ${active ? "active" : ""} ${
                      change ? "change" : ""
                    }`}
                    style={{ height: height }}
                  >
                    <FlipImgBack
                      change={change}
                      height={height}
                      width={width}
                      cropIndex={6}
                      src={nImgPath}
                      style={{ height: "100%" }}
                      className={
                        "backgroundImg imgCrop6" + (change ? " change" : "")
                      }
                    />
                    <div
                      className={`flips sec7 ${active ? "active" : ""} ${
                        change ? "change" : ""
                      }`}
                      style={{ height: height }}
                    >
                      <FlipImgBack
                        change={change}
                        height={height}
                        width={width}
                        cropIndex={7}
                        src={nImgPath}
                        style={{ height: "100%" }}
                        className={
                          "backgroundImg imgCrop7" + (change ? " change" : "")
                        }
                      />
                      <div
                        className={`flips sec8 ${active ? "active" : ""} ${
                          change ? "change" : ""
                        }`}
                        style={{ height: height }}
                      >
                        <FlipImgBack
                          change={change}
                          height={height}
                          width={width}
                          cropIndex={8}
                          src={nImgPath}
                          style={{ height: "100%" }}
                          className={
                            "backgroundImg imgCrop8" + (change ? " change" : "")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FlipForward2 = ({
  backActive,
  change,
  curImg,
  curBackImg,
  width,
  height,
}) => {
  return (
    <>
      <FlipWrap
        change={change}
        className={"tempArea3"}
        style={{ top: "400px", visibility: "visible" }}
      >
        <div className={"flipBody2"}>
          <div
            className={`flips2 sec1 ${backActive ? "backActive" : ""}`}
            style={{ height: height }}
          >
            <FlipCurImg
              change={change}
              height={height}
              width={width}
              cropIndex={1}
              src={curImg}
              style={{ height: "100%" }}
              className={"backgroundImg imgCrop1" + (change ? " change" : "")}
            />
            <div
              className={`flips2 sec2 ${backActive ? "backActive" : ""}`}
              style={{ height: height }}
            >
              <FlipCurImg
                change={change}
                height={height}
                width={width}
                cropIndex={2}
                src={curImg}
                style={{ height: "100%" }}
                className={"backgroundImg imgCrop2" + (change ? " change" : "")}
              />
              <div
                className={`flips2 sec3 ${backActive ? "backActive" : ""}`}
                style={{ height: height }}
              >
                <FlipCurImg
                  change={change}
                  height={height}
                  width={width}
                  cropIndex={3}
                  src={curImg}
                  style={{ height: "100%" }}
                  className={
                    "backgroundImg imgCrop3" + (change ? " change" : "")
                  }
                />

                <div
                  className={`flips2 sec4 ${backActive ? "backActive" : ""}`}
                  style={{ height: height }}
                >
                  <FlipCurImg
                    change={change}
                    height={height}
                    width={width}
                    cropIndex={4}
                    src={curImg}
                    style={{ height: "100%", width: "400px" }}
                    className={
                      "backgroundImg imgCrop4" + (change ? " change" : "")
                    }
                  />
                  <div
                    className={`flips2 sec5 ${backActive ? "backActive" : ""}`}
                    style={{ height: height }}
                  >
                    <FlipCurImg
                      change={change}
                      height={height}
                      width={width}
                      cropIndex={5}
                      src={curImg}
                      style={{ height: "100%" }}
                      className={
                        "backgroundImg imgCrop5" + (change ? " change" : "")
                      }
                    />
                    <div
                      className={`flips2 sec6 ${
                        backActive ? "backActive" : ""
                      }`}
                      style={{ height: height }}
                    >
                      <FlipCurImg
                        change={change}
                        height={height}
                        width={width}
                        cropIndex={6}
                        src={curImg}
                        style={{ height: "100%" }}
                        className={
                          "backgroundImg imgCrop6" + (change ? " change" : "")
                        }
                      />
                      <div
                        className={`flips2 sec7 ${
                          backActive ? "backActive" : ""
                        }`}
                        style={{ height: height }}
                      >
                        <FlipCurImg
                          change={change}
                          height={height}
                          width={width}
                          cropIndex={7}
                          src={curImg}
                          style={{ height: "100%" }}
                          className={
                            "backgroundImg imgCrop7" + (change ? " change" : "")
                          }
                        />
                        <div
                          className={`flips2 sec8 ${
                            backActive ? "backActive" : ""
                          }`}
                          style={{ height: height }}
                        >
                          <FlipCurImg
                            change={change}
                            height={height}
                            width={width}
                            cropIndex={8}
                            src={curImg}
                            style={{ height: "100%" }}
                            className={
                              "backgroundImg imgCrop8" +
                              (change ? " change" : "")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FlipWrap>
      <FlipWrapBack
        change={change}
        className={"tempArea3"}
        style={{ top: "400px", visibility: "visible" }}
      >
        <div className={"flipBody2"}>
          <div
            className={`flips2 sec1 ${backActive ? "backActive" : ""}`}
            style={{ height: height }}
          >
            <FlipCurBackImg
              change={change}
              height={height}
              width={width}
              cropIndex={1}
              src={curBackImg}
              style={{ height: "100%" }}
              className={"backgroundImg imgCrop1" + (change ? " change" : "")}
            />
            <div
              className={`flips2 sec2 ${backActive ? "backActive" : ""}`}
              style={{ height: height }}
            >
              <FlipCurBackImg
                change={change}
                height={height}
                width={width}
                cropIndex={2}
                src={curBackImg}
                style={{ height: "100%" }}
                className={"backgroundImg imgCrop2" + (change ? " change" : "")}
              />
              <div
                className={`flips2 sec3 ${backActive ? "backActive" : ""}`}
                style={{ height: height }}
              >
                <FlipCurBackImg
                  change={change}
                  height={height}
                  width={width}
                  cropIndex={3}
                  src={curBackImg}
                  style={{ height: "100%" }}
                  className={
                    "backgroundImg imgCrop3" + (change ? " change" : "")
                  }
                />

                <div
                  className={`flips2 sec4 ${backActive ? "backActive" : ""}`}
                  style={{ height: height }}
                >
                  <FlipCurBackImg
                    change={change}
                    height={height}
                    width={width}
                    cropIndex={4}
                    src={curBackImg}
                    style={{ height: "100%", width: "400px" }}
                    className={
                      "backgroundImg imgCrop4" + (change ? " change" : "")
                    }
                  />
                  <div
                    className={`flips2 sec5 ${backActive ? "backActive" : ""}`}
                    style={{ height: height }}
                  >
                    <FlipCurBackImg
                      change={change}
                      height={height}
                      width={width}
                      cropIndex={5}
                      src={curBackImg}
                      style={{ height: "100%" }}
                      className={
                        "backgroundImg imgCrop5" + (change ? " change" : "")
                      }
                    />
                    <div
                      className={`flips2 sec6 ${
                        backActive ? "backActive" : ""
                      }`}
                      style={{ height: height }}
                    >
                      <FlipCurBackImg
                        change={change}
                        height={height}
                        width={width}
                        cropIndex={6}
                        src={curBackImg}
                        style={{ height: "100%" }}
                        className={
                          "backgroundImg imgCrop6" + (change ? " change" : "")
                        }
                      />
                      <div
                        className={`flips2 sec7 ${
                          backActive ? "backActive" : ""
                        }`}
                        style={{ height: height }}
                      >
                        <FlipCurBackImg
                          change={change}
                          height={height}
                          width={width}
                          cropIndex={7}
                          src={curBackImg}
                          style={{ height: "100%" }}
                          className={
                            "backgroundImg imgCrop7" + (change ? " change" : "")
                          }
                        />
                        <div
                          className={`flips2 sec8 ${
                            backActive ? "backActive" : ""
                          }`}
                          style={{ height: height }}
                        >
                          <FlipCurBackImg
                            change={change}
                            height={height}
                            width={width}
                            cropIndex={8}
                            src={curBackImg}
                            style={{ height: "100%" }}
                            className={
                              "backgroundImg imgCrop8" +
                              (change ? " change" : "")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FlipWrapBack>
    </>
  );
};

export const FlipForward = ({
  backActive,
  change,
  nImgPath,
  width,
  height,
}) => {
  return (
    <div className={"tempArea3"}>
      <div className={"flipBody2"}>
        <div
          className={`flips2 sec1 ${backActive ? "backActive" : ""}`}
          style={{ height: height }}
        >
          <FlipImg
            change={change}
            height={height}
            width={width}
            cropIndex={1}
            src={nImgPath}
            style={{ height: "100%" }}
            className={"backgroundImg imgCrop1" + (change ? " change" : "")}
          />
          <div
            className={`flips2 sec2 ${backActive ? "backActive" : ""}`}
            style={{ height: height }}
          >
            <FlipImg
              change={change}
              height={height}
              width={width}
              cropIndex={2}
              src={nImgPath}
              style={{ height: "100%" }}
              className={"backgroundImg imgCrop2" + (change ? " change" : "")}
            />
            <div
              className={`flips2 sec3 ${backActive ? "backActive" : ""}`}
              style={{ height: height }}
            >
              <FlipImg
                change={change}
                height={height}
                width={width}
                cropIndex={3}
                src={nImgPath}
                style={{ height: "100%" }}
                className={"backgroundImg imgCrop3" + (change ? " change" : "")}
              />

              <div
                className={`flips2 sec4 ${backActive ? "backActive" : ""}`}
                style={{ height: height }}
              >
                <FlipImg
                  change={change}
                  height={height}
                  width={width}
                  cropIndex={4}
                  src={nImgPath}
                  style={{ height: "100%", width: "400px" }}
                  className={
                    "backgroundImg imgCrop4" + (change ? " change" : "")
                  }
                />
                <div
                  className={`flips2 sec5 ${backActive ? "backActive" : ""}`}
                  style={{ height: height }}
                >
                  <FlipImg
                    change={change}
                    height={height}
                    width={width}
                    cropIndex={5}
                    src={nImgPath}
                    style={{ height: "100%" }}
                    className={
                      "backgroundImg imgCrop5" + (change ? " change" : "")
                    }
                  />
                  <div
                    className={`flips2 sec6 ${backActive ? "backActive" : ""}`}
                    style={{ height: height }}
                  >
                    <FlipImg
                      change={change}
                      height={height}
                      width={width}
                      cropIndex={6}
                      src={nImgPath}
                      style={{ height: "100%" }}
                      className={
                        "backgroundImg imgCrop6" + (change ? " change" : "")
                      }
                    />
                    <div
                      className={`flips2 sec7 ${
                        backActive ? "backActive" : ""
                      }`}
                      style={{ height: height }}
                    >
                      <FlipImg
                        change={change}
                        height={height}
                        width={width}
                        cropIndex={7}
                        src={nImgPath}
                        style={{ height: "100%" }}
                        className={
                          "backgroundImg imgCrop7" + (change ? " change" : "")
                        }
                      />
                      <div
                        className={`flips2 sec8 ${
                          backActive ? "backActive" : ""
                        }`}
                        style={{ height: height }}
                      >
                        <FlipImg
                          change={change}
                          height={height}
                          width={width}
                          cropIndex={8}
                          src={nImgPath}
                          style={{ height: "100%" }}
                          className={
                            "backgroundImg imgCrop8" + (change ? " change" : "")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FlipForward_ver2 = ({
  backActive,
  change,
  page,
  refList,
  nImgPath,
  width,
  height,
}) => {
  const [imgs, setImgs] = useState([...refList]);
  const [curImg, setCurImg] = useState(null);
  const [prvImg, setPrvImg] = useState(null);

  console.log(imgs);

  console.log("page is : ", page);

  useEffect(() => {
    if (page === 1) {
      setCurImg(imgs[0].current.toDataURL());
    } else {
      setCurImg(imgs[page - 3].current.toDataURL());
      let timer_1 = setTimeout(
        () => setCurImg(imgs[page - 2].current.toDataURL()),
        100
      );
      let timer_2 = setTimeout(
        () => setCurImg(imgs[page - 1]?.current.toDataURL()),
        500
      );
      return () => {
        clearTimeout(timer_1);
        clearTimeout(timer_2);
      };
    }
  }, [page]);

  const handlePrevImg = (page) => {
    if (page > 3) {
      return imgs[page - 4].current.toDataURL();
    } else {
      return "";
    }
  };

  return (
    <FlipNextPageWrap backActive={backActive}>
      {backActive && (
        <FlipPrev>
          {page === 3 ? (
            <div
              style={{ width: width, height: height, background: "white" }}
            ></div>
          ) : (
            <img style={{ height: "100%" }} src={handlePrevImg(page)} />
          )}
        </FlipPrev>
      )}

      <FlipNextBody className={"flipBody2"}>
        <Flip2Sec
          seq={"sec1"}
          backActive={backActive}
          // className={`flips2 sec1 ${backActive ? "backActive" : ""}`}
          style={{ height: height }}
        >
          <FlipImg
            change={change}
            height={height}
            width={width}
            cropIndex={1}
            src={curImg}
            style={{ height: "100%" }}
            className={"backgroundImg imgCrop1" + (change ? " change" : "")}
          />
          <Flip2Sec
            seq={"sec2"}
            backActive={backActive}
            // className={`flips2 sec2 ${backActive ? "backActive" : ""}`}
            style={{ height: height }}
          >
            <FlipImg
              change={change}
              height={height}
              width={width}
              cropIndex={2}
              src={curImg}
              style={{ height: "100%" }}
              className={"backgroundImg imgCrop2" + (change ? " change" : "")}
            />
            <Flip2Sec
              seq={"sec3"}
              backActive={backActive}
              // className={`flips2 sec3 ${backActive ? "backActive" : ""}`}
              style={{ height: height }}
            >
              <FlipImg
                change={change}
                height={height}
                width={width}
                cropIndex={3}
                src={curImg}
                style={{ height: "100%" }}
                className={"backgroundImg imgCrop3" + (change ? " change" : "")}
              />

              <Flip2Sec
                seq={"sec4"}
                backActive={backActive}
                // className={`flips2 sec4 ${backActive ? "backActive" : ""}`}
                style={{ height: height }}
              >
                <FlipImg
                  change={change}
                  height={height}
                  width={width}
                  cropIndex={4}
                  src={curImg}
                  style={{ height: "100%", width: "400px" }}
                  className={
                    "backgroundImg imgCrop4" + (change ? " change" : "")
                  }
                />
                <Flip2Sec
                  seq={"sec5"}
                  backActive={backActive}
                  // className={`flips2 sec5 ${backActive ? "backActive" : ""}`}
                  style={{ height: height }}
                >
                  <FlipImg
                    change={change}
                    height={height}
                    width={width}
                    cropIndex={5}
                    src={curImg}
                    style={{ height: "100%" }}
                    className={
                      "backgroundImg imgCrop5" + (change ? " change" : "")
                    }
                  />
                  <Flip2Sec
                    seq={"sec6"}
                    backActive={backActive}
                    // className={`flips2 sec6 ${backActive ? "backActive" : ""}`}
                    style={{ height: height }}
                  >
                    <FlipImg
                      change={change}
                      height={height}
                      width={width}
                      cropIndex={6}
                      src={curImg}
                      style={{ height: "100%" }}
                      className={
                        "backgroundImg imgCrop6" + (change ? " change" : "")
                      }
                    />
                    <Flip2Sec
                      seq={"sec7"}
                      backActive={backActive}
                      // className={`flips2 sec7 ${
                      //   backActive ? "backActive" : ""
                      // }`}
                      style={{ height: height }}
                    >
                      <FlipImg
                        change={change}
                        height={height}
                        width={width}
                        cropIndex={7}
                        src={curImg}
                        style={{ height: "100%" }}
                        className={
                          "backgroundImg imgCrop7" + (change ? " change" : "")
                        }
                      />
                      <Flip2Sec
                        seq={"sec8"}
                        backActive={backActive}
                        // className={`flips2 sec8 ${
                        //   backActive ? "backActive" : ""
                        // }`}
                        style={{ height: height }}
                      >
                        <FlipImg
                          change={change}
                          height={height}
                          width={width}
                          cropIndex={8}
                          src={curImg}
                          style={{ height: "100%" }}
                          className={
                            "backgroundImg imgCrop8" + (change ? " change" : "")
                          }
                        />
                      </Flip2Sec>
                    </Flip2Sec>
                  </Flip2Sec>
                </Flip2Sec>
              </Flip2Sec>
            </Flip2Sec>
          </Flip2Sec>
        </Flip2Sec>
      </FlipNextBody>
    </FlipNextPageWrap>
  );
};

export const FlipBack_ver2 = ({
  active,
  change,
  page,
  refList,
  nImgPath,
  width,
  height,
}) => {
  const [imgs, setImgs] = useState([...refList]);
  const [curImg, setCurImg] = useState(null);
  const [nextImg, setNextImg] = useState(null);

  useEffect(() => {
    setCurImg(imgs[page]?.current.toDataURL());
    let timer_1 = setTimeout(
      () => setCurImg(imgs[page - 1]?.current.toDataURL()),
      550
    );
    let timer_2 = setTimeout(
      () => setCurImg(imgs[page - 2]?.current.toDataURL()),
      1000
    );

    return () => {
      clearTimeout(timer_1);
      clearTimeout(timer_2);
    };
  }, [page]);

  const handleNextImg = (page) => {
    // return imgs[page]
    return imgs[page + 1]?.current.toDataURL();
  };

  return (
    <div className={"tempArea2"}>
      {active && (
        <div
          className={"flipNext"}
          style={{ position: "absolute", visibility: "visible", right: 0 }}
        >
          {/* <div style={{width:width,height:height, background:"white"}}></div> */}
          <img style={{ height: "100%" }} src={handleNextImg(page)} />
        </div>
      )}
      <div className={"flipBody"}>
        <div
          className={`flips sec1 ${active ? "active" : ""} ${
            change ? "change" : ""
          }`}
          style={{ height: height }}
        >
          <FlipImgBack
            change={change}
            height={height}
            width={width}
            cropIndex={1}
            src={curImg}
            style={{ height: "100%" }}
            className={"backgroundImg imgCrop1" + (change ? " change" : "")}
          />
          <div
            className={`flips sec2 ${active ? "active" : ""} ${
              change ? "change" : ""
            }`}
            style={{ height: height }}
          >
            <FlipImgBack
              change={change}
              height={height}
              width={width}
              cropIndex={2}
              src={curImg}
              style={{ height: "100%" }}
              className={"backgroundImg imgCrop2" + (change ? " change" : "")}
            />
            <div
              className={`flips sec3 ${active ? "active" : ""} ${
                change ? "change" : ""
              }`}
              style={{ height: height }}
            >
              <FlipImgBack
                change={change}
                height={height}
                width={width}
                cropIndex={3}
                src={curImg}
                style={{ height: "100%" }}
                className={"backgroundImg imgCrop3" + (change ? " change" : "")}
              />
              <div
                className={`flips sec4 ${active ? "active" : ""} ${
                  change ? "change" : ""
                }`}
                style={{ height: height }}
              >
                <FlipImgBack
                  change={change}
                  height={height}
                  width={width}
                  cropIndex={4}
                  src={curImg}
                  style={{ height: "100%", width: "400px" }}
                  className={
                    "backgroundImg imgCrop4" + (change ? " change" : "")
                  }
                />
                <div
                  className={`flips sec5 ${active ? "active" : ""} ${
                    change ? "change" : ""
                  }`}
                  style={{ height: height }}
                >
                  <FlipImgBack
                    change={change}
                    height={height}
                    width={width}
                    cropIndex={5}
                    src={curImg}
                    style={{ height: "100%" }}
                    className={
                      "backgroundImg imgCrop5" + (change ? " change" : "")
                    }
                  />
                  <div
                    className={`flips sec6 ${active ? "active" : ""} ${
                      change ? "change" : ""
                    }`}
                    style={{ height: height }}
                  >
                    <FlipImgBack
                      change={change}
                      height={height}
                      width={width}
                      cropIndex={6}
                      src={curImg}
                      style={{ height: "100%" }}
                      className={
                        "backgroundImg imgCrop6" + (change ? " change" : "")
                      }
                    />
                    <div
                      className={`flips sec7 ${active ? "active" : ""} ${
                        change ? "change" : ""
                      }`}
                      style={{ height: height }}
                    >
                      <FlipImgBack
                        change={change}
                        height={height}
                        width={width}
                        cropIndex={7}
                        src={curImg}
                        style={{ height: "100%" }}
                        className={
                          "backgroundImg imgCrop7" + (change ? " change" : "")
                        }
                      />
                      <div
                        className={`flips sec8 ${active ? "active" : ""} ${
                          change ? "change" : ""
                        }`}
                        style={{ height: height }}
                      >
                        <FlipImgBack
                          change={change}
                          height={height}
                          width={width}
                          cropIndex={8}
                          src={curImg}
                          style={{ height: "100%" }}
                          className={
                            "backgroundImg imgCrop8" + (change ? " change" : "")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const FlipBackMobile_reverse = ({
  active,
  // change,
  page,
  imgList,
  refList,
  nImgPath,
  width,
  height,
}) => {
  const [curImg, setCurImg] = useState(null);
  const [change, setChange] = useState(true);

  useEffect(() => {
    if (page !== 1) {
      setCurImg(createHeightAndBlob(page, refList[page - 1]).blob);
    } else {
      setCurImg(imgList[0].blob);
    }
  }, [page]);
  return (
    <FlipWrapMobile width={width} height={height} active={active}>
      <FlipBodyMobile className={"flipBody"}>
        <FlipSecMobile_ver2
          seq={"seq1"}
          active={active}
          width={width}
          // className={`flips sec1 ${active ? "active" : ""} ${
          //   change ? "change" : ""
          // }`}
          style={{ height: height }}
        >
          <FlipImgBack_mobile
            change={change}
            height={height}
            width={width}
            cropIndex={1}
            src={curImg}
            style={{ height: "100%" }}
            className={"backgroundImg imgCrop1" + (change ? " change" : "")}
          />
          <FlipSecMobile_ver2
            active={active}
            width={width}
            seq={"seq2"}
            style={{ height: height }}
          >
            <FlipImgBack_mobile
              change={change}
              height={height}
              width={width}
              cropIndex={2}
              src={curImg}
              style={{ height: "100%" }}
              className={"backgroundImg imgCrop2" + (change ? " change" : "")}
            />
            <FlipSecMobile_ver2
              active={active}
              width={width}
              seq={"seq3"}
              style={{ height: height }}
            >
              <FlipImgBack_mobile
                change={change}
                height={height}
                width={width}
                cropIndex={3}
                src={curImg}
                style={{ height: "100%" }}
                className={"backgroundImg imgCrop3" + (change ? " change" : "")}
              />
              <FlipSecMobile_ver2
                active={active}
                width={width}
                seq={"seq4"}
                style={{ height: height }}
              >
                <FlipImgBack_mobile
                  change={change}
                  height={height}
                  width={width}
                  cropIndex={4}
                  src={curImg}
                  style={{ height: "100%" }}
                  className={
                    "backgroundImg imgCrop4" + (change ? " change" : "")
                  }
                />
                <FlipSecMobile_ver2
                  active={active}
                  width={width}
                  seq={"seq5"}
                  style={{ height: height }}
                >
                  <FlipImgBack_mobile
                    change={change}
                    height={height}
                    width={width}
                    cropIndex={5}
                    src={curImg}
                    style={{ height: "100%" }}
                    className={
                      "backgroundImg imgCrop5" + (change ? " change" : "")
                    }
                  />
                  <FlipSecMobile_ver2
                    active={active}
                    width={width}
                    seq={"seq6"}
                    style={{ height: height }}
                  >
                    <FlipImgBack_mobile
                      change={change}
                      height={height}
                      width={width}
                      cropIndex={6}
                      src={curImg}
                      style={{ height: "100%" }}
                      className={
                        "backgroundImg imgCrop6" + (change ? " change" : "")
                      }
                    />
                    <FlipSecMobile_ver2
                      active={active}
                      width={width}
                      seq={"seq7"}
                      style={{ height: height }}
                    >
                      <FlipImgBack_mobile
                        change={change}
                        height={height}
                        width={width}
                        cropIndex={7}
                        src={curImg}
                        style={{ height: "100%" }}
                        className={
                          "backgroundImg imgCrop7" + (change ? " change" : "")
                        }
                      />
                      <FlipSecMobile_ver2
                        active={active}
                        width={width}
                        seq={"seq8"}
                        style={{ height: height }}
                      >
                        <FlipImgBack_mobile
                          change={change}
                          height={height}
                          width={width}
                          cropIndex={8}
                          src={curImg}
                          style={{ height: "100%" }}
                          className={
                            "backgroundImg imgCrop8" + (change ? " change" : "")
                          }
                        />
                      </FlipSecMobile_ver2>
                    </FlipSecMobile_ver2>
                  </FlipSecMobile_ver2>
                </FlipSecMobile_ver2>
              </FlipSecMobile_ver2>
            </FlipSecMobile_ver2>
          </FlipSecMobile_ver2>
        </FlipSecMobile_ver2>
      </FlipBodyMobile>
    </FlipWrapMobile>
  );
};

export const FlipBackMobile = ({
  active,
  // change,
  page,
  imgList,
  refList,
  nImgPath,
  width,
  height,
}) => {
  const [curImg, setCurImg] = useState(null);
  const [change, setChange] = useState(true);

  useEffect(() => {
    if (page !== 1) {
      // setCurImg(createHeightAndBlob(page, refList[page - 2]).blob);
      let timer_id = setTimeout(
        () => setCurImg(createHeightAndBlob(page, refList[page - 1]).blob),
        510
      );

      return () => {
        console.log("clear");
        clearTimeout(timer_id);
      };
    } else {
      setCurImg(createHeightAndBlob(page, refList[0]).blob);
    }
  }, [page]);
  return (
    <FlipWrapMobile width={width} height={height} active={active}>
      <FlipBodyMobile className={"flipBody"}>
        <FlipSecMobile_ver2_reverse
          seq={"seq1"}
          active={active}
          width={width}
          // className={`flips sec1 ${active ? "active" : ""} ${
          //   change ? "change" : ""
          // }`}
          style={{ height: height }}
        >
          <FlipImgBack_mobile
            change={change}
            height={height}
            width={width}
            cropIndex={1}
            src={curImg}
            style={{ height: "100%" }}
            className={"backgroundImg imgCrop1" + (change ? " change" : "")}
          />
          <FlipSecMobile_ver2_reverse
            active={active}
            width={width}
            seq={"seq2"}
            style={{ height: height }}
          >
            <FlipImgBack_mobile
              change={change}
              height={height}
              width={width}
              cropIndex={2}
              src={curImg}
              style={{ height: "100%" }}
              className={"backgroundImg imgCrop2" + (change ? " change" : "")}
            />
            <FlipSecMobile_ver2_reverse
              active={active}
              width={width}
              seq={"seq3"}
              style={{ height: height }}
            >
              <FlipImgBack_mobile
                change={change}
                height={height}
                width={width}
                cropIndex={3}
                src={curImg}
                style={{ height: "100%" }}
                className={"backgroundImg imgCrop3" + (change ? " change" : "")}
              />
              <FlipSecMobile_ver2_reverse
                active={active}
                width={width}
                seq={"seq4"}
                style={{ height: height }}
              >
                <FlipImgBack_mobile
                  change={change}
                  height={height}
                  width={width}
                  cropIndex={4}
                  src={curImg}
                  style={{ height: "100%" }}
                  className={
                    "backgroundImg imgCrop4" + (change ? " change" : "")
                  }
                />
                <FlipSecMobile_ver2_reverse
                  active={active}
                  width={width}
                  seq={"seq5"}
                  style={{ height: height }}
                >
                  <FlipImgBack_mobile
                    change={change}
                    height={height}
                    width={width}
                    cropIndex={5}
                    src={curImg}
                    style={{ height: "100%" }}
                    className={
                      "backgroundImg imgCrop5" + (change ? " change" : "")
                    }
                  />
                  <FlipSecMobile_ver2_reverse
                    active={active}
                    width={width}
                    seq={"seq6"}
                    style={{ height: height }}
                  >
                    <FlipImgBack_mobile
                      change={change}
                      height={height}
                      width={width}
                      cropIndex={6}
                      src={curImg}
                      style={{ height: "100%" }}
                      className={
                        "backgroundImg imgCrop6" + (change ? " change" : "")
                      }
                    />
                    <FlipSecMobile_ver2_reverse
                      active={active}
                      width={width}
                      seq={"seq7"}
                      style={{ height: height }}
                    >
                      <FlipImgBack_mobile
                        change={change}
                        height={height}
                        width={width}
                        cropIndex={7}
                        src={curImg}
                        style={{ height: "100%" }}
                        className={
                          "backgroundImg imgCrop7" + (change ? " change" : "")
                        }
                      />
                      <FlipSecMobile_ver2_reverse
                        active={active}
                        width={width}
                        seq={"seq8"}
                        style={{ height: height }}
                      >
                        <FlipImgBack_mobile
                          change={change}
                          height={height}
                          width={width}
                          cropIndex={8}
                          src={curImg}
                          style={{ height: "100%" }}
                          className={
                            "backgroundImg imgCrop8" + (change ? " change" : "")
                          }
                        />
                      </FlipSecMobile_ver2_reverse>
                    </FlipSecMobile_ver2_reverse>
                  </FlipSecMobile_ver2_reverse>
                </FlipSecMobile_ver2_reverse>
              </FlipSecMobile_ver2_reverse>
            </FlipSecMobile_ver2_reverse>
          </FlipSecMobile_ver2_reverse>
        </FlipSecMobile_ver2_reverse>
      </FlipBodyMobile>
    </FlipWrapMobile>
  );
};

const FlipImg = styled.img`
  width: ${(props) => props.width + "px" || "400px"};
  height: 100%;
  position: absolute;
  ${(props) => {
    const divided_x_length = props.width / 8;
    const divided_y_length = props.height;

    let curZIndex = 10 + (props.cropIndex - 1);
    let curLeft = -50 * (props.cropIndex - 1);

    let clip_to_right = divided_x_length * props.cropIndex;
    let clip_to_bottom = divided_y_length;
    let clip_from_left = divided_x_length * (props.cropIndex - 1);

    let transformDeg = "-180deg";

    if (props.change) {
      // console.log(props.width);
      curZIndex = 10 + (8 - props.cropIndex);
      clip_to_right = (props.width / 8) * (8 - (props.cropIndex - 1));
      clip_from_left = (props.width / 8) * (8 - props.cropIndex);
    }

    return `z-index:${curZIndex}; 
    left:${curLeft}px; 
    ${props.change ? `transform:rotateY(${transformDeg});` : ""}
    clip:rect(0,${clip_to_right}px,${clip_to_bottom}px,${clip_from_left}px)`;
  }}
`;

const FlipImgBack = styled.img`
  width: ${(props) => props.width + "px" || "400px"};
  height: 100%;
  position: absolute;
  ${(props) => {
    const divided_x_length = props.width / 8;
    const divided_y_length = props.height;

    let curZIndex = 10 + (props.cropIndex - 1);
    let curLeft = -50 * (props.cropIndex - 1);

    let clip_to_right = divided_x_length * props.cropIndex;
    let clip_to_bottom = divided_y_length;
    let clip_from_left = divided_x_length * (props.cropIndex - 1);

    let transformDeg = "-180deg";

    if (!props.change) {
      // console.log(props.width);

      curZIndex = 10 + (8 - props.cropIndex);
      clip_to_right = (props.width / 8) * (8 - (props.cropIndex - 1));
      clip_from_left = (props.width / 8) * (8 - props.cropIndex);
    }

    return `z-index:${curZIndex}; 
    right:${curLeft}px; 
    ${props.change ? `transform:rotateY(${transformDeg});` : ""}
    clip:rect(0,${clip_to_right}px,${clip_to_bottom}px,${clip_from_left}px)`;
  }}
`;

const FlipCurImg = styled.img`
  width: ${(props) => props.width + "px" || "400px"};
  height: 100%;
  position: absolute;
  ${(props) => {
    const divided_x_length = props.width / 8;
    const divided_y_length = props.height;

    let curZIndex = 10 + (props.cropIndex - 1);
    let curLeft = -50 * (props.cropIndex - 1);

    let clip_to_right = divided_x_length * props.cropIndex;
    let clip_to_bottom = divided_y_length;
    let clip_from_left = divided_x_length * (props.cropIndex - 1);

    let transformDeg = "-180deg";

    return `z-index:${curZIndex}; 
    left:${curLeft}px;
    visibility:${props.change ? `hidden` : `visible`};

    clip:rect(0,${clip_to_right}px,${clip_to_bottom}px,${clip_from_left}px)`;
  }}
`;

const FlipCurBackImg = styled.img`
  width: ${(props) => props.width + "px" || "400px"};
  height: 100%;
  position: absolute;
  ${(props) => {
    const divided_x_length = props.width / 8;
    const divided_y_length = props.height;

    let curZIndex = 10 + (8 - props.cropIndex);
    let curLeft = -50 * (props.cropIndex - 1);

    let clip_to_right = (props.width / 8) * (8 - (props.cropIndex - 1));
    let clip_to_bottom = divided_y_length;
    let clip_from_left = (props.width / 8) * (8 - props.cropIndex);

    let transformDeg = "-180deg";

    return `z-index:${curZIndex}; 
    left:${curLeft}px; 
    transform:rotateY(${transformDeg});
    visibility:${props.change ? `visible` : `hidden`};
    
    clip:rect(0,${clip_to_right}px,${clip_to_bottom}px,${clip_from_left}px)`;
  }}
`;

const FlipImgBack_mobile = styled.img`
  max-width: unset;
  width: ${(props) => props.width + "px" || "400px"};
  height: 100%;
  position: absolute;
  ${(props) => {
    const divided_x_length = props.width / 8;
    const divided_y_length = props.height;

    let curZIndex = 10 + (props.cropIndex - 1);
    let curLeft = -50 * (props.cropIndex - 1);

    let clip_to_right = divided_x_length * props.cropIndex;
    let clip_to_bottom = divided_y_length;
    let clip_from_left = divided_x_length * (props.cropIndex - 1);

    // let transformDeg = "-180deg";

    if (!props.change) {
      // console.log(props.width);

      curZIndex = 10 + (8 - props.cropIndex);
      clip_to_right = (props.width / 8) * (8 - (props.cropIndex - 1));
      clip_from_left = (props.width / 8) * (8 - props.cropIndex);
    }

    return `z-index:${curZIndex}; 
    left:${curLeft}px; 
    
    clip:rect(0,${clip_to_right}px,${clip_to_bottom}px,${clip_from_left}px)`;
  }}
`;

const FlipWrap = styled.div`
  ${(props) => {
    return `
  z-index:${props.change ? `9999` : `99999`}
  `;
  }}
`;

const FlipWrapBack = styled.div`
  ${(props) => {
    return `
  z-index:${props.change ? `99999` : `9999`}
  `;
  }}
`;
