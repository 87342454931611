import React, { useEffect, useState } from "react";
import MainImg1 from "../asset/img/main/img_mainB01.png";
import MainImg2 from "../asset/img/main/img_mainB02.png";
import MainImg3 from "../asset/img/main/img_mainB03.png";
import MainImg4 from "../asset/img/main/img_mainB04.png";
import MainYoutube from "../asset/img/main/btn_youtube.png";
import MainNaver from "../asset/img/main/btn_naver.png";
import TitleMain from "../asset/img/main/title_main.png";
import TitleMainM from "../asset/img/main/title_main_mobile.png";
import { IsFirstDay, IsSecondDay } from "../utils/DateLimit";

const TARGET_DATE = new Date(`2022/12/08 10:00:00`);
const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
const KR_TAREGET_DATE =
  TARGET_DATE.getTime() + TARGET_DATE.getTimezoneOffset() * 60 * 1000;
const FINAL_DATE = new Date(KR_TAREGET_DATE + KR_TIME_DIFF);

const MainSection1 = () => {
  const isFirst = IsFirstDay();
  const isSecond = IsSecondDay();
  const [day, setDay] = useState();
  const [min, setMin] = useState();
  const [sec, setSec] = useState();
  const [ss, setSs] = useState();

  useEffect(() => {
    const nowDate = new Date();
    const nowDate_C =
      nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000;
    const FINAL_NOW = new Date(nowDate_C + KR_TIME_DIFF);
    const gap = FINAL_DATE.getTime() - FINAL_NOW.getTime();
    const dday = Math.ceil(gap / (1000 * 60 * 60 * 24));
    const dmin = Math.ceil((gap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const dsec = Math.ceil((gap % (1000 * 60 * 60)) / (1000 * 60));
    const se = Math.ceil((gap % (1000 * 60)) / 1000);
    setDay(parseInt(dday));
    setMin(parseInt(dmin));
    setSec(parseInt(dsec));
    setSs(parseInt(se));
  }, []);

  const handlePrev = () => {
    alert("12월 8일 오픈 예정입니다.");
    return false;
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(ss) > 0) {
        setSs(parseInt(ss) - 1);
      }
      if (parseInt(ss) <= 0) {
        if (parseInt(sec) <= 0) {
          if (parseInt(min) <= 0) {
            if (parseInt(day) <= 0) {
              clearInterval(countdown);
            } else {
              setDay(parseInt(day) - 1);
              setMin(59);
              setSec(59);
              setSs(59);
            }
          } else {
            setMin(parseInt(min) - 1);
            setSec(59);
            setSs(59);
          }
        } else {
          setSec(parseInt(sec) - 1);
          setSs(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [day, min, sec, ss]);
  return (
    <section className="section1">
      <div className="main_visual">
        <div className="slideList">
          <div className="img_area">
            <img
              className="mImg1 wow fadeIn"
              data-wow-delay="1.2s"
              src={MainImg1}
            />
            <img
              className="mImg2 wow fadeInUp"
              data-wow-delay="1.7s"
              src={MainImg2}
            />
            <img
              className="mImg3 wow fadeInUp"
              data-wow-delay="1.9s"
              src={MainImg3}
            />
            <img
              className="mImg4 wow fadeInUp"
              data-wow-delay="2s"
              src={MainImg4}
            />
          </div>
          <div className="text_area">
            {sec > 0 && (
              <div
                className="dDay"
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  position: "fixed",
                  bottom: "1em",
                  right: "1em",
                  backgroundColor: "#061650",
                }}
              >
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    color: "white !Important",
                  }}
                >
                  행사일까지
                </span>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span>D&nbsp;-&nbsp;</span>
                  <p style={{ color: "white" }}>
                    {sec}분 {ss}초
                  </p>
                </div>
              </div>
            )}
            <img
              className="slogan pc"
              src={TitleMain}
              alt="2022 SW인재페스티벌"
            />
            <img
              className="slogan mobile"
              src={TitleMainM}
              alt="2022 SW인재페스티벌"
            />
            <div
              className="link_area wow fadeInDown"
              data-wow-delay=".7s"
              style={{ width: "21.5em" }}
            >
              <span style={{ fontSize: "1.4rem", color: "#051650" }}>
                송&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;출
              </span>
              {isFirst || isSecond ? (
                isFirst ? (
                  <a href={"https://youtu.be/h8EA2xFZn30"} target={"_blank"}>
                    <img src={MainYoutube} />
                  </a>
                ) : isSecond ? (
                  <a href={"https://youtu.be/xDQCoW8KZtU"} target={"_blank"}>
                    <img src={MainYoutube} />
                  </a>
                ) : (
                  <a href="#" onClick={() => handlePrev()}>
                    <img src={MainYoutube} />
                  </a>
                )
              ) : (
                <a href="#" onClick={() => handlePrev()}>
                  <img src={MainYoutube} />
                </a>
              )}

              {isFirst || isSecond ? (
                isFirst ? (
                  <a href={"https://tv.naver.com/l/121832"} target={"_blank"}>
                    <img src={MainNaver} />
                  </a>
                ) : isSecond ? (
                  <a href={"https://tv.naver.com/l/121833"} target={"_blank"}>
                    <img src={MainNaver} />
                  </a>
                ) : (
                  <a href="#" onClick={() => handlePrev()}>
                    <img src={MainNaver} />
                  </a>
                )
              ) : (
                <a href="#" onClick={() => handlePrev()}>
                  <img src={MainNaver} />
                </a>
              )}
            </div>

            <div style={{ display: "block" }}>
              <p
                style={{
                  fontSize: "0.85em",
                  width: "19.9em",
                  textAlign: "center",
                  background: "rgb(5 22 80)",
                  borderRadius: "5em",
                  marginTop: "0.4em",
                  padding: "0.5em",
                  color: "white",
                }}
              >
                (SW중심대학협의회)를 검색해주세요
              </p>
            </div>
            <div style={{ display: "block" }}>
              <p
                style={{
                  fontSize: "0.85em",
                  width: "25.1em",
                  textAlign: "center",
                  background: "#e6e6e6",
                  borderRadius: "5em",
                  marginTop: "0.8em",
                  fontWeight: "bold",
                }}
              >
                ※ 안전관리 및 방역관리 계획을 준수하여 진행합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection1;
