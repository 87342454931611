import React from "react";
import { Link } from "react-router-dom";

const UnivTabs = ({ current }) => {
  return (
    <div className="sub_nav depth2">
      <ul>
        <li className={current === "intro" ? "active" : null}>
          <Link to="/univIntro">사업소개</Link>
        </li>
        <li className={current === "current" ? "active" : null}>
          <Link to="/univCurrent">선정현황</Link>
        </li>
      </ul>
    </div>
  );
};

export default UnivTabs;
