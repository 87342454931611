import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BoardSubVisual from "../../components/BoardSubVisual";
import BoardTabs from "../../components/BoardTabs";
import { parseDateFormat } from "../../utils/DateFormat";

const URL =
  "https://s3.ap-northeast-2.amazonaws.com/mulsarang2021.archive/iitp2022/";

const NoticeDetail = () => {
  //   console.log(match.params?.index);
  const { id } = useParams();
  const [info, setInfo] = useState({
    loading: true,
  });

  useEffect(() => {
    const getNotice = async () => {
      const res = await axios.get(
        `https://admin.swfestival2022.kr/board/notice/view?id=${id}`
        // `http://localhost:8080/board/notice/view?id=${id}`
      );
      if (res.status == 200) {
        setInfo({ ...res.data, loading: false });
      } else {
        alert("조회중 오류가 발생했습니다.");
      }
    };
    getNotice();
  }, [id]);

  console.log(info);

  return (
    <div className={"wrap main"}>
      <div className={"wrap main"}>
        <div className={"container sub"}>
          <BoardSubVisual />
          <BoardTabs current={"notice"} />
          <div className={"sub_wrap"}>
            <span className={"page_title"}>공지사항</span>
            <div className="board_wrap">
              <div className="board_area view">
                {!info.loading && (
                  <ul>
                    <li className="view_top">
                      <p className="title">{info?.title}</p>

                      <p className="date">{parseDateFormat(info?.createdAt)}</p>
                      <p className="view">{info.viewCnt}</p>
                    </li>
                    <li className="view_cont">
                      <div className="view_file">
                        {!info.loading &&
                          info?.noticeFile &&
                          info?.noticeFile.length > 0 && (
                            <a href={URL + info.noticeFile[0].file.path}>
                              {info.noticeFile[0].file.originName}
                            </a>
                          )}
                      </div>
                      <p
                        dangerouslySetInnerHTML={{ __html: info?.content }}
                      ></p>
                    </li>
                  </ul>
                )}
                <div className="btn_area">
                  <Link to="/notice" className="btn list">
                    목록으로
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeDetail;
