const dataUrlToBlob = (uri) => {
  var str, arr, i, matched, type;
  uri = uri.split(",");
  matched = /data:([^;]+)/.exec(uri[0]);
  if (matched) {
    type = matched[1];
  }
  str = atob(uri[1]);
  arr = new Uint8Array(str.length);
  for (i = 0; i < arr.length; i++) {
    arr[i] = str.charCodeAt(i);
  }
  return new Blob([arr], { type: type });
};

export const getCanvasStyleHeight = (ref) => {
  if (ref.current) {
    const refStyle = window.getComputedStyle(ref.current);
    const refStyleHeight =
      refStyle.getPropertyValue("height").replace("px", "") * 1;
    return refStyleHeight;
  } else {
    return 0;
  }
};

export const getCanvasStyleHeightWithOutCurrent = (ref) => {
  if (ref) {
    const refStyle = window.getComputedStyle(ref);
    const refStyleHeight =
      refStyle.getPropertyValue("height").replace("px", "") * 1;
    return refStyleHeight;
  } else {
    return 0;
  }
};

export const getBlobFromCanvas = (dataURL) => {
  const blob = dataUrlToBlob(dataURL);

  const returnData = URL.createObjectURL(blob);
  return returnData;
};

export const createHeightAndBlob = (page, ref) => {
  const refHeight = getCanvasStyleHeight(ref);
  const refBlob = getBlobFromCanvas(ref.current.toDataURL());
  const finalData = { page, height: refHeight, blob: refBlob };

  return finalData;
};

export const createHeightAndBlodWithOutCurrent = (page, ref) => {
  const refHeight = getCanvasStyleHeightWithOutCurrent(ref);
  const refBlob = getBlobFromCanvas(ref.toDataURL());
  const finalData = { page, height: refHeight, blob: refBlob };

  return finalData;
};
