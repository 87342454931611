import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import BoardSubVisual from "../../components/BoardSubVisual";
import LoginTabs from "../../components/LoginTabs";
import { accountSelect } from "../../recoil/AtomConfig";

const Login = () => {
  const navi = useNavigate();
  const [state, setState] = useRecoilState(accountSelect);
  const [account, setAccount] = useState({
    id: "",
    pwd: "",
  });

  const submit = async () => {
    const { data } = await handleLoginAxios(account.id, account.pwd);

    console.log(data);

    if (data.success) {
      setState((prev) => {
        const prevObject = { ...prev };
        prevObject.id = data.data.id;
        prevObject.accessToken = data.data.accessToken;
        prevObject.voteList = [...data.data.voteList];
        return { ...prevObject };
      });
      localStorage.setItem("accessToken", data.data.accessToken);
      navi("/home");
    } else {
      alert(data.msg);
    }
  };

  const handleLoginAxios = async (id, pwd) => {
    // const result = await axios.post(
    //   "http://localhost:8080/api/account/signin",
    //   { id, pwd }
    // );
    const result = await axios.post(
      "https://admin.swfestival2022.kr/api/account/signin",
      { id, pwd }
    );

    return result;
  };

  return (
    <div className={"wrap main"}>
      <div className={"container sub"}>
        <BoardSubVisual />
        <LoginTabs current={"login"} />
        <div className="sub_wrap login">
          <br />
          <div className={"infoArea"}>
            <p>
              * 회원가입 후 <b>'인기상 투표'</b>가 가능합니다.{" "}
              <small>
                <b>
                  (관람안내 {">"} 우수작품 {">"} 투표하기)
                </b>
              </small>
            </p>
            <p>
              * 사전등록은 <b>(관람안내 {">"} 사전등록)</b>에서 가능합니다.
            </p>
          </div>
          <br />
          <span>2022 SW인재페스티벌 로그인</span>

          <form className="login_form">
            <div className="input_area">
              <input
                type="text"
                name="login_id"
                className="login_inp"
                placeholder="아이디는 휴대전화번호로 입력해주세요."
                onChange={(e) => setAccount({ ...account, id: e.target.value })}
              />
            </div>
            <div className="input_area">
              <input
                type="password"
                name="login_pw"
                className="login_inp"
                placeholder="비밀번호를 입력하세요"
                onChange={(e) =>
                  setAccount({ ...account, pwd: e.target.value })
                }
              />
            </div>

            <button type="button" className="login_btn" onClick={submit}>
              로그인
            </button>
            <div style={{ textAlign: "right" }}>
              <span
                style={{
                  fontSize: "0.7em",
                  color: "#a0a0a0",
                  cursor: "pointer",
                }}
                onClick={() => navi("/findpwd")}
              >
                비밀번호 찾기
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
